import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Table, message, Select, Card } from "antd";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";
import { useParams } from "react-router-dom";
import Dragger from "antd/es/upload/Dragger";

const { Option } = Select;

const EducationDetails = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [file, setFile] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);
	const [id, setId] = useState(null);
	const { token, apiurl } = useAuth();
	const id2=useParams();

	useEffect(() => {
		fetchEducationDetails();
	}, []);

	const fetchEducationDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/geteducation/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify(id2)
			});
			if (!response.ok) {
				throw new Error("Failed to fetch EducationDetails");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching EducationDetails");
		}
	};


	

	

	const columns = [
		{
			title: "Institution",
			dataIndex: "institution",
			key: "institution",
		},
		{
			title: "Degree",
			dataIndex: "degree",
			key: "degree",
		},
		{
			title: "Field of Study",
			dataIndex: "field_of_study",
			key: "field_of_study",
		},
		{
			title: "Start Date",
			dataIndex: "start_date",
			key: "start_date",
		},
		{
			title: "End Date",
			dataIndex: "end_date",
			key: "end_date",
		},
		{
			title: "Education Proof",
			key: "education_proof",
			render: (text, record) => (
				<span>
					
						<a
							href={`${apiurl}${record?.education_proof}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					
				</span>
			),
		},
	];



	

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Education Details"
				>
				<Table
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20, overflow: "auto" }}
				/>
			</Card>
			
		</div>
	);
};

export default EducationDetails;
