import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Table, message, Modal, Input } from "antd";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";
import { LeftCircleOutlined } from "@ant-design/icons";
import './Payroll.css';
import Loader from "../Loader/Loader";
import Success from "../utils/Success";

const AdminPayroll = () => {
	const [userData, setUserData] = useState([]);
	const navigate = useNavigate();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [currentEdit, setCurrentEdit] = useState(null);
	const [loading,setLoading] = useState(false);
	const [success,setSuccess] = useState(false);
	const { apiurl, token } = useAuth();
	const { month, year } = useParams();

	

	const fetchUserData = async () => {
		try {
			const response = await fetch(`${apiurl}/payrollinfo/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch payroll data");
			}
			const data = await response.json();
			setUserData(data.data);
		} catch (error) {
			message.error("Error fetching payroll data");
		}
	};

	useEffect(() => {
		fetchUserData();
	}, []);

	const runpayroll = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/runpayroll/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					month: month,
					year: year,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				setLoading(false);
				throw new Error("Failed to fetch payroll data");
			}
			const data = await response.json();
			setUserData(data.data);
			setSuccess(true);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			message.error("Error fetching payroll data");
		}
	};

	const showModal = (record) => {
		setCurrentEdit(record);
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setCurrentEdit(null);
	};

	const handleSave = async () => {
		if (!currentEdit) return;
		setCurrentEdit({ ...currentEdit, month: month, year: year });
		try {
			const response = await fetch(`${apiurl}/payrollinfo/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(currentEdit),
			});
			if (!response.ok) {
				throw new Error("Failed to update payroll data");
			}
			fetchUserData();
			setIsModalVisible(false);
			message.success("Payroll updated successfully!");
		} catch (error) {
			message.error("Error updating payroll data");
		}
	};

	const handleInputChange = (e, field) => {
		setCurrentEdit({
			...currentEdit,
			[field]: e.target.value,
			month: month,
			year: year,
		});
	};

	const columns = [
		{ title: "Employee", dataIndex: "employee", key: "employee" },
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{ title: "CTC", dataIndex: "ctc", key: "ctc" },
		{ title: "Leaves Taken", dataIndex: "leaves", key: "leaves" },
		{ title: "Absent", dataIndex: "absent", key: "absent" },
		{ title: "Current Leave Balance", dataIndex: "currentleavebalance", key: "currentleavebalance" },
		{ title: "Carry Forwarded Leave Balance", dataIndex: "forwardedleavebalance", key: "forwardedleavebalance" },
		{ title: "Bonus", dataIndex: "bonus", key: "bonus" },
		{ title: "Advance Amount", dataIndex: "advance", key: "advance" },
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (_, record) => (
				<Button onClick={() => showModal(record)}>Edit</Button>
			),
		},
		{
			title: "Edit Attendance",
			dataIndex: "edit",
			key: "edit",
			render: (_, record) => (
				<Button onClick={() =>navigate(`/empattendance/${month}/${year}/${record.id}`)}>Edit </Button>
			),
		},
	];

	if (loading){
		return <Loader></Loader>
	}

	if (success){
		return <Success title={<p>Payroll Runned Successfully <br></br> Click <Link to="/home" >Here</Link> to return Home</p>}/>
	}

	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="admin-payroll-main">
				<Table dataSource={userData} columns={columns} />
				<Button className="pay-btn" onClick={runpayroll}>
					Run Payroll
				</Button>
				<Modal
					title="Edit Payroll"
					open={isModalVisible}
					onOk={handleSave}
					onCancel={handleCancel}
					okText="Save"
					cancelText="Cancel">
					{/* <Input
						addonBefore="Leaves Taken"
						value={currentEdit?.leaves}
						onChange={(e) => handleInputChange(e, "leaves")}
					/> */}
					<Input
						addonBefore="Bonus"
						value={currentEdit?.bonus}
						onChange={(e) => handleInputChange(e, "bonus")}
					/>
					<Input
						addonBefore="Advance"
						value={currentEdit?.advance}
						onChange={(e) => handleInputChange(e, "advance")}
					/>
					<Input
						addonBefore="Current Leave Balance"
						value={currentEdit?.currentleavebalance}
						onChange={(e) => handleInputChange(e, "currentleavebalance")}
					/>
					<Input
						addonBefore="Forwarded Leave Balance"
						value={currentEdit?.forwardedleavebalance}
						onChange={(e) => handleInputChange(e, "forwardedleavebalance")}
					/>
					<Input
						addonBefore="CTC"
						value={currentEdit?.ctc}
						onChange={(e) => handleInputChange(e, "ctc")}
					/>
					<Input
						addonBefore="Comments"  required
						onChange={(e) => handleInputChange(e, "comments")}
					/>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default AdminPayroll;
