import React, { useEffect, useState } from "react";
import {
	Table,
	Button,
	Space,
	Modal,
	Form,
	Input,
	message,
	Card,
} from "antd";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";
import "./ticket.css"
function TicketList() {
	const [grievances, setGrievances] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();

	useEffect(() => {
		fetchGrievances();
	}, []);

	const fetchGrievances = async () => {
		try {
			const response = await fetch(`${apiurl}/tickets/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch tickets");
			}
			const data = await response.json();
			setGrievances(data);
		} catch (error) {
			message.error("Error fetching Tickets: " + error.message);
		}
	};

	const columns = [
		{
			title: "Issue",
			dataIndex: "issue",
			key: "issue",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Link to={`/tickets/${record.id}`}>View</Link>
				</Space>
			),
		},
	];

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const onFinish = async (values) => {
		values = { ...values, childid: sessionStorage.getItem("selectedChildId") };
		try {
			const response = await fetch(`${apiurl}/tickets/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(values),
			});
			if (!response.ok) {
				throw new Error("Failed to create Ticket");
			}
			await response.json();
			message.success("Ticket added successfully");
			form.resetFields();
			setIsModalVisible(false);
			fetchGrievances();
		} catch (error) {
			message.error("Error creating Ticket: " + error.message);
		}
	};

	return (
		<Main>
		<Card
			title="Tickets"
			extra={
				<Button type="primary" onClick={showModal}>
					Add Ticket
				</Button>
			}>
			<Table columns={columns} dataSource={grievances} rowKey="id" />
			<Modal
				title="Create Ticket"
				open={isModalVisible}
				onCancel={handleCancel}
				footer={null}>
				<Form form={form} onFinish={onFinish} layout="vertical">
					<Form.Item
						name="issue"
						label="Issue"
						rules={[{ required: true, message: "Please input the title!" }]}>
						<Input />
					</Form.Item>
					<Form.Item
						name="description"
						label="Description"
						rules={[
							{ required: true, message: "Please input the description!" },
						]}>
						<Input.TextArea />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</Card>
		</Main>
	);
}

export default TicketList;
