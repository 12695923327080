import { Button, Form, Input, message } from "antd";
import React, { useState } from "react";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";
import Loader from "../Loader/Loader";

const AttReq = () => {
	const { apiurl, token } = useAuth();
	const [loading, setLoading] = useState(false);

	const handleRequestAttendance = async (values) => {
		setLoading(true);
		values = {
			...values,
			childid: sessionStorage.getItem("selectedChildId"),
		};
		try {
			const response = await fetch(`${apiurl}/attendancerequest/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.sucess);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			console.error("Error during Attendance Request:", error);
			message.error("An error occurred while requesting attendance.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<Main>
			<h3>Request For Attendance</h3>
			{loading ? (
				<Loader />
			) : (
				<div className="req-att-main">
					<Form
						className="form"
						onFinish={handleRequestAttendance}
						layout="vertical">
						<Form.Item
							name="Date"
							label="Date"
							rules={[{ required: true, message: "Please enter the date!" }]}>
							<Input type="Date" />
						</Form.Item>
						<Form.Item
							name="Description"
							label="Description"
							rules={[
								{ required: true, message: "Please enter your request!" },
							]}>
							<Input.TextArea rows={4} />
						</Form.Item>
						<Form.Item>
							<Button type="primary" htmlType="submit">
								Submit
							</Button>
						</Form.Item>
					</Form>
				</div>
			)}
		</Main>
	);
};

export default AttReq;
