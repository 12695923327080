import React, { useState, useEffect } from "react";
import { Table, Modal, Button, message } from "antd";
import UploadHolidays from "./UploadHolidays";
import { useAuth } from "../../utils/useAuth";

const Holidays = () => {
	const { apiurl, token } = useAuth();
	const [holidaysData, setHolidaysData] = useState(null);
	const [isVisible, setIsVisible] = useState(false);
    const [count,setCount] = useState(null);

	const fetchHolidays = async () => {
		try {
			const response = await fetch(`${apiurl}/getholidays/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to fetch Holidays Data");
			}

			const data = await response.json();
			setHolidaysData(data.holidays);
            setCount(data.count)
		} catch (error) {
			message.error("Error fetching Holidays Data");
		}
	};

	useEffect(() => {
		fetchHolidays();
	}, []);

	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
	];

	const handleUploadClick = () => {
		setIsVisible(true);
	};

	const handleModalClose = () => {
		setIsVisible(false);
	};

	return (
		<>
			<div>Holidays {count}</div>
			<Button onClick={handleUploadClick}>Upload holidays</Button>
			<Table columns={columns} dataSource={holidaysData} />
			<Modal open={isVisible} onCancel={handleModalClose} footer={null}>
				<UploadHolidays />
			</Modal>
		</>
	);
};

export default Holidays;
