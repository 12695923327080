import React, { useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	Select,
	Card,
	Progress,
	message,
	Table,
	Spin,
	DatePicker,
	Statistic,
	Modal,
} from "antd";
import {
	PieChart,
	Pie,
	Cell,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";
import "./Leave.css";
import moment from "moment";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const { Option } = Select;

const Leaves = () => {
	const [value, onChange] = useState(new Date());
	const { token, apiurl } = useAuth();
	const [employees, setEmployees] = useState([]);
	const [leaveData, setLeaveData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [form] = Form.useForm();
	const [metrics, setMetrics] = useState(null);

	useEffect(() => {
		fetchData();
		fetchemployees();
	}, []);

	const fetchemployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setEmployees(data.data);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiurl}/leaves/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setLeaveData(data.data);
			setMetrics(data.metrics);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const onFinish = async (values) => {
		console.log(values);
		setLoading(true);
		values = { ...values, childid: sessionStorage.getItem("selectedChildId") };
		try {
			const response = await fetch(`${apiurl}/leaves/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});

			if (response.ok) {
				setLoading(false);
				message.success("Leave request submitted successfully");
				form.resetFields();
				fetchData();
			} else {
				setLoading(false);
				message.error("Error submitting leave request");
			}
		} catch (error) {
			setLoading(false);
			message.error("Error submitting leave request");
		}
	};

	const columns = [
		{
			title: "Applied Date",
			dataIndex: "timeStamp",
			key: "timeStamp",
			render: (text) => moment(text).format("DD-MM-YYYY"),
		},
		{
			title: "From",
			dataIndex: "fromDate",
			key: "fromDate",
			render: (text) => moment(text).format("DD-MM-YYYY"),
		},
		{
			title: "To",
			dataIndex: "toDate",
			key: "toDate",
			render: (text) => moment(text).format("DD-MM-YYYY"),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text,record) => (
				<span style={{ color: getStatusColor(record.status) }}>
					{record.status}
				</span>
			),
		},
	];
	const getStatusColor = (status) => {
		switch (status) {
			case "pending":
				return "orange";
			case "approved":
				return "green";
			case "rejected":
				return "red";
			default:
				return "";
		}
	};

	const expandedRowRender = (record) => {
		return (
			<div className="leave-expanded-row">
				<div className="items-group">
					<div className="item">
						<div className="item-label">Leave Type:</div>
						<div className="item-value">{record.type}</div>
					</div>
					<div className="item">
						<div className="item-label">Leave Duration:</div>
						<div className="item-value">{record.leavetype}</div>
					</div>
				</div>
				<div className="item">
					<div className="item-label">Reason:</div>
					<pre className="item-value">{record.reason}</pre>
				</div>
				<div className="items-group">
					<div className="item">
						<div className="item-label">Work Delegated:</div>
						<div className="item-value">
							{record.workDelegated ? record.workDelegated : "N/A"}
						</div>
					</div>
					<div className="item">
						<div className="item-label">Approving Person:</div>
						<div className="item-value">{record.approvingPerson}</div>
					</div>
				</div>
				<div className="item">
					<div className="item-label">Comments :</div>
					<pre className="item-value">
						{record.comments ? record.comments : "N/A"}
					</pre>
				</div>
			</div>
		);
	};

	const pieData = [
		{ type: "Annual", value: metrics?.annual_leaves_year },
		{ type: "Sick", value: metrics?.sick_leaves_year },
		{ type: "Personal", value: metrics?.personal_leaves_year },
		{ type: "Com Off", value: metrics?.comoff_leaves_year },
	];

	const columnData = [
		{ type: "Accepted", value: metrics?.accepted_leaves_year },
		{ type: "Rejected", value: metrics?.rejected_leaves_year },
		{ type: "Pending", value: metrics?.pending_leaves_year },
	];

	const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

	const CustomTooltip = ({ active, payload }) => {
		if (active && payload && payload.length) {
			return (
				<div className="custom-tooltip">
					<p className="label">{`${payload[0].payload.type}: ${payload[0].value}`}</p>
				</div>
			);
		}

		return null;
	};

	return (
		<Main>
			<div className="leave-main">
				<div className="section-1">
					{metrics && (
						<div className="dashboard-container">
							<div className="dashboard-item ">
								<div className="leave-summary-card bgp">
									<div className="sec-head white-col">Your Leave Summary</div>
									<div className="leave-section">
										<h4>Accrued Leaves</h4>
										<ul className="leave-list">
											<li>Casual Leaves: {metrics.allowed_casual}</li>
											<li>Sick Leaves: {metrics.allowed_sick}</li>
											<li>Comp Off Leaves: {metrics.comoff_leaves}</li>
											<li>Annual Leaves: {metrics.allowed_leaves}</li>
										</ul>
									</div>
									<div className="leave-section">
										<h4>Leaves Taken</h4>
										<ul className="leave-list">
											<li>Casual Leaves: {metrics.personal_leaves_year}</li>
											<li>Sick Leaves: {metrics.sick_leaves_year}</li>
											<li>Comp Off Leaves: {metrics.comoff_leaves_year}</li>
											<li>Annual Leaves: {metrics.annual_leaves_year}</li>
										</ul>
									</div>
									<div className="leave-section">
										<h4>Leave Balance</h4>
										<ul className="leave-list">
											<li>Carry forward Leaves: {metrics.leavebal}</li>
										</ul>
									</div>
								</div>
							</div>

							<div className="dashboard-item">
								<div className="leaves-breakdown-card">
									<div className="sec-head">Leaves Breakdown</div>
									{metrics &&
									metrics.annual_leaves_year +
										metrics.personal_leaves_year +
										metrics.comoff_leaves_year +
										metrics.sick_leaves_year >
										0 ? (
										<div>
											<Statistic
												title="Total Leaves"
												value={metrics?.accepted_leaves_year}
											/>
											<ResponsiveContainer width={300} height={250}>
												<PieChart>
													<Pie
														data={pieData}
														dataKey="value"
														nameKey="type"
														labelLine={false}
														outerRadius={80}
														label>
														{pieData.map((entry, index) => (
															<Cell
																key={`cell-${index}`}
																fill={COLORS[index % COLORS.length]}
															/>
														))}
													</Pie>
													<Tooltip content={<CustomTooltip />} />
													<Legend />
												</PieChart>
											</ResponsiveContainer>
										</div>
									) : (
										<>No Leaves in this year</>
									)}
								</div>
							</div>

							<div className="dashboard-item">
								<div className="leave-requests-card">
									<div className="sec-head">Your Leaves</div>
									<ResponsiveContainer width={350} height={250}>
										<BarChart data={columnData}>
											<CartesianGrid strokeDasharray="3 3" />
											<XAxis dataKey="type" />
											<YAxis dataKey="value" />
											<Tooltip />
											<Bar dataKey="value" fill="#8884d8" />
										</BarChart>
									</ResponsiveContainer>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="section-2">
					<div className="leave-form-cont">
						<div className="sec-head">Apply For Leave</div>
						{loading ? (
							<div className="spinner-container">
								<Spin spinning={true} size="large" />
							</div>
						) : (
							<Form
								form={form}
								layout="vertical"
								onFinish={onFinish}
								className="leave-form">
								<div>
									<div className="input-group">
										<Form.Item
											name="fromDate"
											label="Leave From"
											rules={[
												{
													required: true,
													message: "Please enter Start Date",
												},
											]}>
											<Input type="date" className="leave-input" />
										</Form.Item>

										<Form.Item
											name="toDate"
											label="Leave To"
											rules={[
												{
													required: true,
													message: "Please enter End Date",
												},
											]}>
											<Input type="date" className="leave-input" />
										</Form.Item>
									</div>
									<p className="leave-note">
										* Note that weekends and holidays will not be considered
										during calculation of duration.
									</p>
									<div className="input-group">
										<Form.Item
											name="type"
											label="Leave Type"
											rules={[
												{
													required: true,
													message: "Please select the leave type",
												},
											]}>
											<Select
												placeholder="Select leave type"
												className="leave-input">
												<Option value="annual">Annual Leave</Option>
												<Option value="sick">Sick Leave</Option>
												<Option value="personal">Casual/ Personal Leave</Option>
												<Option value="com-off">Comp Off</Option>
												<Option value="bereavement">Bereavement Leave</Option>
												<Option value="relocation">
													Relocation Support Leave
												</Option>
												<Option value="maternity">Maternity Leave</Option>
												<Option value="marriage">Marriage Leave</Option>
											</Select>
										</Form.Item>

										<Form.Item
											name="leavetype"
											label="Leave Duration"
											rules={[
												{
													required: true,
													message: "Please select the leave duration.",
												},
											]}>
											<Select
												placeholder="Select leave duration"
												className="leave-input">
												<Option value="halfday">Half Day</Option>
												<Option value="fullday">Full Day</Option>
											</Select>
										</Form.Item>
									</div>
									<Form.Item name="workDelegated" label="Work Delegated">
										<Select
											placeholder="Select the work delegated"
											className="leave-input">
											{employees.map((employee) => (
												<Select.Option key={employee.id} value={employee.id}>
													{employee.userName} - {employee.email}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
								</div>
								<div className="input-group">
									<Form.Item
										name="comments"
										label="Comments On Work Delegation">
										<Input.TextArea
											placeholder="Enter comments"
											className="leave-input ta"
										/>
									</Form.Item>
									<Form.Item
										name="reason"
										label="Reason for leave"
										rules={[
											{
												required: true,
												message: "Please enter reason for leave",
											},
										]}>
										<Input.TextArea
											placeholder="Enter Reason"
											className="leave-input ta"
										/>
									</Form.Item>
								</div>
								<Form.Item>
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						)}
					</div>
					<div>
						<Calendar onChange={onChange} value={value} />
					</div>
				</div>
				<div className="tabcont" id="leavedata">
					<div className="sec-head">Leave Records</div>
					<Table
						columns={columns}
						dataSource={leaveData}
						rowKey="id"
						expandable={{
							expandedRowRender,
						}}
					/>
				</div>
			</div>
			<Modal></Modal>
		</Main>
	);
};

export default Leaves;
