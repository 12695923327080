import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, message, Card, Input } from "antd";
import HeaderComponent from "../Header/Header";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import IpRestriction from "../IpRestriction";
import "./ip.css";

const Ipadd = () => {
	const navigate = useNavigate();
	const { apiurl, token } = useAuth();
	const [ips, setIps] = useState([]);
	const [IPaddr, setIPaddr] = useState("");
	const [ipName, setIpName] = useState("");

	useEffect(() => {
		getIps();
	}, []);

	const getIps = async () => {
		try {
			const response = await fetch(`${apiurl}/getips/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setIps(data.data);
			} else {
				throw new Error("Failed to fetch IP Data");
			}
		} catch (error) {
			message.error("Error fetching IP Data");
		}
	};

	const handleSubmit = async () => {
		try {
			const response = await fetch(`${apiurl}/setips/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					ip: IPaddr,
					name: ipName,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.message);
				setIPaddr("");
				setIpName("");
				getIps();
			} else {
				throw new Error("Failed to add IP Data");
			}
		} catch (error) {
			message.error("Error adding IP Data");
		}
	};

	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="ip-res">
				<IpRestriction />
			</div>
			<div className="ips-sec-main">
				<div className="existing-list">
					<div className="sec-head">Existing IPs:</div>
					<ol>
						{ips.map((ip, index) => (
							<li key={index} className="existing-item">
								{ip.name} ({ip.address})
							</li>
						))}
					</ol>
					<Link to="/ipexceptions" className="excep-link">
						Ip Exceptions
					</Link>
				</div>
				<div className="add-id-form">
					<div className="sec-head">Add New IP</div>
					<div className="form">
						<Input
							type="text"
							placeholder="Enter IP Address"
							value={IPaddr}
							onChange={(e) => setIPaddr(e.target.value)}
							style={{ marginTop: "10px" }}
						/>
						<Input
							type="text"
							placeholder="Enter IP Name"
							value={ipName}
							onChange={(e) => setIpName(e.target.value)}
							style={{ marginTop: "10px", marginBottom: "10px" }}
						/>
						<Button type="primary" onClick={handleSubmit}>
							Submit
						</Button>
					</div>
				</div>
			</div>
		</HeaderComponent>
	);
};

export default Ipadd;
