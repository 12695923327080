import Main from "./Main";
import { useAuth } from "../../utils/useAuth";
import { Card, message,Button,Table } from "antd";
import { useParams, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
const EmployeeData=()=>{


    useEffect(() => {
        fetchEmpData();
    }, []);
    const { month, year } = useParams();

    const [employees,SetEmployees] = useState(null);
    const { apiurl, token } = useAuth();
    const columns = [
        {
            title: "Name",
            dataIndex: "userName",
            key: "userName",
            render: (text, record) => (
				<>
                <Link to={`/hr/${record.id}`}>
                    {text}
                </Link>
				
				</>
            ),
        },
        {
            title: "Date of Joining",
            dataIndex: "dateOfJoining",
            key: "dateOfJoining",
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
        },
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
        },
        {
            title: "CTC",
            dataIndex: "ctc",
            key: "ctc",
        },
        {
            title: "Salary",
            dataIndex: "salary",
            key: "salary",
        },
        {
            title: "No of Days Present",
            dataIndex: "present",
            key: "present",
        },
        {
            title: "Leaves Taken",
            dataIndex: "leaves",
            key: "leaves",
        },
        
        {
            title: "Absent",
            dataIndex: "absent",
            key: "absent",
        },
        {
            title: "No of latelogins",
            dataIndex: "latelogin",
            key: "latelogin",
        },
        {
            title: "Carry Forwarded Leave Balance",
            dataIndex: "carryleavebal",
            key: "carryleavebal",
        },
        {
            title: "Current Leave Balance",
            dataIndex: "currentleavebal",
            key: "currentleavebal",
        },
       
    ];

    const fetchEmpData = async ()=>{
        try{
            const response = await fetch(`${apiurl}/empdataforrm/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ 'childid': sessionStorage.getItem('selectedChildId'),'month':month,'year':year })
            });
        
        const data=await response.json();
        if(response.ok){
            SetEmployees(data);
        }else{
            message.error(data.error);
        }
        console.log(employees)
    }
    catch (error) {
        message.error("An error occurred while processing your request.");
    }

    };




    return (
        <div class="empdata-table"> 
        
            <h1>Employee Data</h1>
            
            <Table
					dataSource={employees}
					columns={columns}
				/>
        </div>
    );
};
export default  EmployeeData;