import React, { useEffect, useState } from "react";
import {
	Form,
	Table,
} from "antd";

import "./Leave.css";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";

const OptionalHolidaysData = () => {
	useEffect(() => {
		fetchData();
	}, []);
	const [data, setData] = useState([]);
	const { apiurl, token } = useAuth();
	const [loading, setLoading] = useState(true);
	const fetchData = async () => {
		try {
			const response = await fetch(`${apiurl}/optdataforhr/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			setData(data);
			console.log(data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: "Employee",
			dataIndex: "employee",
			key: "employee",
		},

		{
			title: "Holiday",
			dataIndex: "holiday",
			key: "holiday",
		},
		{
			title: "Holiday Date",
			dataIndex: "date",
			key: "date",
		},

		{
			title: "Work Delegated",
			dataIndex: "workDelegated",
			key: "workDelegated",
		},
		{
			title: "Comments",
			dataIndex: "comments",
			key: "comments",
		},
	];

	return (
		<Main>
			<div className="tabcont" id="leavedata">
				<Table dataSource={data} columns={columns} rowKey="id" />
			</div>
		</Main>
	);
};

export default OptionalHolidaysData;
