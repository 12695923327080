import React, { useState } from "react";
import {  Button, Card,  Input, Popconfirm, Radio, message } from "antd";
import { CloseOutlined , CheckOutlined} from "@ant-design/icons";
import "./RegistrationPage.css";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";
import {useNavigate} from "react-router-dom";
import logo from "./../../Images/LOGO-10.png";

const RegistrationComponent = () => {
	const navigate = useNavigate();
	const [currentStep, setCurrentStep] = useState(0);
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNo, setContactNo] = useState("");
	const [noOfEmployees, setNoOfEmployees] = useState(null);
	const [noOfChilds, setNoOfChilds] = useState(null);
	const [selectedFeatures, setSelectedFeatures] = useState("");
	const [loading,setLoading] = useState(false);
	const {apiurl} = useAuth();
	
	const nextStep = () => {
		if (currentStep === 0) {
			if (
				email !== "" &&
				contactNo !== "" &&
				noOfEmployees !== null &&
				noOfEmployees !== "" &&
				noOfEmployees !== 0 &&
				noOfChilds !== "" &&
				noOfChilds !== 0 &&
				noOfChilds !== null
			) {
				setCurrentStep(currentStep + 1);
			} else {
				message.warning("Please enter all the details");
			}
		} else if (currentStep === 1) {
			if (selectedFeatures !== "") {
				setCurrentStep(currentStep + 1);
			} else {
				message.warning("Please Select the Feature");
			}
		}
	};

	const prevStep = () => {
		setCurrentStep(currentStep - 1);
	};

	const radioOnchange = (e) => {
		setSelectedFeatures(e.target.value)
	}

	const calculateAmount = ( ) => {
		const BASE_PRICE = 60;
		const PAYROLL_PRICE = 5;
		const LEAVE_PRICE = 2;
		const PER_CHILD = 100;

		const NO_OF_EMPLOYEES = noOfEmployees;
		const NO_OF_CHILDS = noOfChilds;

		let DAILY_AMOUNT;

		if (selectedFeatures === "BASIC") {
			DAILY_AMOUNT = BASE_PRICE * NO_OF_EMPLOYEES + PER_CHILD * NO_OF_CHILDS;
		} else if (selectedFeatures === "PMS") {
			DAILY_AMOUNT =
				(BASE_PRICE + PAYROLL_PRICE) * NO_OF_EMPLOYEES +
				PER_CHILD * NO_OF_CHILDS;
		} else if (selectedFeatures === "LMS") {
			DAILY_AMOUNT =
				(BASE_PRICE + LEAVE_PRICE) * NO_OF_EMPLOYEES + PER_CHILD * NO_OF_CHILDS;
		} else {
			DAILY_AMOUNT =
				(BASE_PRICE + PAYROLL_PRICE + LEAVE_PRICE) * NO_OF_EMPLOYEES +
				PER_CHILD * NO_OF_CHILDS;
		}
		const MONTHLY_AMOUNT = DAILY_AMOUNT * 30;
		const FINAL_PRICE = MONTHLY_AMOUNT ;
		return FINAL_PRICE;
	};


	const handleRegistration = async () => {
		try {
			setLoading(true);

			const finaldata = {
				fullName: fullName,
				email: email,
				contactNo: contactNo,
				noOfEmployees: noOfEmployees,
				noOfChilds: noOfChilds,
				feature: selectedFeatures,
				
			};

			const response = await fetch(`${apiurl}/accountregistration/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(finaldata),
			});
			if (!response.ok) {
				const data = await response.json();
				message.error(data.error);
				setLoading(false);
			} else {
				const data = await response.json();
				if (data) {
					message.success(data.message);
					setLoading(false);
					navigate('/login');
				}
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			message.warning(error.message);
		}
	};

	if (loading) {
		return (
				<Loader />
		);
	}

	
	return (
		<div className="registration-container">
			<div className="left-cont">
				<img src={logo}></img>
			</div>
			<div className="right-cont">
				<div className="steps-content">
					{currentStep === 0 && (
						<div className="step-content">
							<div className="step-number">Step 1/3</div>
							<div className="form-container">
								<label htmlFor="username">Username*</label>
								<Input
									required
									className="input-field"
									type="text"
									placeholder="Full Name"
									value={fullName}
									onChange={(e) => setFullName(e.target.value)}
								/>
								<label htmlFor="email">Email*</label>
								<Input
									required
									className="input-field"
									type="email"
									placeholder="Email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<label htmlFor="contactNo">Contact Number*</label>
								<Input
									required
									className="input-field"
									type="text"
									placeholder="Contact No"
									value={contactNo}
									onChange={(e) => setContactNo(e.target.value)}
								/>
								<label htmlFor="noOfEmployees">No Of Employees*</label>
								<Input
									required
									className="input-field"
									type="number"
									placeholder="No. of Employees"
									value={noOfEmployees}
									onChange={(e) => setNoOfEmployees(e.target.value)}
								/>

								<label htmlFor="noOfChilds">No of Child Accounts*</label>
								<Input
									required
									className="input-field"
									type="number"
									placeholder="No. of Child Accounts"
									value={noOfChilds}
									onChange={(e) => setNoOfChilds(e.target.value)}
								/>
							</div>
						</div>
					)}
					{currentStep === 1 && (
						<div className="step-content">
							<div className="step-number">Step 2/3</div>
							<div className="form-container">
								<div>
									<Radio.Group
										className="feature-checkbox"
										onChange={radioOnchange}
										value={selectedFeatures}>
										<Radio className="checkbox" value={"BASIC"}>
											<Card className="feature">
												<div className="plan-name">BASIC</div>
												<p>
													<CheckOutlined className="col-green" /> Employee
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Attendance
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Grievance
													Management
												</p>

												<p>
													<CloseOutlined className="col-red" /> Payroll
													Management
												</p>
												<p>
													<CloseOutlined className="col-red" /> Leave Management
												</p>
											</Card>
										</Radio>
										<Radio className="checkbox" value={"PMS"}>
											<Card className="feature">
												<div className="plan-name">BASIC + PMS</div>
												<p>
													<CheckOutlined className="col-green" /> Employee
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Attendance
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Payroll
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Grievance
													Management
												</p>

												<p>
													<CloseOutlined className="col-red" /> Leave Management
												</p>
											</Card>
										</Radio>
										<Radio className="checkbox" value={"LMS"}>
											<Card className="feature">
												<div className="plan-name">BASIC + LMS</div>
												<p>
													<CheckOutlined className="col-green" /> Employee
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Attendance
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Leave
													Management
												</p>
												<p>
													<CheckOutlined className="col-green" /> Grievance
													Management
												</p>

												<p>
													<CloseOutlined className="col-red" /> Payroll
													Management
												</p>
											</Card>
										</Radio>
										<Radio className="checkbox" value={"BASICPMSLMS"}>
											<Card className="feature">
												<div className="plan-name">BASIC + PMS + LMS</div>
												<p>
													<CheckOutlined className="col-green" />
													Employee Management
												</p>
												<p>
													<CheckOutlined className="col-green" />
													Attendance Management
												</p>
												<p>
													<CheckOutlined className="col-green" />
													Leave Management
												</p>
												<p>
													<CheckOutlined className="col-green" />
													Payroll Management
												</p>
												<p>
													<CheckOutlined className="col-green" />
													Grievance Management
												</p>
											</Card>
										</Radio>
									</Radio.Group>
								</div>
							</div>
						</div>
					)}
				
					{currentStep === 2 && (
						<div className="step-content">
							<div className="step-number">Step 3/3</div>
							<div className="form-container">
								<div className="amount-cont">
									<center>
										<h2>Please Confirm Your Details</h2>
									</center>
									<li>No of Child Accounts Needed: {noOfChilds}</li>
									<li>No of User Accounts Needed: {noOfEmployees}</li>
									<center>
										<h3>
											Amount you need to pay<br></br>{" "}
											<span className="col-red">{calculateAmount()} INR</span>
										</h3>
									</center>
								</div>
							</div>
						</div>
					)}
				</div>

				<div className="steps-action">
					{currentStep > 0 && (
						<Button className="prev-button" onClick={() => prevStep()}>
							Previous
						</Button>
					)}

					{currentStep === 2 && (
						<Popconfirm
							title="Confirm Registration"
							description="Once registered you cannot change details unless you upgrade the plan."
							onConfirm={handleRegistration}
							okText="Register">
							<Button>Register</Button>
						</Popconfirm>
					)}

					{currentStep < 2 && (
						<Button className="next-button" onClick={() => nextStep()}>
							Next
						</Button>
					)}

				</div>
			</div>
		</div>
	);
};

export default RegistrationComponent;
