import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, message, TimePicker, Checkbox } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import moment from "moment";
import "./Policy.css";

const { Group: CheckboxGroup } = Checkbox;
const weekDaysOptions = [
	"Monday",
	"Tuesday",
	"Wednesday",
	"Thursday",
	"Friday",
	"Saturday",
	"Sunday",
];

const AttendancePolicy = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [isPolicy, setIspolicy] = useState(false);
	const [id, setId] = useState("");

	useEffect(() => {
		fetchPolicy();
	}, []);

	const fetchPolicy = async () => {
		try {
			const response = await fetch(`${apiurl}/fetch-attendance-policy/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setInitialValues({
					...data.data,
					officeStartTime: moment(data.data.officeStartTime, "HH:mm"),
					officeEndTime: moment(data.data.officeEndTime, "HH:mm"),
				});
				setIspolicy(true);
			} else {
				setIspolicy(false);
			}
		} catch (error) {
			message.error(error.message || "Error fetching policy");
			setIspolicy(false);
		}
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			const formattedValues = {
				...values,
				officeStartTime: values.officeStartTime.format("HH:mm"),
				officeEndTime: values.officeEndTime.format("HH:mm"),
				childid: sessionStorage.getItem("selectedChildId"),
			};
			const response = await fetch(`${apiurl}/create-attendance-policy/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(formattedValues),
			});
			if (response.ok) {
				message.success("Policy created successfully");
				setCreateMode(false);
				fetchPolicy();
			} else {
				message.error("Failed to create policy");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error creating policy");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		try {
			setLoading(true);
			const formattedValues = {
				...values,
				id: id,
				officeStartTime: values.officeStartTime.format("HH:mm"),
				officeEndTime: values.officeEndTime.format("HH:mm"),
			};
			const response = await fetch(`${apiurl}/create-attendance-policy/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(formattedValues),
			});
			if (response.ok) {
				message.success("Policy updated successfully");
				fetchPolicy();
				setEditMode(false);
			} else {
				message.error("Failed to update policy");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error creating policy");
			setLoading(false);
		}
	};

	return (
		<div className="policy-main">
			{isPolicy ? (
				<>
					<Card
						className="policy"
						bordered={false}
						title="Attendance Policy"
						extra={
							<Button
								icon={<EditOutlined />}
								onClick={() => {
									setEditMode(true);
									setId(initialValues.id);
								}}
								disabled={editMode}
							/>
						}>
						<p>
							<span>Office Start Time</span>{" "}
							{initialValues.officeStartTime.format("HH:mm")}{" "}
						</p>
						<p>
							<span>Office End Time</span>{" "}
							{initialValues.officeEndTime.format("HH:mm")}{" "}
						</p>
						<p>
							<span>Working Days</span> {initialValues.workingDays}
						</p>
					</Card>
				</>
			) : (
				<>
					<h3>Policy Not Yet Created.</h3>
					<Button onClick={() => setCreateMode(true)}>Create Policy</Button>
				</>
			)}
			<Modal
				open={createMode}
				title="Create Attendance Policy"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={handleCreate}>
					<Form.Item required name="officeStartTime" label="Office Start Time">
						<TimePicker format="HH:mm" />
					</Form.Item>
					<Form.Item required name="officeEndTime" label="Office End Time">
						<TimePicker format="HH:mm" />
					</Form.Item>
					<Form.Item required name="workingDays" label="Working Days">
						<CheckboxGroup options={weekDaysOptions} />
					</Form.Item>
				</Form>
			</Modal>

			<Modal
				open={editMode}
				title="Edit Attendance Policy"
				destroyOnClose
				onCancel={() => setEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					initialValues={initialValues}
					layout="vertical"
					onFinish={handleUpdate}>
					<Form.Item required name="officeStartTime" label="Office Start Time">
						<TimePicker format="HH:mm" />
					</Form.Item>
					<Form.Item required name="officeEndTime" label="Office End Time">
						<TimePicker format="HH:mm" />
					</Form.Item>
					<Form.Item required name="workingDays" label="Working Days">
						<CheckboxGroup options={weekDaysOptions} />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default AttendancePolicy;
