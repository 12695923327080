import React, { useEffect, useState } from "react";
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
} from "recharts";
import { useAuth } from "../utils/useAuth";

const AttendanceSummary = () => {
    const {apiurl,token} = useAuth();
	const [attendanceSummary, setAttendanceSummary] = useState({
		month_1: {
			present: 0,
			latelogin: 0,
			halfday: 0,
			absent: 0,
			leave: 0,
		},
		month_2: {
			present: 0,
			latelogin: 0,
			halfday: 0,
			absent: 0,
			leave: 0,
		},
		month_3: {
			present: 0,
			latelogin: 0,
			halfday: 0,
			absent: 0,
			leave: 0,
		},
	});

	useEffect(() => {
		const fetchAttendanceSummary = async () => {
			try {
				const response = await fetch(`${apiurl}/attendancesummary/`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				setAttendanceSummary(data);
			} catch (error) {
				console.error(
					"There was an error fetching the attendance summary!",
					error
				);
			}
		};

		fetchAttendanceSummary();
	}, [token]);

	const prepareChartData = () => {
		const chartData = [];
		const monthNames = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];

		const currentDate = new Date();

		for (let month = 1; month <= 3; month++) {
			const currentMonthIndex = (currentDate.getMonth() - month) % 12;
			const monthName = monthNames[currentMonthIndex]; 
			const year = currentDate.getFullYear() - (currentDate.getMonth() < month ? 1 : 0);
			chartData.push({
				name: `${monthName} - ${year}`,
				"On Time": attendanceSummary[`month_${month}`].present,
				"Late Login": attendanceSummary[`month_${month}`].latelogin,
				"Half Day": attendanceSummary[`month_${month}`].halfday,
				Absent: attendanceSummary[`month_${month}`].absent,
				Leave: attendanceSummary[`month_${month}`].leave,
			});
		}
		return chartData;
	};

	const chartData = prepareChartData();

	return (
		<div className="attendancesummary-main">
			<div className="sec-head">Quarterly Attendance Insights</div>
			<BarChart
				width={600}
				height={300}
				data={chartData}
				margin={{
					top: 25,
					right: 0,
					left: 0,
					bottom: 5,
				}}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend />
				<Bar dataKey="On Time" fill="#82ca9d" />
				<Bar dataKey="Late Login" fill="#8884d8" />
				<Bar dataKey="Half Day" fill="#ffc658" />
				<Bar dataKey="Absent" fill="#d0ed57" />
				<Bar dataKey="Leave" fill="#a4de6c" />
			</BarChart>
		</div>
	);
};

export default AttendanceSummary;
