import React, { useEffect, useState } from 'react'
import { useAuth } from '../../utils/useAuth';
import { Button, Modal, Popconfirm, message } from 'antd';
import { Link } from 'react-router-dom';
import PaymentComponent from '../../RegistrationPage/Payment';
import './Home.css';
import AddChild from '../../ChildAccount/AddChild';
import Main from './Layouts/Main';

const SUPER_USER_HOME = () => {
    const { apiurl,token } = useAuth();
    const [orgStatus,setOrgStatus] = useState(false);
    const [orgData,setOrgData] = useState(null);
	const [subStatus,setSubStatus] = useState(false);
	const [plan,setPlan] = useState("");
	const [empCount, setEmpCount] = useState(0);
	const [childCount, setChildCount] = useState(0);
	const [childs,setChilds] = useState(null);
	
	const [isModalVisible,setIsModalVisible] = useState(false);
	
    useEffect(() => {
			fetchSubStatus();
	}, []);

	useEffect(() => {
		if (subStatus) {
			fetchOrgStatus();
		}
	}, [subStatus]);

	useEffect(() => {
		if(orgStatus){
			fetchChilds();
		}
	}, [orgStatus]);

	const fetchSubStatus = async () => {
		try {
			const response = await fetch(`${apiurl}/is-sub`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setSubStatus(data.status);
				setPlan(data.plan);
				setChildCount(data.childs);
				setEmpCount(data.employees);
			} else {
				message.error("Unable to find organization please try again");
			}
		} catch (error) {
			message.error(error);
		}
	};
		const fetchOrgStatus = async () => {
			try {
				const response = await fetch(`${apiurl}/is-org-created`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					console.log(data)
					setOrgStatus(data.status);
					setOrgData(data.data);
				} else {
					message.error('Unable to find organization please try again');
				}
			} catch (error) {
				message.error(error);
			}
		};

		const fetchChilds = async () => {
			try {
				const response = await fetch(`${apiurl}/fetchchilds`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					console.log(data);
					setChilds(data.data);
				} else {
					message.error("Unable to find childs please try again");
				}
			} catch (error) {
				message.error(error);
			}
		};
		
		

	if(!subStatus){
		return(
				<PaymentComponent/>
		)
	}
	
    return (
			<Main>
				{orgStatus ? (
					<div className="super-user-home">
						<div className="organization-details">
							<div className="org-det">
								<div className="section-head">Organization Details</div>
								<div className="org-info">
									<div>
										Name: <span>{orgData?.orgname}</span>
									</div>
									<div>
										Type: <span>{orgData?.type}</span>
									</div>
									<div>
										Reg No: <span>{orgData?.regNo}</span>
									</div>
									<div>
										Registration Date:{" "}
										<span>{orgData?.companyRegistrationDate}</span>
									</div>
									<div>
										Company Gst Reg No: <span>{orgData?.companyGstRegNo}</span>
									</div>
									<div>
										Company Pan No: <span>{orgData?.companyPanNo}</span>
									</div>
									<div>
										Company Tan No: <span>{orgData?.companyTanNo}</span>
									</div>
								</div>
							</div>
							<div className="con-det">
								<div className="section-head">Contact Person</div>
								<div className="info">
									<div>
										Name: <span>{orgData?.contactPerson}</span>
									</div>
									<div>
										Contact No: <span>{orgData?.contactNo}</span>
									</div>
									<div>
										Email: <span>{orgData?.email}</span>
									</div>
									<div>
										Designation: <span>{orgData?.designation}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="second-section">
							<div className="plan-details">
								<div className="section-head">Your Plan</div>
								<div className="info">
									<div>
										Subcribed Plan: <span>{plan}</span>
									</div>
									<div>
										Child Accounts Allowed: <span>{childCount}</span>
									</div>
									<div>
										User Accounts Allowed: <span>{empCount}</span>
									</div>
								</div>
							</div>
							<div className="child-details">
								<div className="section-head">Child Accounts</div>
								<div className="child-info">
									{childs &&
										childs.map((child) => (
											<Link to={`/child-details/${child.id}`}>
												<div className="child">{child.name}</div>
											</Link>
										))}
									<div className="add-new">
										{childs?.length < childCount ? (
											<Button type='text' onClick={()=>setIsModalVisible(true)}>Add More</Button>
										) : (
											<div className="col-red" style={{padding:"10px"}}>Limit Reached</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<Link to="/addorg">Add organization</Link>
				)}
				<Modal
					title="Add New Child"
					open={isModalVisible}
					destroyOnClose = {true}
					onCancel={() => {
						fetchChilds();
						setIsModalVisible(false);
					}}
					footer={null}>
						<AddChild />
					</Modal>
			</Main>
		);

}

export default SUPER_USER_HOME