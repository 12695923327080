import React, { useState, useEffect } from "react";
import "./Clock.css";

const StaticCard = ({ position, digit }) => {
	return (
		<div className={position}>
			<span>{digit}</span>
		</div>
	);
};

const AnimatedCard = ({ animation, digit }) => {
	return (
		<div className={`flipCard ${animation}`}>
			<span>{digit}</span>
		</div>
	);
};

const FlipUnitContainer = ({ digit, shuffle, unit }) => {
	let currentDigit = digit;
	let previousDigit = digit - 1;

	if (unit !== "hours") {
		previousDigit = previousDigit === -1 ? 59 : previousDigit;
	} else {
		previousDigit = previousDigit === -1 ? 23 : previousDigit;
	}

	currentDigit = currentDigit < 10 ? `0${currentDigit}` : currentDigit;
	previousDigit = previousDigit < 10 ? `0${previousDigit}` : previousDigit;

	const digit1 = shuffle ? previousDigit : currentDigit;
	const digit2 = !shuffle ? previousDigit : currentDigit;
	const animation1 = shuffle ? "fold" : "unfold";
	const animation2 = !shuffle ? "fold" : "unfold";

	return (
		<div className="flipUnitContainer">
			<StaticCard position="upperCard" digit={currentDigit} />
			<StaticCard position="lowerCard" digit={previousDigit} />
			<AnimatedCard digit={digit1} animation={animation1} />
			<AnimatedCard digit={digit2} animation={animation2} />
		</div>
	);
};

const FlipClock = () => {
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const [seconds, setSeconds] = useState(0);
	const [hoursShuffle, setHoursShuffle] = useState(true);
	const [minutesShuffle, setMinutesShuffle] = useState(true);
	const [secondsShuffle, setSecondsShuffle] = useState(true);

	useEffect(() => {
		const timerID = setInterval(() => {
			const time = new Date();
			const newHours = time.getHours();
			const newMinutes = time.getMinutes();
			const newSeconds = time.getSeconds();

			if (newHours !== hours) {
				setHours(newHours);
				setHoursShuffle(!hoursShuffle);
			}

			if (newMinutes !== minutes) {
				setMinutes(newMinutes);
				setMinutesShuffle(!minutesShuffle);
			}

			if (newSeconds !== seconds) {
				setSeconds(newSeconds);
				setSecondsShuffle(!secondsShuffle);
			}
		}, 50);

		return () => clearInterval(timerID);
	}, [hours, minutes, seconds, hoursShuffle, minutesShuffle, secondsShuffle]);

	return (
		<div className="flipclock-main">
			<div className="qt">
				''Don't watch the clock; do what it does. Keep going.''
			</div>
			<div className="current-date">{new Date().toDateString()}</div>
			<div className="flipClock">
				<FlipUnitContainer
					unit={"hours"}
					digit={hours}
					shuffle={hoursShuffle}
				/>
				<FlipUnitContainer
					unit={"minutes"}
					digit={minutes}
					shuffle={minutesShuffle}
				/>
				<FlipUnitContainer
					unit={"seconds"}
					digit={seconds}
					shuffle={secondsShuffle}
				/>
			</div>
		</div>
	);
};

export default FlipClock;