import React, { useEffect, useState } from "react";
import { Table, Button, Modal, Select, message, Popconfirm } from "antd";
import { useAuth } from "../utils/useAuth";

const { Option } = Select;

const IPException = () => {
	const [exceptionEmployees, setExceptionEmployees] = useState([]);
	const [allEmployees, setAllEmployees] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
    const {apiurl,token} = useAuth();

	useEffect(() => {
		fetchExceptionEmployees();
	}, []);

    const fetchExceptionEmployees = async () => {
			try {
				const response = await fetch(`${apiurl}/getipexceptions/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						childid: sessionStorage.getItem("selectedChildId"),
					}),
				});
				if (response.ok) {
					const data = await response.json();
					console.log(data);
					setExceptionEmployees(data.data);
				} else {
					message.error("Unable to find Employees. Please try again.");
				}
			} catch (error) {
				message.error(error);
			}
		};

	const fetchAllEmployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setAllEmployees(data.data);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const handleAddEmployee = () => {
		fetchAllEmployees();
		setIsModalVisible(true);
	};

	const handleSubmit = async () => {
		if (!selectedEmployee) {
			message.error("Please select an employee");
			return;
		}

		try {
			const response = await fetch(`${apiurl}/ipexceptions/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ empid: selectedEmployee }),
			});

			if (!response.ok)
				throw new Error("Failed to add employee to exception list");
			const data = await response.json();
			setExceptionEmployees([...exceptionEmployees, data]);
			message.success("Successfully added employee to exception list");
			setIsModalVisible(false);
			setSelectedEmployee(null);
		} catch (error) {
			message.error(error.message);
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/ipexceptions/`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id }),
			});

			if (!response.ok)
				throw new Error("Failed to delete employee from exception list");
			setExceptionEmployees(exceptionEmployees.filter((emp) => emp.id !== id));
			message.success("Successfully deleted employee from exception list");
		} catch (error) {
			message.error(error.message);
		}
	};

	const columns = [
		{ title: "Employee", dataIndex: "employee", key: "employee" },
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Popconfirm
					title="Are you sure to delete this employee?"
					onConfirm={() => handleDelete(record.id)}
					okText="Yes"
					cancelText="No">
					<Button type="link">Delete</Button>
				</Popconfirm>
			),
		},
	];

	return (
		<div>
			<Button
				type="primary"
				onClick={handleAddEmployee}
				style={{ marginBottom: "20px" }}>
				Add Employee
			</Button>
			{exceptionEmployees.length > 0 && (
				<Table dataSource={exceptionEmployees} columns={columns} rowKey="id" />
			)}

			<Modal
				title="Add Employee to Exception List"
				open={isModalVisible}
				onCancel={() => setIsModalVisible(false)}
				onOk={handleSubmit}>
				<Select
					style={{ width: "100%" }}
					placeholder="Select an employee"
					onChange={setSelectedEmployee}>
					{allEmployees.map((employee) => (
						<Option key={employee.id} value={employee.id}>
							{employee.userName} ({employee.employeeid})
						</Option>
					))}
				</Select>
			</Modal>
		</div>
	);
};

export default IPException;
