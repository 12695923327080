import React from "react";
import HeaderComponent from "../Header/Header";
import LeavePolicy from "./LeavePolicy";
import { LeftCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import PayrollPolicy from "./PayrollPolicy";
import AttendancePolicy from "./AttendancePolicy";
import AttendanceRequestPolicy from "./AttendanceRequestPolicy";

const Policy = () => {
	const navigate = useNavigate();
	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div>
				<div className="policies-types">
					<div className="policy-item">
						<LeavePolicy />
					</div>
					<div className="policy-item">
						<PayrollPolicy />
						<AttendanceRequestPolicy />
					</div>
					<div className="policy-item">
						<AttendancePolicy />
					</div>
				</div>
			</div>
		</HeaderComponent>
	);
};

export default Policy;
