import React, { useState, useEffect } from "react";
import {
	Table,
	Button,
	Modal,
	Form,
	Input,
	InputNumber,
	Select,
	message,
} from "antd";
import { useAuth } from "../utils/useAuth";

const { Option } = Select;

const PfSlabsComponent = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [editingItem, setEditingItem] = useState(null);
	const [form] = Form.useForm();
	const { apiurl, token } = useAuth();

	const childId = sessionStorage.getItem("selectedChildId");

	useEffect(() => {
		fetchData();
	}, [childId]);

	const fetchData = async () => {
		setLoading(true);
		try {
			const response = await fetch(
				`${apiurl}/pfslabs/${childId ? `${childId}/` : ""}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);
			const result = await response.json();
			if (response.ok) {
				setData(result);
			} else {
				message.error("Failed To fetch data");
			}
		} catch (error) {
			message.error("Failed to fetch data");
		} finally {
			setLoading(false);
		}
	};

	const showModal = (item) => {
		setEditingItem(item);
		form.setFieldsValue(item ? { ...item } : { unit: "percent" });
		setIsModalVisible(true);
	};

	const handleOk = async () => {
		try {
			const values = await form.validateFields();
			if (editingItem) {
				const response = await fetch(`${apiurl}/pfslab/${editingItem.id}/`, {
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(values),
				});
				if (response.ok) {
					message.success("Item updated successfully");
				} else {
					message.error("Failed To fetch data");
				}
			} else {
				const response = await fetch(
					`${apiurl}/pfslabs/${childId ? `${childId}/` : ""}`,
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Authorization: `Bearer ${token}`,
						},
						body: JSON.stringify(values),
					}
				);
				if (response.ok) {
					message.success("Item added successfully");
				} else {
					message.error("Failed To fetch data");
				}
			}
			fetchData();
			setIsModalVisible(false);
			form.resetFields();
		} catch (error) {
			message.error("Failed to save data");
		}
	};

	const handleDelete = async (id) => {
		try {
			const response = await fetch(`${apiurl}/pfslab/${id}/`, {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				message.success("Item deleted successfully");
			} else {
				message.error("Failed To fetch data");
			}

			fetchData();
		} catch (error) {
			message.error("Failed to delete item");
		}
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		form.resetFields();
	};

	const columns = [
		{
			title: "Start Amount",
			dataIndex: "start_amount",
			key: "start_amount",
		},
		{
			title: "End Amount",
			dataIndex: "end_amount",
			key: "end_amount",
		},
		{
			title: "PF Amount",
			dataIndex: "pf_amount",
			key: "pf_amount",
		},
		{
			title: "Unit",
			dataIndex: "unit",
			key: "unit",
			render: (text) => (text === "percent" ? "Percentage" : "Amount"),
		},
		{
			title: "Actions",
			key: "actions",
			render: (text, record) => (
				<span>
					<Button onClick={() => showModal(record)}>Edit</Button>
					<Button onClick={() => handleDelete(record.id)} danger>
						Delete
					</Button>
				</span>
			),
		},
	];

	return (
		<div>
			<Button type="primary" onClick={() => showModal(null)}>
				Add New
			</Button>
			<Table
				columns={columns}
				dataSource={data}
				rowKey="id"
				loading={loading}
			/>

			<Modal
				title={editingItem ? "Edit Item" : "Add New Item"}
				visible={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}>
				<Form form={form} layout="vertical">
					<Form.Item
						name="start_amount"
						label="Start Amount"
						rules={[
							{ required: true, message: "Please input the start amount!" },
						]}>
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item
						name="end_amount"
						label="End Amount"
						rules={[
							{ required: true, message: "Please input the end amount!" },
						]}>
						<InputNumber min={0} />
					</Form.Item>
					<Form.Item
						name="pf_amount"
						label="PF Amount"
						rules={[
							{ required: true, message: "Please input the PF amount!" },
						]}>
						<InputNumber min={0} max={9999.99} />
					</Form.Item>
					<Form.Item
						name="unit"
						label="Unit"
						rules={[{ required: true, message: "Please select the unit!" }]}>
						<Select>
							<Option value="percent">Percentage</Option>
							<Option value="amount">Amount</Option>
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default PfSlabsComponent;
