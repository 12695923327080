import { Button, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import Success from "../utils/Success";
import './Style.css';
const AddEmpToRole = ({ role, child_id }) => {
	const [employees, setEmployees] = useState([]);
	const { token, apiurl } = useAuth();
	const [user, setUser] = useState("");
    const [loading,setLoading] = useState(false);
    const [isSuccess,setIsSuccess] = useState(false);

	useEffect(() => {
		fetchemployees();
	}, []);

	const fetchemployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setEmployees(data.data);
                // setIsSuccess(true);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const handleUpdateRole = async () => {
        setLoading(true);
		try {
			const response = await fetch(`${apiurl}/addrole/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					role: role,
					user: user,
					child_id: child_id,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
                setIsSuccess(true)
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error);
		}
        finally{
            setLoading(false);
        }
	};

    if(loading){
        return (
            <>loading</>
        )
    }

    if(isSuccess){
        return(
            <Success title = {role + " Added Successfully"}/>
        )
    }

	return (
		<div className="add-emp">
			<div className="form-container">
				<div className="section-head">Select the employee</div>
				<div>
					<label>Role:</label>
					<Input value={role} readOnly></Input>
				</div>
				<div>
					<label>Employee:</label>
					<Select
						placeholder="Select an employee"
						value={user}
						onChange={(value) => setUser(value)}>
						{employees.map((employee) => (
							<Select.Option key={employee.id} value={employee.id}>
								{employee.userName} - {employee.email}
							</Select.Option>
						))}
					</Select>
				</div>
			</div>
            <center>
			<Button className="btn" type="submit" onClick={handleUpdateRole}>
				Submit
			</Button></center>
		</div>
	);
};

export default AddEmpToRole;
