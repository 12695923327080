import React, { useState } from "react";
import { Form, Input, Button, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { createRef } from "react";
import { useAuth } from "../utils/useAuth";


const OtpVerification = () => {
  const [otpValue, setOtpValue] = useState("");
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [resendOtpLoading, setResendOtpLoading] = useState(false);
  const { user , apiurl} = useAuth();
  const emailValue = user.email;
  const inputRefs = Array(6)
		.fill()
		.map(() => createRef());
	const handleInput = (index, event) => {
		const value = event.target.value;

		if (value === "" && index > 0) {
			inputRefs[index - 1].current.focus();
		} else if (index < inputRefs.length - 1 && value !== "") {
			inputRefs[index + 1].current.focus();
		}

		const updatedOtpValue = otpValue.split("");
		updatedOtpValue[index] = value;
		setOtpValue(updatedOtpValue.join(""));
	};

	const handleKeyDown = (index, event) => {
		if (event.key === "Backspace" && index > 0 && event.target.value === "") {
			inputRefs[index - 1].current.focus();

			const updatedOtpValue = otpValue.split("");
			updatedOtpValue[index - 1] = "";
			setOtpValue(updatedOtpValue.join(""));
		}
	};
  

  const handleOtpSend = async () => {
    try {
      const url = new URL(`${apiurl}/otp/`);
		url.searchParams.append("email", emailValue);
		const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        setOtpModalVisible(true);
      } else {
        throw new Error("Failed to send OTP");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

 const handleOtpVerification = async () => {
		try {
			const response = await fetch(`${apiurl}/otp/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					email: emailValue,
					otp: otpValue,
				}),
			});

			if (response.ok) {
				message.success("OTP verification successful");
				setTimeout(() => {
					navigate("/dashboard");
				}, 2000);
			} else {
				throw new Error("Invalid OTP");
			}
		} catch (error) {
			message.error(error.message);
		}
 };


  const handleResendOtp = async () => {
    setResendOtpLoading(true);

    try {
      const url = new URL(`${apiurl}/otp/`);
			url.searchParams.append("email", emailValue);
			const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          email: emailValue,
        },
      });

      if (response.ok) {
        setResendOtpLoading(false);
        message.success("OTP resent successfully");
      } else {
        throw new Error("Failed to resend OTP");
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  

  return (
		<div>
			<center>
				<h2>OTP VERIFICATION</h2>
			</center>
			<center>
				<p>Click the button bellow to send otp to your email</p>
			</center>
			<Form form={form} onFinish={handleOtpSend}>
				<Form.Item>
					<center>
						<Button type="primary" htmlType="submit" className="lb">
							Send OTP
						</Button>
					</center>
				</Form.Item>
			</Form>

			<Modal
				open={otpModalVisible}
				title="OTP Verification"
				onCancel={() => setOtpModalVisible(false)}
				footer={null}
        >
        <div className="inn">
				<Form onFinish={handleOtpVerification} layout="vertical">
					<Form.Item
						name="otp"
						label="Enter Your OTP"
						rules={[
							{
								required: true,
								message:"Enter the complete otp"
							},
						]}>
						<Input.Group compact>
							{Array(6)
								.fill()
								.map((_, index) => (
									<Form.Item
										key={index}
										name={["otp", `digit${index + 1}`]}
										noStyle
										>
										<Input
											ref={inputRefs[index]}
											style={{ width: "10%", marginRight: "8px" , border: "1px solid black" }}
											maxLength={1}
											onInput={(event) => handleInput(index, event)}
											onKeyDown={(event) => handleKeyDown(index, event)}
										/>
									</Form.Item>
								))}
						</Input.Group>
					</Form.Item>

					<Form.Item>
          <div className="btf">
						<Button type="primary" htmlType="submit" style={{marginRight:"10px"}}>
							Verify OTP
						</Button>
						<Button onClick={handleResendOtp} loading={resendOtpLoading}>
							Resend OTP
						</Button>
            </div>
					</Form.Item>
				</Form>
        </div>
			</Modal>
		</div>
	);
};

export default OtpVerification;
