import React, { useEffect, useState } from "react";
import { Button, Modal, Result, Table, message } from "antd";
import {
	CreditCardOutlined,
	ExpandOutlined,
	SmileOutlined,
	CloudUploadOutlined,
} from "@ant-design/icons";
import AttUpload from "./Upload";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";

const AttendanceMain = () => {
	const {apiurl,token} = useAuth();
	const [modalVisible, setModalVisible] = useState(false);
	const [uploadmodal, setUploadModal] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [attData,setAttData] = useState(null);
	const [myAttData,setMyAttData] = useState(null);

	const openModal = (option) => {
		setSelectedOption(option);
		setModalVisible(true);
	};

	const closeModal = () => {
		setModalVisible(false);
	};

	const handleButtonClick = (option) => {
		if (option !== "Bulk upload") {
			openModal(option);
		} else {
			setUploadModal(true);
		}
	};

	useEffect(() => {
		fetchAttendance();
		fetchMyAttendance();
	}, []);

	const fetchAttendance = async () => {
		try {
			const response = await fetch(`${apiurl}/getattendance/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Attendance Data");
			}
			const data = await response.json();
			setAttData(data.data);
		} catch (error) {
			message.error("Error fetching Attendance Data");
		}
	};
	const fetchMyAttendance = async () => {
		try {
			const response = await fetch(`${apiurl}/userattendance/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Attendance Data");
			}
			const data = await response.json();
			setMyAttData(data.data);
		} catch (error) {
			message.error("Error fetching Attendance Data");
		}
	};

	const columns = [
		{
			title: "Employee",
			dataIndex: "employee",
			key: "employee",
		},
		{
			title: "Parent",
			dataIndex: "parent",
			key: "parent",
		},
		{
			title: "Child",
			dataIndex: "child",
			key: "child",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Time In",
			dataIndex: "time_in",
			key: "time_in",
		},
		{
			title: "Time Out",
			dataIndex: "time_out",
			key: "time_out",
		},
		{
			title: "Net Time In",
			dataIndex: "net_time_in",
			key: "net_time_in",
		},
	];
	return (
		<Main>
		<div className="att-main">
			<div className="section-1">
			<div className="section-head">Add Attendance</div>
			<div className="att-btns">
				<Button
					type="primary"
					onClick={() => handleButtonClick("Card reader")}
					icon={<CreditCardOutlined />}
					style={{ width: 150 }}>
					Card reader
				</Button>

				<Button
					type="primary"
					onClick={() => handleButtonClick("Biometric")}
					icon={<ExpandOutlined />}
					style={{ width: 150 }}>
					Biometric
				</Button>

				<Button
					type="primary"
					onClick={() => handleButtonClick("Facial")}
					icon={<SmileOutlined />}
					style={{ width: 150 }}>
					Facial
				</Button>

				<Button
					type="primary"
					onClick={() => handleButtonClick("Bulk upload")}
					icon={<CloudUploadOutlined />}
					style={{ width: 150 }}>
					Bulk upload
				</Button>
			</div>
				<Modal
					title={selectedOption}
					open={modalVisible}
					onCancel={closeModal}
					footer={null}>
					<Result
						icon={<SmileOutlined />}
						title={
							<>
								<p>
									{selectedOption} is currently in development.We'll update you
									once it's ready!
								</p>
							</>
						}
					/>
				</Modal>

				<Modal
					open={uploadmodal}
					destroyOnClose
					onCancel={() => setUploadModal(false)}
					footer={null}>
					<AttUpload />
				</Modal>
			</div>
			<div className="att-records">
			<div className="section-head">Attendance Data</div>
				<Table bordered dataSource={attData} columns={columns} />
			</div>
			</div>
		</Main>
	);
};

export default AttendanceMain;
