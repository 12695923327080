import React,{useEffect, useState} from "react";
import { useAuth } from '../utils/useAuth'
import Main from '../Homepages/Layouts/Main';
import { message ,Table,Popconfirm,Button} from 'antd';


const HRAttendanceRequest = () => { 
    const { apiurl ,token} = useAuth();
    const [data,setData]=useState(null);
 useEffect(()=>{
getdata();
// updateStatus();
 },[]);
 const updateStatus = async (id, status) => {
    try {
        const response = await fetch(`${apiurl}/attendancerequest/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id: id,
                status: status,
                childid: sessionStorage.getItem("selectedChildId"),
            }),
        });
        const data = await response.json();
        if (response.ok) {
            console.log(data)
            message.success(data.success);
            getdata();
        } else {
            message.error(data.error);
        }
    } catch (error) {
        message.error(error);
    }
};
const getdata = async ()=>{
    try{
    const response= await fetch(`${apiurl}/hrattrequests/`,{
        method:"POST",
		headers:{
			"Content-Type":"application/json",
			 Authorization: `Bearer ${token}`,
		},
        body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})


    });
    if (response.ok){
        const data1=await response.json()
        console.log(data1)
        setData(data1.data)

    }
}
    catch(error) {
        message.error("Failed to fetch attendance requests")
    }

};
const columns=[
    { title: "id", dataIndex: "id", key: "id" },
    { title: "Employee", dataIndex: "employee", key: "employee" },
    { title: "Date", dataIndex: "Date", key: "Date" },
    { title: "reason", dataIndex: "reason", key: "reason" },
    { title: "status", dataIndex: "status", key: "status" },
    
   {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
            <>
                {record.status === "underreview" && (
                    <>
                        <Popconfirm
                            title="Confirm"
                            onConfirm={() => updateStatus(record.id, "approved")}
                            okText="Approve">
                            <Button>Approve</Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Confirm"
                            onConfirm={() => updateStatus(record.id, "rejected")}
                            okText="Reject">
                            <Button>Reject</Button>
                        </Popconfirm>
                    </>
                )}
            </>
        ),
    },
]

  return (
<Main>
<div className="approvals-main">
<div className="section-head">Attendance Requests Created</div>

<Table columns={columns} dataSource ={data} />

</div>
  </Main>
 
);
};

export default HRAttendanceRequest;