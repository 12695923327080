import React, { useState } from 'react';
import { useAuth } from "./utils/useAuth";

const AddQuote = () => {
  const [file, setFile] = useState(null);
  const { apiurl, token } = useAuth();

  const handleFileChange = (event) => {

    setFile(event.target.files[0]);
    console.log("file changed", event.target.files[0])
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    

    try {
      const response = await fetch(`${apiurl}/addquotes/`, {
        method: 'POST',
        headers: {
					
					Authorization: `Bearer  ${token}`,
				},
        body: formData,
      });
      console.log(formData)

      if (response.ok) {
        const data = await response.json();
        
        alert('File uploaded successfully');
        console.log('Response data:', data);
      } else {
        alert('Failed to upload file');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while uploading the file');
    }
  };

  return (
    <div>
      <h2>Add a Quote</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <input type="file" onChange={handleFileChange} />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default AddQuote;
