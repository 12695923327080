import React from "react";
import "./Loader.css";
import gif from "./loader.gif";

const Loader = () => {
	return (
		<div className="loader-teacher">
			<img src={gif} className="loader-image" alt="loader"></img>
		</div>
	);
};

export default Loader;
