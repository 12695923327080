import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Table, message, Select, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";
import { useParams } from "react-router-dom";
const { Option } = Select;

const Occasions = () => {
	const types = ["birthday", "marriageday"];
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const { token, apiurl } = useAuth();
	const id=useParams();

	useEffect(() => {
		fetchOccations();
	}, []);

	const fetchOccations = async () => {
		try {
			const response = await fetch(`${apiurl}/getoccasions/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify(id)
			});
			if (!response.ok) {
				throw new Error("Failed to fetch occasions");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching occasions");
		}
	};

	const handleEdit = (item) => {
		setSelectedItem(item);
		setEditMode(true);
		form.setFieldsValue(item);
	};

	

	const columns = [
		{
			title: "Occasion",
			dataIndex: "type",
			key: "type",
		},
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
	];



	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Occasion Details"
				>
				<Table
					className="relation-table"
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20 }}
				/>
			</Card>
			
			
		</div>
	);
};

export default Occasions;
