import React, { useState } from "react";
import { Upload, Button, message, Spin, Table } from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";
import Papa from "papaparse";


const { Dragger } = Upload;

const BulkUpload = () => {
	const [file, setFile] = useState(null);
	const navigate = useNavigate();
	const { apiurl, token } = useAuth();
	const [loading, setLoading] = useState(false);
    const [parsedData, setParsedData] = useState([]);

		const [tableColumns, setTableColumns] = useState([]);
		const [tableData, setTableData] = useState([]);

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(`Upload failed.`);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
        Papa.parse(file, {
					header: true,
					skipEmptyLines: true,
					complete: function (results) {
						const rowsArray = [];
						const valuesArray = [];
						console.log(results);
						results.data.forEach((d) => {
							rowsArray.push(Object.keys(d));
							valuesArray.push(Object.values(d));
						});

						setParsedData(results.data);
						setTableColumns(rowsArray[0]);
						setTableData(results.data);
					},
				});
	};

	const handleSubmit = async () => {
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append("childid" ,sessionStorage.getItem('selectedChildId'))
			formData.append("file", file);
			
			const headers = {
				Authorization: `Bearer ${token}`,
			};

			const response = await fetch(`${apiurl}/bulkupload/`, {
				method: "POST",
				body: formData,
				headers: headers,
			});
			if (response.ok) {
				const data = await response.json();
				if (data) {
					setLoading(false);
					message.success(data.message);
					navigate('/home')
				}
				
			} else {
				const data = await response.json();
				setLoading(false);
				message.error(data.error);
			}
		} else {
			setLoading(false);
			message.warning("Please select a file to upload.");
		}
	};


	if (loading) {
		return (
			<div className="loading-container">
				<Spin
					tip="Uploading"
					size="large"
					indicator={
						<LoadingOutlined
							style={{
								fontSize: 30,
							}}
							spin
						/>
					}></Spin>
			</div>
		);
	}
    const columns = tableColumns.map((column, index) => {
			return {
				title: column,
				key: "Employee details",
				dataIndex: column,
			};
		});

	return (
		<div className="name-sheet-main">
            <h3>Add Employees</h3>
			<h4>
				Give a csv file with fields : userName, email,
				phoneNumber,dateOfBirth,designation,department,type,gender,reported
				to(email)(not mandatory)
			</h4>
			<h5 style={{ color: "red" }}>Make sure the header is same as above </h5>
			<Dragger
				className="name-sheet-upload"
				accept=".csv"
				maxCount={1}
				customRequest={customRequest}
				onChange={handleFileChange}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag file to this area to upload
				</p>
				<p className="ant-upload-hint">Upload only .csv files</p>
			</Dragger>
			{parsedData.length > 0 && (
				<Table dataSource={tableData} columns={columns} />
			)}
			<Button className="name-sheet-btn" type="primary" onClick={handleSubmit}>
				Upload
			</Button>
		</div>
	);
};

export default BulkUpload;
