import React, { useEffect, useState } from "react";
import HeaderComponent from "../../Header/Header";
import { Button, Form, Input, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { PlusCircleFilled, LeftCircleOutlined } from "@ant-design/icons";

const DepDes = () => {
	const navigate = useNavigate();
	const { apiurl, token } = useAuth();
	const [existingDepts, setExistingDepts] = useState([]);
	const [existingDesigs, setExistingDesigs] = useState([]);

	const [isModalVisible2, setIsModalVisible2] = useState(false);
	const [isModalVisible3, setIsModalVisible3] = useState(false);
	const [loading, setLoading] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [designations, setDesignations] = useState([]);
	const [departmentInput, setDepartmentInput] = useState("");
	const [designationInput, setDesignationInput] = useState("");

	useEffect(() => {
		fetchDepartments();
		fetchDesignations();
	}, []);
	const fetchDepartments = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdepartments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setExistingDepts(data.data);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const fetchDesignations = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdesignations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setExistingDesigs(data.data);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const handleDepartment = (e) => {
		e.preventDefault();

		if (departmentInput.trim() !== "") {
			const isDuplicate = existingDepts.some(
				(dept) => dept.name.toUpperCase() === departmentInput.toUpperCase()
			);
			if (isDuplicate) {
				message.error(`Department "${departmentInput}" already exists.`);
				return;
			}
			setDepartments((prevDepartments) => [
				...prevDepartments,
				departmentInput.toUpperCase(),
			]);
			setDepartmentInput("");
		}
	};

	const handleDesignation = (e) => {
		e.preventDefault();
		if (designationInput.trim() !== "") {
			const isDuplicate = existingDesigs.some(
				(desig) => desig.name.toUpperCase() === designationInput.toUpperCase()
			);
			if (isDuplicate) {
				message.error(`Designation "${designationInput}" already exists.`);
				return;
			}
			setDesignations((prevDesignations) => [
				...prevDesignations,
				designationInput.toUpperCase(),
			]);
			setDesignationInput("");
		}
	};

	const handleDepartmentSubmit = async () => {
		setLoading(true);
		if (departments.length === 0) {
			message.error("Please add at least one department.");
			setLoading(false);
			return;
		}

		try {
			const response = await fetch(`${apiurl}/departments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					departments: departments,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.data);
				fetchDepartments();
				setDepartments([]);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
			setIsModalVisible2(false);
		}
	};

	const handleDesignationSubmit = async () => {
		setLoading(true);
		if (designations.length === 0) {
			message.error("Please add at least one designation.");
			setLoading(false);
			return;
		}

		try {
			const response = await fetch(`${apiurl}/designations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					designations: designations,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.data);
				fetchDesignations();
				setDesignations([]);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		} finally {
			setLoading(false);
			setIsModalVisible3(false);
		}
	};
	const handleDeleteDept = (index) => {
		const array = departments.filter((_, i) => i !== index);
		console.log(array);
		setDepartments(array);
	};
	const handleDeleteDesig = (index) => {
		const array = designations.filter((_, i) => i !== index);
		console.log(array);
		setDesignations(array);
	};
	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="departments">
				<h2>
					Departments{" "}
					<Button type="text" onClick={() => setIsModalVisible2(true)}>
						<PlusCircleFilled style={{ fontSize: "20px" }} />
					</Button>
				</h2>
				{existingDepts &&
					existingDepts.map((dept) => (
						<div key={dept.id}>
							{dept.name} {dept.child}{" "}
						</div>
					))}
				<Modal
					destroyOnClose={true}
					title="Add Departments"
					open={isModalVisible2}
					onCancel={() => {
						setIsModalVisible2(false);
					}}
					footer={null}>
					<Form layout="vertical" className="departments-form">
						<Form.Item label="Department Name">
							<Input
								placeholder="Enter department name"
								value={departmentInput}
								onChange={(e) => setDepartmentInput(e.target.value)}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										e.preventDefault();
										handleDepartment(e);
									}
								}}
							/>
							<ol>
								{departments.map((department, index) => (
									<div className="department-listing">
										<li key={index}>{department}</li>
										<Button type="text" onClick={() => handleDeleteDept(index)}>
											❌
										</Button>
									</div>
								))}
							</ol>
						</Form.Item>
						<Button
							className="sub-btn"
							type="primary"
							onClick={handleDepartmentSubmit}>
							Add Department
						</Button>
					</Form>
				</Modal>
			</div>
			<div className="departments">
				<h2>
					Designations{" "}
					<Button type="text" onClick={() => setIsModalVisible3(true)}>
						<PlusCircleFilled style={{ fontSize: "20px" }} />
					</Button>
				</h2>
				{existingDesigs &&
					existingDesigs.map((desig) => (
						<div key={desig.id}>
							{desig.name} {desig.child}{" "}
						</div>
					))}
				<Modal
					destroyOnClose={true}
					title="Add Designations"
					open={isModalVisible3}
					onCancel={() => {
						setIsModalVisible3(false);
					}}
					footer={null}>
					<Form layout="vertical" className="departments-form">
						<Form.Item label="Designations">
							<Input
								placeholder="Enter designation name"
								value={designationInput}
								onChange={(e) => setDesignationInput(e.target.value)}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										e.preventDefault();
										handleDesignation(e);
									}
								}}
							/>
							<ol>
								{designations.map((designation, index) => (
									<div className="department-listing">
										<li key={index}>{designation}</li>
										<Button
											type="text"
											onClick={() => handleDeleteDesig(index)}>
											❌
										</Button>
									</div>
								))}
							</ol>
						</Form.Item>
						<Button
							type="primary"
							className="sub-btn"
							onClick={handleDesignationSubmit}>
							Add Designations
						</Button>
					</Form>
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default DepDes;
