import React, { useState, useEffect } from "react";
import { Button, Result } from "antd";
import "./Style.css";
import { useNavigate } from "react-router-dom";
import animationData from "./../../Images/underconst.json";
import Lottie from "react-lottie";
import vid from "./../../Images/landingpage.mp4";

const HomePage = () => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: animationData,
	};


	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 8000);

		return () => clearTimeout(timer);
	}, []);

	return (
		<div className="homepage-container">
			{isLoading ? (
				<video
					autoPlay
					loop
					muted
					className="loading-video">
					<source src={vid} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			) : (
				<Result
					icon={
						<Lottie
							isClickToPauseDisabled={true}
							className="under-const-image"
							options={defaultOptions}
						/>
					}
					title="Landing Page Development in Progress: Please Stand By!"
					subTitle="You can Access the Application by Clicking Here"
					extra={
						<Button
							type="primary"
							size="large"
							onClick={() => navigate("/login")}>
							Login To Application
						</Button>
					}
				/>
			)}
		</div>
	);
};

export default HomePage;
