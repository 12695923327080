import React, { useEffect, useState } from "react";
import {
	Table,
	Calendar,
	Input,
	Button,
	Progress,
	message,
	Form,
	Modal,
	Skeleton,
	Card,
	Statistic,
} from "antd";
import { SearchOutlined, ArrowUpOutlined } from "@ant-design/icons";
import { CalendarOutlined, ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import "./Attendance.css";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";
import Punch from "./Punch";
import Break from "./Break";
import HolidayMonths from "./HolidayMonths";
import { Link } from "react-router-dom";
import FlipClock from "../Clock/Clock";
import Clock from "../Clock/Clock1";
import Cmetrics from "./Cmetrics";
import AttendanceSummary from "./AttendanceSummary";

const Attendance = () => {
	const [monthsData, setMonthsData] = useState({});
	const [attendanceData, setAttendanceData] = useState([]);
	const [holidayData, setHolidayData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [breaksData, setBreaksData] = useState(null);
	const [modalvisible, setIsmodalvisible] = useState(false);
	const [visible, setVisible] = useState(false);
	const [selectedDateInfo, setSelectedDateInfo] = useState(null);

	const handleCancel = () => {
		setVisible(false);
		setSelectedDateInfo({ date: null, type: null });
	};
	const { token, apiurl } = useAuth();

	const currentMonth = moment().month() + 1;

	const atttype = sessionStorage.getItem("selectedAttType");

	useEffect(() => {
		fetchMyAttendance();
		fetchmonthsdata();
	}, []);

	const fetchmonthsdata = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/attendanceinfo/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setMonthsData(data.data);
				setLoading(false);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const fetchMyAttendance = async () => {
		try {
			const response = await fetch(`${apiurl}/userattendance/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Attendance Data");
			}
			const data = await response.json();
			setAttendanceData(data.data);
		} catch (error) {
			message.error("Error fetching Attendance Data");
		}
	};

	const fetchBreaks = async (id) => {
		setIsmodalvisible(true);
		try {
			const response = await fetch(`${apiurl}/fetchdaybreaks/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id: id }),
			});

			if (!response.ok) {
				throw new Error("Failed to fetch Attendance Data");
			}

			const data = await response.json();
			setBreaksData(data.data);
		} catch (error) {
			message.error("Error fetching Attendance Data");
		}
	};

	const formatTime = (timeStr) => {
		if (!timeStr) {
			return "";
		}
		const [hours, minutes] = timeStr.split(":").map(Number);
		if (hours === 0) {
			return `${minutes}mins`;
		} else {
			return `${hours}hr ${minutes}mins`;
		}
	};

	const attendanceColumns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (text, record) => (
				<Button type="text" onClick={() => fetchBreaks(record.id)}>
					{new Date(text).toLocaleDateString()}
				</Button>
			),
		},
		{
			title: "Time In",
			dataIndex: "time_in",
			key: "time_in",
			render: (text) => (text ? <p>{text}</p> : <p>-</p>),
		},
		{
			title: "Time Out",
			dataIndex: "time_out",
			key: "time_out",
			render: (text) => (text ? <p>{text}</p> : <p>-</p>),
		},
		{
			title: "Net Break Time",
			dataIndex: "net_break",
			key: "net_break",
			render: (text) => (text ? <p>{formatTime(text)}</p> : <p>-</p>),
		},
		{
			title: "Net Reported Work Time",
			dataIndex: "net_time_in",
			key: "net_time_in",
			render: (text) => (text ? <p>{formatTime(text)}</p> : <p>-</p>),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => {
				if (text === "holiday") {
					return <span style={{ color: "#FF4500" }}>Holiday</span>;
				}
				if (text === "leave") {
					return <span style={{ color: "#FF6347" }}>Leave</span>;
				}
				if (text === "absent") {
					return <span style={{ color: "#DC143C" }}>Absent</span>;
				}
				if (text === "latelogin") {
					return <span style={{ color: "#FFA500" }}>Late Login</span>;
				}
				if (text === "present") {
					return <span style={{ color: "#32CD32" }}>Present</span>;
				}
				return text;
			},
		},
	];

	const rowClassName = (record) => {
		return record.logged_in ? "" : "row-not-logged-in";
	};

	const handleClick = (monthData) => {
		console.log(monthData);
		setSelectedDateInfo(monthData);
		setVisible(true);
	};

	const holidayListContainer = document.querySelector(".list");

	if (holidayListContainer) {
		holidayListContainer.innerHTML = "";
		const filteredHolidays = holidayData.filter((holiday) => {
			const holidayDate = new Date(holiday.date);
			return holidayDate.getMonth() === currentMonth - 1;
		});

		filteredHolidays.forEach((holiday) => {
			const holidayItem = document.createElement("div");
			holidayItem.classList.add("holiday-item");
			const holidayDate = document.createElement("span");
			const options = { day: "numeric" };
			const formattedDate = new Date(holiday.date).toLocaleDateString(
				"en-US",
				options
			);
			holidayDate.textContent = formattedDate;
			holidayDate.classList.add("holiday-date");
			holidayItem.appendChild(holidayDate);
			holidayListContainer.appendChild(holidayItem);
			const holidayName = document.createElement("span");
			holidayName.textContent = holiday.name;
			holidayName.classList.add("holiday-name");
			holidayItem.appendChild(holidayName);
		});
	} else {
		console.error("Element with class 'list' not found.");
	}

	const columns = [
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
		},
		{
			title: "Break In",
			dataIndex: "time_in",
			key: "time_in",
		},
		{
			title: "Break Out",
			dataIndex: "time_out",
			key: "time_out",
		},
		{
			title: "Duration",
			dataIndex: "net_time_in",
			key: "net_time_in",
			render: (text) => formatTime(text),
		},
	];

	const renderMonthData = (monthData, monthClass) => (
		<div className={monthClass}>
			<div className="header m-1 df">
				<div className="month">
					{monthData.name}
					<span className="year">{monthData.year}</span>
				</div>
				<div className="dot bg"></div>
			</div>
			<div className="status working-status">
				<div className="stat">Working</div>
				{monthData.wor ? (
					<div className="days">{monthData.wor} days</div>
				) : (
					<div className="days">No Data</div>
				)}
			</div>

			<Progress
				strokeColor="lightgreen"
				trailColor="#D9D9D9"
				percent={(monthData.pres / monthData.wor) * 100}
				showInfo={false}
			/>

			<div className="status attendance-status">
				<div className="present">
					<div className="stat">P</div>
					{monthData.pres ? (
						<span className="days">{monthData.pres}</span>
					) : (
						<span className="days">-</span>
					)}
				</div>
				<div className="late">
					<div className="stat">LL</div>
					{monthData.late ? (
						<span className="days">{monthData.late}</span>
					) : (
						<span className="days">-</span>
					)}
				</div>
				<div className="absent" onClick={() => handleClick(monthData)}>
					<div className="stat">L/A</div>
					{monthData.abse ? (
						<span className="days">{monthData.abse}</span>
					) : (
						<span className="days">-</span>
					)}
				</div>
			</div>
		</div>
	);

	const abscolumns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
		},
	];

	return (
		<Main>
			<div className="attendance-main">
				<div className="top-sec">
					<div className="main-item">
						{atttype && atttype === "punch" && <Punch />}
					</div>
					<div className="main-item">
						<FlipClock />
						{atttype && atttype === "punch" && <Cmetrics />}
					</div>
				</div>
				<div className="bottom-sec">
					<div className="cont-1">
						<div className="attendance-months">
							{/* <Skeleton loading={loading}>
								{monthsData && (
									<>
										{monthsData.current &&
											renderMonthData(monthsData.current, "currentMonth")}
										{monthsData.previous &&
											renderMonthData(monthsData.previous, "previousMonth m-1")}
										{monthsData.prev_previous &&
											renderMonthData(
												monthsData.prev_previous,
												"beforepreviousMonth"
											)}
									</>
								)}
								<Modal open={visible} onCancel={handleCancel} footer={null}>
									{selectedDateInfo && selectedDateInfo.absences && (
										<Table
											columns={abscolumns}
											dataSource={selectedDateInfo.absences}
											rowKey={(record, index) => index}
											pagination={false}
										/>
									)}
								</Modal>
							</Skeleton> */}
							<AttendanceSummary />
						</div>
						<div className="month-overview">
							<HolidayMonths />
						</div>
					</div>
				</div>
			</div>
			<div className="attendanceData-main">
				<div className="sec-heading">Past 7 Days Insights</div>
				<div className="attendanceData">
					{attendanceData &&
						attendanceData.map((item, index) => (
							<div key={index} className="attendanceItem">
								<div className="attendanceHeader">
									<div className="attendanceDate">
										<CalendarOutlined /> {item.date}
									</div>
									<div
										className={`attendanceStatus ${item.status
											.toLowerCase()
											.replace(/\s/g, "-")}`}>
										{item.status === "latelogin" ? "Late Login" : item.status}
									</div>
								</div>
								<div className="attendanceDetails">
									<div className="attendanceCheckIn">
										<div className="label">
											<ClockCircleOutlined /> Check In Time
										</div>
										<div className="value">{item.time_in}</div>
									</div>
									<div className="attendanceCheckOut">
										<div className="label">
											<ClockCircleOutlined /> Check Out Time
										</div>
										<div className="value">{item.time_out}</div>
									</div>
								</div>
							</div>
						))}
					<div className="attendanceItem view-all">
						<Link to="/attendance/records">View More</Link>
					</div>
				</div>
			</div>
			<Modal
				title="Break"
				open={modalvisible}
				onCancel={() => setIsmodalvisible(false)}
				footer={""}>
				{breaksData ? (
					<Table
						dataSource={breaksData}
						columns={columns}
						rowKey="id"
						pagination={false}
					/>
				) : (
					<p>Loading...</p>
				)}
			</Modal>
		</Main>
	);
};

export default Attendance;
