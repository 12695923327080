import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Input, Button, Card, message, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { useParams } from "react-router-dom";
import './Employee.css';

const { Option } = Select;

const BasicDetailsModal = () => {
	const bloodGroups = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [editMode, setEditMode] = useState(false);
	const { token, apiurl } = useAuth();
	const id2=useParams();
	

	useEffect(() => {
		fetchUserDetails();
	}, []);


	const renderValue = (value) => (value ? value : "-----");
	const fetchUserDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/getbasicdetails/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify(id2)
			});
			if (!response.ok) {
				throw new Error("Failed to fetch user details");
			}
			const data = await response.json();
			setInitialValues(data.data);
		} catch (error) {
			message.error("Error fetching user details");
		}
	};

	

	


	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="User Details"
				>
				<div className="group">
					<div className="detail">
						<span>First Name:</span> {initialValues.firstName}
					</div>
					<div className="detail">
						<span>Middle Name:</span> {renderValue(initialValues.middleName)}
					</div>
					<div className="detail">
						<span>Last Name:</span> {renderValue(initialValues.lastName)}
					</div>
				</div>
				<hr></hr>
				<div className="detail">
					<span>Communication Address: </span>
					{renderValue(initialValues.communicationAddress)}
				</div>
				<div className="detail">
					<span>Permanent Address:</span>{" "}
					{renderValue(initialValues.permanentAddress)}
				</div>
				<hr></hr>
				<div className="group">
					<div className="detail">
						<span>Aadhar Number:</span>{" "}
						{renderValue(initialValues.aadharNumber)}
					</div>
					<div className="detail">
						<span>PAN Card Number:</span>{" "}
						{renderValue(initialValues.panCardNumber)}
					</div>
				</div>
				<hr></hr>
				<div className="group">
					<div className="detail">
						<span>Blood Group:</span> {renderValue(initialValues.bloodGroup)}
					</div>
					<div className="detail">
						<span>Health Insurance Number: </span>
						{renderValue(initialValues.healthInsuranceNumber)}
					</div>
				</div>
				<hr></hr>
				<div className="group">
					<div className="detail">
						<span>PF Account Number:</span>{" "}
						{renderValue(initialValues.pfAccountNumber)}
					</div>
					<div className="detail">
						<span>ESI Account Number:</span>{" "}
						{renderValue(initialValues.esiAccountNumber)}
					</div>
					<div className="detail">
						<span>Gratuity Number:</span>{" "}
						{renderValue(initialValues.gratuityNumber)}
					</div>
				</div>
				<hr></hr>

				<div className="detail">
					<span>Health Issues:</span> {renderValue(initialValues.healthIssues)}
				</div>
			</Card>
			
		</div>
	);
};

export default BasicDetailsModal;
