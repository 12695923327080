import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, Input, message, Modal, Table } from "antd";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Main from "../Homepages/Layouts/Main";

const AttendanceRecords = () => {
	const { apiurl, token } = useAuth();
	const [attendanceRecords, setAttendanceRecords] = useState([]);
	const [loading, setLoading] = useState(false);
	const [fromDate, setFromDate] = useState("");
	const [toDate, setToDate] = useState("");
	const [error, setError] = useState(null);
	const [breaksData, setBreaksData] = useState(null);
	const [selectedYear, setSelectedYear] = useState("");
	const [selectedMonth, setSelectedMonth] = useState("");
	const [modalvisible, setIsmodalvisible] = useState(false);

	const fetchAttendanceRecords = async () => {
		if (!fromDate || !toDate) {
			setError("Please provide both from_date and to_date");
			return;
		}

		try {
			setLoading(true);
			setError(null);
			const response = await fetch(
				`${apiurl}/attendancerecords?from_date=${fromDate}&to_date=${toDate}`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
				}
			);
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			setAttendanceRecords(data.data);
		} catch (error) {
			setError("Failed to fetch attendance records");
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchAttendanceRecords();
	}, []);

	const generateDateFilters = (data) => {
		const uniqueYears = [
			...new Set(data.map((item) => moment(item.date).format("YYYY"))),
		];
		const uniqueMonths = [
			...new Set(data.map((item) => moment(item.date).format("MM"))),
		];

		const yearFilters = uniqueYears.map((year) => ({
			text: year.toString(),
			value: year.toString(),
		}));

		const monthFilters = uniqueMonths.map((month) => ({
			text: moment.months()[parseInt(month) - 1],
			value: month.toString(),
		}));

		return [{ text: "All", value: "" }, ...yearFilters, ...monthFilters];
	};

	const fetchBreaks = async (id) => {
		setIsmodalvisible(true);
		try {
			const response = await fetch(`${apiurl}/fetchdaybreaks/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ id: id }),
			});

			if (!response.ok) {
				throw new Error("Failed to fetch Attendance Data");
			}

			const data = await response.json();
			setBreaksData(data.data);
		} catch (error) {
			message.error("Error fetching Attendance Data");
		}
	};

	const formatTime = (timeStr) => {
		if (!timeStr) {
			return "";
		}
		const [hours, minutes] = timeStr.split(":").map(Number);
		if (hours === 0) {
			return `${minutes}mins`;
		} else {
			return `${hours}hr ${minutes}mins`;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		fetchAttendanceRecords();
	};

	const rowClassName = (record) => {
		return record.logged_in ? "" : "row-not-logged-in";
	};

	const attendanceColumns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
			render: (text, record) => (
				<Button type="text" onClick={() => fetchBreaks(record.id)}>
					{new Date(text).toLocaleDateString()}
				</Button>
			),
			filters: generateDateFilters(attendanceRecords),
			filteredValue: [selectedYear, selectedMonth],
			onFilter: (value, record) => {
				const date = moment(record.date);
				const yearMatch =
					selectedYear === "" || date.format("YYYY") === selectedYear;
				const monthMatch =
					selectedMonth === "" || date.format("MM") === selectedMonth;
				return yearMatch && monthMatch;
			},
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<Input
						placeholder="Year"
						value={selectedYear}
						onChange={(e) => setSelectedYear(e.target.value)}
						style={{ marginBottom: 8, display: "block" }}
					/>
					<Input
						placeholder="Month"
						value={selectedMonth}
						onChange={(e) => setSelectedMonth(e.target.value)}
						style={{ marginBottom: 8, display: "block" }}
					/>
					<Button
						type="primary"
						onClick={() => {
							confirm();
						}}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90, marginRight: 8 }}>
						Search
					</Button>
					<Button
						onClick={() => {
							clearFilters();
						}}
						size="small"
						style={{ width: 90 }}>
						Reset
					</Button>
				</div>
			),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
			onFilterDropdownVisibleChange: (visible) => {
				if (visible) {
					setTimeout(() => {
						const inputs = document.getElementsByClassName("ant-input");
						if (inputs.length >= 2) {
							inputs[0].focus();
						}
					}, 0);
				}
			},
		},
		{
			title: "Time In",
			dataIndex: "time_in",
			key: "time_in",
			render: (text) => (text ? <p>{text}</p> : <p>-</p>),
		},
		{
			title: "Time Out",
			dataIndex: "time_out",
			key: "time_out",
			render: (text) => (text ? <p>{text}</p> : <p>-</p>),
		},
		{
			title: "Net Break Time",
			dataIndex: "net_break",
			key: "net_break",
			render: (text) => (text ? <p>{formatTime(text)}</p> : <p>-</p>),
		},
		{
			title: "Net Reported Work Time",
			dataIndex: "net_time_in",
			key: "net_time_in",
			render: (text) => (text ? <p>{formatTime(text)}</p> : <p>-</p>),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => {
				if (text === "holiday") {
					return <span style={{ color: "#FF4500" }}>Holiday</span>;
				}
				if (text === "leave") {
					return <span style={{ color: "#FF6347" }}>Leave</span>;
				}
				if (text === "absent") {
					return <span style={{ color: "#DC143C" }}>Absent</span>;
				}
				if (text === "latelogin") {
					return <span style={{ color: "#FFA500" }}>Late Login</span>;
				}
				if (text === "present") {
					return <span style={{ color: "#32CD32" }}>Present</span>;
				}
				return text;
			},
		},
	];

	const columns = [
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
		},
		{
			title: "Break In",
			dataIndex: "time_in",
			key: "time_in",
		},
		{
			title: "Break Out",
			dataIndex: "time_out",
			key: "time_out",
		},
		{
			title: "Duration",
			dataIndex: "net_time_in",
			key: "net_time_in",
			render: (text) => formatTime(text),
		},
	];

	const downloadCSV = () => {
		const csvData = [
			[
				"Date",
				"Time In",
				"Time Out",
				"Net Break Time",
				"Net Reported Work Time",
				"Status",
			],
			...attendanceRecords.map((record) => [
				record.date,
				record.time_in || "-",
				record.time_out || "-",
				record.net_break ? formatTime(record.net_break) : "-",
				record.net_time_in ? formatTime(record.net_time_in) : "-",
				record.status,
			]),
		];

		const csvContent = csvData.map((e) => e.join(",")).join("\n");
		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");

		if (link.download !== undefined) {
			const url = URL.createObjectURL(blob);
			link.setAttribute("href", url);
			link.setAttribute("download", "attendance_records.csv");
			link.style.visibility = "hidden";
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return (
		<Main>
			<div className="attendance-container">
				<h1>Fetch Attendance Records</h1>
				<form onSubmit={handleSubmit}>
					<div>
						<label>From Date:</label>
						<input
							type="date"
							value={fromDate}
							onChange={(e) => setFromDate(e.target.value)}
						/>
					</div>
					<div>
						<label>To Date:</label>
						<input
							type="date"
							value={toDate}
							onChange={(e) => setToDate(e.target.value)}
						/>
					</div>
					<button type="submit">Fetch Records</button>
				</form>
				{error && <p style={{ color: "red" }}>{error}</p>}
				{attendanceRecords.length > 0 && (
					<>
						<Button className="csv-download-button" onClick={downloadCSV}>
							Download as CSV
						</Button>
						<div className="attendanceData">
							<Table
								dataSource={attendanceRecords}
								rowClassName={rowClassName}
								columns={attendanceColumns}
							/>
						</div>
					</>
				)}
				<Modal
					title="Break"
					open={modalvisible}
					onCancel={() => setIsmodalvisible(false)}
					footer={""}>
					{breaksData ? (
						<Table
							dataSource={breaksData}
							columns={columns}
							rowKey="id"
							pagination={false}
						/>
					) : (
						<p>Loading...</p>
					)}
				</Modal>
			</div>
		</Main>
	);
};

export default AttendanceRecords;
