import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Table, message, Select, Card } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";

const { Option } = Select;

const Relationships = () => {
	const types = ["FATHER", "MOTHER", "BROTHER", "SISTER", "SPOUSE", "GUARDIAN"];
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const { token, apiurl } = useAuth();

	useEffect(() => {
		fetchRelationships();
	}, []);

	const fetchRelationships = async () => {
		try {
			const response = await fetch(`${apiurl}/relations/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch relationships");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching relationships");
		}
	};

	const handleEdit = (item) => {
		setSelectedItem(item);
		setEditMode(true);
		form.setFieldsValue(item);
	};

	const handleCancelEdit = () => {
		setEditMode(false);
		setSelectedItem(null);
		form.resetFields();
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/relations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details created successfully");
				setCreateMode(false);
				fetchRelationships();
				form.resetFields();
			} else {
				message.error("Failed to create details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error creating details");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		values = { ...values, id: selectedItem.id };
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/relations/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				setEditMode(false);
				setSelectedItem(null);
				fetchRelationships();
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	const columns = [
		{
			title: "Relation",
			dataIndex: "relationType",
			key: "relationType",
		},
		{
			title: "Name",
			dataIndex: "relationName",
			key: "relationName",
		},

		{
			title: "Contact",
			dataIndex: "relationContact",
			key: "relationContact",
		},
		{
			title: "Age",
			dataIndex: "relationAge",
			key: "relationAge",
		},
		{
			title: "Aadhaar",
			dataIndex: "relationAadhar",
			key: "relationAadhar",
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Button onClick={() => handleEdit(record)}>Edit</Button>
			),
		},
	];

	const renderValue = (value) => (value ? value : "-----");

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Relationship Details"
				extra={
					<Button
						icon={<PlusOutlined />}
						onClick={() => setCreateMode(true)}
						disabled={editMode}
					/>
				}>
				<Table
					className="relation-table"
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20 }}
				/>
			</Card>
			<Modal
				open={editMode}
				title="Edit Details"
				onCancel={handleCancelEdit}
				footer={[
					<Button key="cancel" onClick={handleCancelEdit}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					destroyOnClose
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={selectedItem}>
					<Form.Item name="relationType" label="Relation Type">
						<Select>
							{types.map((type) => (
								<Option key={type} value={type}>
									{type}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="relationName" label="Name">
						<Input />
					</Form.Item>
					<Form.Item name="relationAge" label="Age">
						<Input />
					</Form.Item>
					<Form.Item name="relationContact" label="Contact Number">
						<Input type="number" />
					</Form.Item>
					<Form.Item name="relationAadhar" label="Aadhar">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={createMode}
				title="Add New Relationship"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					initialValues={false}
					onFinish={handleCreate}>
					<Form.Item name="relationType" label="Relation Type">
						<Select>
							{types.map((type) => (
								<Option key={type} value={type}>
									{type}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item name="relationName" label="Name">
						<Input />
					</Form.Item>
					<Form.Item name="relationAge" label="Age">
						<Input />
					</Form.Item>
					<Form.Item name="relationContact" label="Contact Number">
						<Input />
					</Form.Item>
					<Form.Item name="relationAadhar" label="Aadhar">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default Relationships;
