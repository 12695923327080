import React, { useEffect, useState } from "react";
import {
	Button,
	Popconfirm,
	Tabs,
	message,
	Select,
	Table,
	Modal,
	Input,
} from "antd";
import { useAuth } from "../utils/useAuth";
import "./Approvals.css";
import Main from "../Homepages/Layouts/Main";

const { TabPane } = Tabs;
const { Option } = Select;

const Approvals = () => {
	const [reviewRoles, setReviewRoles] = useState([]);
	const [reviewlatelogin, setReviewLateLogin] = useState([]);
	const [lateloginRequests, setLateLoginRequests] = useState([]);
	const [reviewcompoff, setReviewCompOff] = useState([]);
	const [compoffRequests, setCompOffRequests] = useState([]);
	const [requestRoles, setRequestRoles] = useState([]);
	const [approvals, setApprovals] = useState([]);
	const [myapprovals, setMyApprovals] = useState([]);
	const [leaveData, setLeaveData] = useState([]);
	const [myLeaveData, setMyLeaveData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [reviewFilter, setReviewFilter] = useState("inbox");
	const [approvalFilter, setApprovalFilter] = useState("inbox");
	const [lateLoginFilter, setLateLoginFilter] = useState("inbox");
	const [leaveFilter, setLeaveFilter] = useState("inbox");
	const [LateFilter, setLateFilter] = useState("inbox");
	const [CompOffFilter, setCompOffFilter] = useState("inbox");
	const { apiurl, token } = useAuth();
	const [reason, setReason] = useState("");
	const [reasonModalVisible, setReasonModalVisible] = useState(false);
	const [currentLeaveId, setCurrentLeaveId] = useState(null);
	const [cancelReasonModal, setCancelReasonModal] = useState(false);

	useEffect(() => {
		fetchData();
		fetchReviewRoles();
		fetchApprovals();
		fetchLateRequests();
		fetchCompOffRequests();
	}, []);

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiurl}/leaveapprovals/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setLeaveData(data.data);
			setMyLeaveData(data.my_data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};
	const fetchCompOffRequests = async () => {
		try {
			const response = await fetch(`${apiurl}/displaycompoffrequests/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			setCompOffRequests(data.data);
			setReviewCompOff(data.my_data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const fetchReviewRoles = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchreviewroles/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setReviewRoles(data.my_req);
				setRequestRoles(data.req);
			} else {
				message.error("Unable to find roles under review. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};
	const fetchLateRequests = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchlaterequests/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setReviewLateLogin(data.my_req);
				setLateLoginRequests(data.req);
			} else {
				message.error(
					"Unable to find requests under review. Please try again."
				);
			}
		} catch (error) {
			message.error(error);
		}
	};

	const fetchApprovals = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchapprovals/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setApprovals(data.awaiting);
				setMyApprovals(data.my);
			} else {
				message.error("Unable to find approvals. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const updateStatus = async (id, status) => {
		try {
			const response = await fetch(`${apiurl}/updaterole/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: id,
					status: status,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchReviewRoles();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error);
		}
	};

	const updateOrgStatus = async (id, status) => {
		try {
			const response = await fetch(`${apiurl}/updateapproval/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: id,
					status: status,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchApprovals();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error);
		}
	};

	const updateLeaveStatus = async (id, status, reason = null) => {
		try {
			const response = await fetch(`${apiurl}/leaves/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					leave_id: id,
					status: status,
					childid: sessionStorage.getItem("selectedChildId"),
					reason: reason,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchData();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error.toString());
		}
	};

	const updateLateLoginStatus = async (id, status) => {
		try {
			const response = await fetch(`${apiurl}/loginrequest/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: id,
					status: status,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchLateRequests();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error);
		}
	};
	const updateCompOffStatus = async (id, status) => {
		try {
			const response = await fetch(`${apiurl}/comprequest/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: id,
					managerstatus: status,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchCompOffRequests();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error);
		}
	};

	const renderReviewRolesColumns = [
		{ title: "Timestamp", dataIndex: "date", key: "date" },
		{
			title: reviewFilter === "inbox" ? "Sender" : "Receiver",
			dataIndex: "senderReceiver",
			key: "senderReceiver",
			render: (_, record) => (
				<>{reviewFilter === "inbox" ? record.sender : record.receiver}</>
			),
		},
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{ title: "Role", dataIndex: "role", key: "role" },
		{ title: "User", dataIndex: "user", key: "user" },
		...(reviewFilter === "inbox"
			? [
					{
						title: "Status",
						dataIndex: "status",
						key: "status",
					},
					{
						title: "Action",
						dataIndex: "action",
						key: "action",
						render: (_, record) => (
							<>
								{record.status === "underreview" && (
									<>
										<Popconfirm
											title="Confirm"
											onConfirm={() => updateStatus(record.id, "approved")}
											okText="Approve">
											<Button>Approve</Button>
										</Popconfirm>
										<Popconfirm
											title="Confirm"
											onConfirm={() => updateStatus(record.id, "rejected")}
											okText="Reject">
											<Button>Reject</Button>
										</Popconfirm>
									</>
								)}
							</>
						),
					},
			  ]
			: []),
	];

	const handleReject = (id) => {
		setCurrentLeaveId(id);
		setReasonModalVisible(true);
	};

	const handleReasonSubmit = async () => {
		if (reason.trim() === "") {
			message.error("Please provide a reason for rejection.");
			return;
		}
		await updateLeaveStatus(currentLeaveId, "rejected", reason);
		setReasonModalVisible(false);
		setReason("");
	};

	const renderMyApprovalsColumns = [
		{ title: "Timestamp", dataIndex: "date", key: "date" },
		{
			title: approvalFilter === "inbox" ? "Sender" : "Receiver",
			dataIndex: "senderReceiver",
			key: "senderReceiver",
			render: (_, record) => (
				<>{approvalFilter === "inbox" ? record.sender : record.receiver}</>
			),
		},
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{ title: "Roles", dataIndex: "roles", key: "roles" },
		{ title: "Departments", dataIndex: "departments", key: "departments" },
		{ title: "Designations", dataIndex: "designations", key: "designations" },
		{ title: "User", dataIndex: "user", key: "user" },
		{ title: "Status", dataIndex: "status", key: "status" },
		...(approvalFilter === "inbox"
			? [
					{
						title: "Action",
						dataIndex: "action",
						key: "action",
						render: (_, record) => (
							<>
								{record.status === "underreview" && (
									<>
										<Popconfirm
											title="Confirm"
											onConfirm={() => updateOrgStatus(record.id, "approved")}
											okText="Approve">
											<Button>Approve</Button>
										</Popconfirm>
										<Popconfirm
											title="Confirm"
											onConfirm={() => updateOrgStatus(record.id, "rejected")}
											okText="Reject">
											<Button>Reject</Button>
										</Popconfirm>
									</>
								)}
							</>
						),
					},
			  ]
			: []),
	];

	const handleCancel = (id) => {
		setCurrentLeaveId(id);
		setCancelReasonModal(true);
	};

	const handleCancelReasonSubmit = async () => {
		if (reason.trim() === "") {
			message.error("Please provide a reason for canceling the leave.");
			return;
		}
		await updateLeaveStatus(currentLeaveId, "cancelled", reason);
		setCancelReasonModal(false);
		setReason("");
	};

	const renderLeaveRequestsColumns = [
		{
			title: "Applied At",
			dataIndex: "timeStamp",
			key: "timeStamp",
			render: (_, record) => {
				const date = new Date(record.timeStamp);
				return (
					<>
						{date.toDateString()}
					</>
				);
			},
		},
		{
			title: leaveFilter === "inbox" ? "Sender" : "Approving Person",
			dataIndex: "senderReceiver",
			key: "senderReceiver",
			render: (_, record) => (
				<>
					{leaveFilter === "inbox" ? record.employee : record.approvingPerson}
				</>
			),
		},
		{
			title: "From Date",
			dataIndex: "fromDate",
			key: "fromDate",
			render: (_, record) => {
				const date = new Date(record.fromDate);
				return <>{date.toDateString()}</>;
			},
		},
		{
			title: "To Date",
			dataIndex: "toDate",
			key: "toDate",
			render: (_, record) => {
				const date = new Date(record.toDate);
				return <>{date.toDateString()}</>;
			},
		},
		...(leaveFilter === "inbox"
			? [
					{
						title: "Status",
						dataIndex: "status",
						key: "status",
					},
					{
						title: "Action",
						dataIndex: "action",
						key: "action",
						render: (_, record) => (
							<>
								{record.status === "pending" && (
									<>
										<Popconfirm
											title="Confirm"
											onConfirm={() => updateLeaveStatus(record.id, "approved")}
											okText="Approve">
											<Button>Approve</Button>
										</Popconfirm>
										<Button onClick={() => handleReject(record.id)}>
											Reject
										</Button>
									</>
								)}
								{record.status === "approved" && (
									<>
										<Button onClick={() => handleCancel(record.id)}>
											Cancel
										</Button>
									</>
								)}
							</>
						),
					},
			  ]
			: []),
	];

	const renderLateRequestsColumns = [
		{
			title: LateFilter === "inbox" ? "Sender" : "Receiver",
			dataIndex: "senderReceiver",
			key: "senderReceiver",
			render: (_, record) => (
				<>{LateFilter === "inbox" ? record.employee : record.reported_to}</>
			),
		},
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{ title: "Status", dataIndex: "status", key: "status" },
		{ title: "Employee", dataIndex: "employee", key: "employee" },
		{ title: "Date", dataIndex: "Date", key: "Date" },
		{ title: "Reason", dataIndex: "reason", key: "reason" },

		...(lateLoginFilter === "inbox"
			? [
					{
						title: "Status",
						dataIndex: "status",
						key: "status",
					},
					{
						title: "Action",
						dataIndex: "action",
						key: "action",
						render: (_, record) => (
							<>
								{record.status === "underreview" && (
									<>
										<Popconfirm
											title="Confirm"
											onConfirm={() =>
												updateLateLoginStatus(record.id, "approved")
											}
											okText="Approve">
											<Button>Approve</Button>
										</Popconfirm>
										<Popconfirm
											title="Confirm"
											onConfirm={() =>
												updateLateLoginStatus(record.id, "rejected")
											}
											okText="Reject">
											<Button>Reject</Button>
										</Popconfirm>
									</>
								)}
							</>
						),
					},
			  ]
			: []),
	];

	const renderCompOffRequestsColumns = [
		{
			title: CompOffFilter === "inbox" ? "Sender" : "Receiver",
			dataIndex: "senderReceiver",
			key: "senderReceiver",
			render: (_, record) => (
				<>{CompOffFilter === "inbox" ? record.employee : record.approvedbyrm}</>
			),
		},
		{ title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{
			title: "ManagerStatus",
			dataIndex: "managerstatus",
			key: "managerstatus",
		},
		{ title: "HRStatus", dataIndex: "hrstatus", key: "hrstatus" },
		{ title: "HR", dataIndex: "approvedbyhr", key: "approvedbyhr" },
		{
			title: "Reporting Manager",
			dataIndex: "approvedbyrm",
			key: "approvedbyrm",
		},
		{ title: "Employee", dataIndex: "employee", key: "employee" },
		{ title: "Date", dataIndex: "Date", key: "Date" },
		{ title: "Reason", dataIndex: "reason", key: "reason" },

		...(CompOffFilter === "inbox"
			? [
					{
						title: "ManagerStatus",
						dataIndex: "managerstatus",
						key: "managerstatus",
					},
					{
						title: "Action",
						dataIndex: "action",
						key: "action",
						render: (_, record) => (
							<>
								{record.managerstatus === "underreview" && (
									<>
										<Popconfirm
											title="Confirm"
											onConfirm={() =>
												updateCompOffStatus(record.id, "approved")
											}
											okText="Approve">
											<Button>Approve</Button>
										</Popconfirm>
										<Popconfirm
											title="Confirm"
											onConfirm={() =>
												updateCompOffStatus(record.id, "rejected")
											}
											okText="Reject">
											<Button>Reject</Button>
										</Popconfirm>
									</>
								)}
							</>
						),
					},
			  ]
			: []),
	];

	if (!apiurl) {
		return [];
	}

	const expandedRowRender = (record) => {
		return (
			<div className="leave-expanded-row">
				<div className="items-group">
					<div className="item">
						<div className="item-label">Leave Type:</div>
						<div className="item-value">{record.type}</div>
					</div>
					<div className="item">
						<div className="item-label">Leave Duration:</div>
						<div className="item-value">{record.leavetype}</div>
					</div>
				</div>
				<div className="item">
					<div className="item-label">Reason:</div>
					<pre className="item-value">{record.reason}</pre>
				</div>
				<div className="items-group">
					<div className="item">
						<div className="item-label">Work Delegated:</div>
						<div className="item-value">
							{record.workDelegated ? record.workDelegated : "N/A"}
						</div>
					</div>
					<div className="item">
						<div className="item-label">Approving Person:</div>
						<div className="item-value">{record.approvingPerson}</div>
					</div>
				</div>
				<div className="item">
					<div className="item-label">Comments :</div>
					<pre className="item-value">
						{record.comments ? record.comments : "N/A"}
					</pre>
				</div>
			</div>
		);
	};

	return (
		<Main>
			<div className="approvals-main">
				<div className="section-head">Approvals</div>
				<Tabs centered defaultActiveKey="1">
					<TabPane tab="Organization Structure" key="1">
						<Select
							defaultValue="inbox"
							style={{ width: 120 }}
							onChange={(value) => setApprovalFilter(value)}>
							<Option value="inbox">Inbox</Option>
							<Option value="sent">Sent</Option>
						</Select>
						<Table
							dataSource={approvalFilter === "inbox" ? myapprovals : approvals}
							columns={renderMyApprovalsColumns}
							rowKey="id"
							style={{ overflow: "auto" }}
						/>
					</TabPane>
					<TabPane tab="Roles" key="2">
						<Select
							defaultValue="inbox"
							style={{ width: 120 }}
							onChange={(value) => setReviewFilter(value)}>
							<Option value="inbox">Inbox</Option>
							<Option value="sent">Sent</Option>
						</Select>
						<Table
							dataSource={reviewFilter === "inbox" ? requestRoles : reviewRoles}
							columns={renderReviewRolesColumns}
							rowKey="id"
							style={{ overflow: "auto" }}
						/>
					</TabPane>
					<TabPane tab="Leave Requests" key="3">
						<Select
							defaultValue="inbox"
							style={{ width: 120 }}
							onChange={(value) => setLeaveFilter(value)}>
							<Option value="inbox">Inbox</Option>
							<Option value="sent">Sent</Option>
						</Select>
						<Table
							dataSource={leaveFilter === "inbox" ? leaveData : myLeaveData}
							columns={renderLeaveRequestsColumns}
							rowKey="id"
							expandable={{
								expandedRowRender,
							}}
							style={{ overflow: "auto" }}
						/>
					</TabPane>
					<TabPane tab="LateLogin Requests" key="4">
						<Select
							defaultValue="inbox"
							style={{ width: 120 }}
							onChange={(value) => setLateLoginFilter(value)}>
							<Option value="inbox">Inbox</Option>
							<Option value="sent">Sent</Option>
						</Select>
						<Table
							dataSource={
								lateLoginFilter === "inbox"
									? lateloginRequests
									: reviewlatelogin
							}
							columns={renderLateRequestsColumns}
							rowKey="id"
							style={{ overflow: "auto" }}
						/>
					</TabPane>
					<TabPane tab="CompOff Requests" key="5">
						<Select
							defaultValue="inbox"
							style={{ width: 120 }}
							onChange={(value) => setCompOffFilter(value)}>
							<Option value="inbox">Inbox</Option>
							<Option value="sent">Sent</Option>
						</Select>
						<Table
							dataSource={
								CompOffFilter === "inbox" ? compoffRequests : reviewcompoff
							}
							columns={renderCompOffRequestsColumns}
							rowKey="id"
							style={{ overflow: "auto" }}
						/>
					</TabPane>
				</Tabs>
			</div>
			<Modal
				title="Reason for Rejection"
				open={reasonModalVisible}
				onOk={handleReasonSubmit}
				onCancel={() => setReasonModalVisible(false)}>
				<Input.TextArea
					value={reason}
					onChange={(e) => setReason(e.target.value)}
					rows={4}
					placeholder="Please enter the reason for rejection"
				/>
			</Modal>

			<Modal
				title="Reason for Cancellation"
				open={cancelReasonModal}
				onOk={handleCancelReasonSubmit}
				onCancel={() => setCancelReasonModal(false)}>
				<Input.TextArea
					value={reason}
					onChange={(e) => setReason(e.target.value)}
					rows={4}
					placeholder="Please enter the reason for Cancellation"
				/>
			</Modal>
		</Main>
	);
};

export default Approvals;
