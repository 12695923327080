import React, { useState, useEffect } from "react";
import { Button, message } from "antd";
import { useAuth } from "./utils/useAuth.js";

const IpRestriction = () => {
	const [enabled, setEnabled] = useState(false);
	const { apiurl, token } = useAuth();
	useEffect(() => {
		fetchIp();
	});

	const fetchIp = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchip/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setEnabled(data.enabled);
			}
		} catch {
			console.error("Error removing IP restrictions");
		}
	};

	const handleSubmit = async () => {
		try {
			const response = await fetch(`${apiurl}/removeip/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					iprestriction: !enabled,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.success);
				fetchIp();
			}
		} catch {
			console.error("Error removing IP restrictions");
		}
	};

	return (
		<div className="iprestrict-main">
			<div className="iprestrict-tit">
				IP restriction : {enabled ? "Enabled" : "Disabled"}
			</div>
			<Button onClick={handleSubmit}>{enabled ? "Disable" : "Enable"}</Button>
		</div>
	);
};

export default IpRestriction;
