import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, Dropdown, Modal, Select, Tabs, message } from "antd";
import {
	LeftCircleOutlined
} from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddEmployee from "../Employee/AddEmployee";
import Main from "../Homepages/Super_User/Layouts/Main";
import './ChildAccount.css';
import AddEmpToRole from "../Pages/AddEmpToRole";
import TabPane from "antd/es/tabs/TabPane";


const ChildAccountDetails = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const [childData, setChildData] = useState(null);
	const { apiurl, token } = useAuth();
    const [isModalVisible, setIsModalVisible] = useState(false);
	

	useEffect(() => {
		fetchChildData();
	}, []);

	const fetchChildData = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchchilds/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: id,
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setChildData(data.data);
			} else {
				message.error("Unable to find child please try again");
			}
		} catch (error) {
			message.error(error);
		}
	};
	return (
		<Main>
			<div className="child-details-cont">
				<div className="go-back">
					<Button type="text" onClick={() => navigate(-1)}>
						<LeftCircleOutlined />Go back
					</Button>
				</div>
				<div className="child-details">
					<div className="child-det">
						<div className="section-head">Child Details</div>
						<div className="child-info">
							<div>
								Name: <span>{childData?.name}</span>
							</div>
							<div>
								Reg No: <span>{childData?.regNo}</span>
							</div>

							<div>
								Company Gst Reg No: <span>{childData?.companyGstRegNo}</span>
							</div>
							<div>
								Company Pan No: <span>{childData?.companyPanNo}</span>
							</div>
							<div>
								Company Tan No: <span>{childData?.companyTanNo}</span>
							</div>
						</div>
					</div>
					<div className="con-det">
						<div className="section-head">Contact Person</div>
						<div className="info">
							<div>
								Name: <span>{childData?.contactPerson}</span>
							</div>
							<div>
								Contact No: <span>{childData?.contactNo}</span>
							</div>
							<div>
								Email: <span>{childData?.email}</span>
							</div>
							<div>
								Designation: <span>{childData?.designation}</span>
							</div>
						</div>
					</div>
				</div>

				{childData && (
					<div className="bussiness-owner-det">
						<div className="section-head">Business Owner</div>
						{childData.bussinessOwner ? (
							<div className="info">
								<div>
									Name: <span>{childData?.bussinessOwner?.userName}</span>
								</div>
								<div>
									Contact No:{" "}
									<span>{childData?.bussinessOwner?.phoneNumber}</span>
								</div>
								<div>
									Email: <span>{childData?.bussinessOwner?.email}</span>
								</div>
								<div>
									Designation:{" "}
									<span>{childData?.bussinessOwner?.designation}</span>
								</div>
								<div>
									Department:{" "}
									<span>{childData?.bussinessOwner?.department}</span>
								</div>
								<div>
									Type: <span>{childData?.bussinessOwner?.type}</span>
								</div>
								<div>
									Gender: <span>{childData?.bussinessOwner?.gender}</span>
								</div>
								<div>
									Date Of Birth:{" "}
									<span>{childData?.bussinessOwner?.dateOfBirth}</span>
								</div>
							</div>
						) : (
							<div className="assign-bo">
								No BussinessOwner{" "}
								<button onClick={() => setIsModalVisible(true)}>
									Click to add
								</button>
							</div>
						)}
					</div>
				)}
				<Modal
					title="Add Bussiness Owner"
					open={isModalVisible}
					onCancel={() => {
						fetchChildData();
						setIsModalVisible(false);
					}}
					footer={null}>
					<div className="choice-selection">
						<Tabs defaultActiveKey="1">
							<TabPane tab="Add Existing" key="1">
								<AddEmpToRole
									role="BUSINESS_OWNER"
									child_id={id}></AddEmpToRole>
							</TabPane>
							<TabPane tab="Add New" key="2">
								<AddEmployee role="BUSINESS_OWNER" child_id={id}></AddEmployee>
							</TabPane>
						</Tabs>
					</div>
				</Modal>
			</div>
		</Main>
	);
};

export default ChildAccountDetails;
