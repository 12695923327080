import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
	HomeOutlined,
	FormOutlined,
	DollarOutlined,
	MedicineBoxOutlined,
	AuditOutlined,
	SolutionOutlined,
	UserOutlined,
	LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, Tooltip, message } from "antd";
import { Link } from "react-router-dom";
import './Main.css';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AdminApproval from "../../../AdminApprovals/AdminApproval";

import { Table, Input, Modal } from "antd";
import { useAuth } from "../../../utils/useAuth";
import logo from "./../../../../Images/LOGO-10.png";

const { Header, Footer, Sider } = Layout;
const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
  const [openKeys, setOpenKeys] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/home",
			"/child-details",
			"/approvals",
		];

		const index = menuItems.findIndex((item) => pathname.includes(item));
		return index !== -1 ? [String(index + 1)] : [""];
	};

	const handleMenuItemClick = ({ keyPath }) => {
		const isProfileSubmenu = keyPath && keyPath[1] === "1";
		setOpenKeys(isProfileSubmenu ? openKeys : []);
	};

	const Logout = () => {
    handleLogout();
    message.success("logout successful");
	navigate('/logout');
	};

	return (
		<Layout>
			<Sider
				className="side"
				breakpoint="lg"
				collapsedWidth="0"
				width={"250px"}
				style={{
					height: "calc(100vh - 100px)",
					position: "fixed",
					left: "0",
					top: "100px",
					bottom: 0,
					zIndex: 1,
					background: "#fff",
				}}>
				<Menu
					mode="inline"
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					openKeys={openKeys}
					onClick={handleMenuItemClick}
					className="menu">
					<Menu.Item key="1" icon={<UserOutlined />}>
						<Link to="/home">Home</Link>
					</Menu.Item>
					<Menu.Item key="2" icon={<UserOutlined />}>
						<Link to="/child-details">Childs</Link>
					</Menu.Item>
					<Menu.Item key="3" icon={<UserOutlined />}>
						<Link to="/adminapproval">Approvals</Link>
					</Menu.Item>
					<div className="logoutbtn">
						<Button onClick={() => setIsModalOpen(true)} type="text">
							<LogoutOutlined /> Logout
						</Button>
					</div>
				</Menu>
			</Sider>
			<Layout>
				<Header className="head">
					<div className="header-logo">
						<img alt="logo" src={logo}></img>
					</div>
					<div className="header-buttons">
						<Button onClick={() => setIsModalOpen(true)}>Logout</Button>
					</div>
				</Header>

				<Modal
					open={isModalOpen}
					onOk={() => {
						Logout();
						setIsModalOpen(false);
					}}
					onCancel={() => setIsModalOpen(false)}>
					<h3>Are you sure to logout?</h3>
				</Modal>
				<div className="content">{children}</div>
			</Layout>
		</Layout>
	);
};
export default Main;
