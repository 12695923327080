import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import { Button, Card, message } from "antd";
import { loadScript } from "../utils/LoadScript";
import logo from "./../../Images/LOGO-10-black.png";
import paymentimg from "./../../Images/payment.jpg";

const Payment = () => {
    const [accountDetails,setAccountDetails] = useState(null)
    const {token,apiurl} = useAuth();
	const navigate = useNavigate();
	const [loading,setLoading] = useState(false);

    useEffect(() => {
		fetchAccountDetails();
	}, []);

    const fetchAccountDetails = async () => {
			try {
				const response = await fetch(`${apiurl}/getaccountdetails`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					console.log(data);
					setAccountDetails(data);
				} else {
					message.error("Unable to find account  details");
				}
			} catch (error) {
				message.error(error);
			}
		};

		const sendPaymentId = async (Id) => {
			try {
				const response = await fetch(`${apiurl}/paymentsuccess/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						payment_id: Id,
					}),
				});
				const data = await response.json();
				if (response.ok) {
					message.success(data.message);
					navigate("/home");
					window.location.reload();
				} else {
					message.errror(response.statusText);
				}
			} catch (error) {
				message.error("Error fetching payment amount");
			} finally {
				setLoading(false);
			}
		};


		const handleFreeTrail = async () => {
			try {
				const response = await fetch(`${apiurl}/freetrail/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					
				});
				const data = await response.json();
				if (response.ok) {
					message.success(data.message);
					navigate("/home");
					window.location.reload();
				} else {
					message.error(data.error);
				}
			} catch (error) {
				message.error("Error creating free trail. Please try again.");
			} finally {
				setLoading(false);
			}
		};
		useEffect(() => {
			const initializeRazorpay = async () => {
				try {
					await loadScript("https://checkout.razorpay.com/v1/checkout.js");
				} catch (error) {
					console.error("Error loading Razorpay script:", error);
					message.error("Error loading Razorpay script");
				}
			};

			initializeRazorpay();
		}, []);

		const initiatePayment = async () => {
			try {
				const options = {
					key: "rzp_test_u4AVcofZe0M51I",
					amount: accountDetails.amount/100,
					currency: "INR",
					name: "GA",
					description: "Payment for GA ORG SYNC",
					handler: async function (response) {
						
						await sendPaymentId(response.razorpay_payment_id);
					},
				};
				const rzp = new window.Razorpay(options);
				rzp.open();
			} catch (error) {
				console.error("Error initializing Razorpay:", error);
				message.error("Error initializing Razorpay");
			}
		};
	return (
		<div className="payment-container">
			<div className="right-cont">
				<img alt="logo" src={logo}></img>
				<div className="color-purple"></div>
			</div>
			<div className="left-cont">
				<img className="logo-mb" alt="logo" src={logo}></img>
				<div className="pricing-card">
					<Card
						className="feature-details"
						title={"Feature: " + accountDetails?.features}
						bordered>
						<div className="detail">
							<div>No Of Employees : </div>
							<div>{accountDetails?.noOfEmployees}</div>
						</div>
						<div className="detail">
							<div>No Of Childs :</div> <div>{accountDetails?.noOfChilds}</div>
						</div>
						<div className="detail">
							<div>Duration(in months) : </div>
							<div>{accountDetails?.duration}</div>
						</div>
						<div className="detail col-green text-up">
							<div>
								<strong>Amount :</strong>
							</div>
							<div>
								<strong>₹ {accountDetails?.amount}</strong>
							</div>
						</div>
					</Card>
				</div>
				<div className="proceed-text">
					You should complete payment inorder to access the application
				</div>
				<Button className="payment-btn" onClick={initiatePayment}>
					Click to Pay
				</Button>

				<Button className="payment-btn" onClick={handleFreeTrail}>
					Get Free Trail
				</Button>
			</div>
		</div>
	);

};

export default Payment;
