import React from "react";
import { Calendar, globalizeLocalizer } from "react-big-calendar";
import globalize from "globalize";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = globalizeLocalizer(globalize);

const CalendarComponent = () => {
	const myEventsList = [
		{
			title: "Project Meeting",
			start: new Date("2024-05-21T23:13:37.880Z"),
			end: new Date("2024-05-22T00:13:37.881Z"),
			allDay: false,
			color: "#FF5733", 
		},
		{
			title: "Client Presentation",
			start: new Date("2024-05-20T23:30:00.000Z"),
			end: new Date("2024-05-21T04:30:00.000Z"),
			allDay: false,
			color: "#33FF57", 
		},
		{
			title: "Team Workshop",
			start: new Date("2024-05-21T09:30:00.000Z"),
			end: new Date("2024-05-21T17:30:00.000Z"),
			allDay: false,
			color: "#3357FF", 
		},
		{
			title: "Office Holiday",
			start: new Date("2024-05-20T18:30:00.000Z"),
			end: new Date("2024-05-21T18:30:00.000Z"),
			allDay: true,
			color: "#FF33A1", 
		},
		{
			title: "Company Retreat",
			start: new Date("2024-05-20T18:30:00.000Z"),
			end: new Date("2024-05-22T18:30:00.000Z"),
			allDay: true,
			color: "#A133FF", 
		},
		{
			title: "Development Sprint",
			start: new Date("2024-05-21T04:13:37.882Z"),
			end: new Date("2024-05-24T04:13:37.882Z"),
			allDay: false,
			color: "#33FFA1", 
		},
	];


	return (
		<div>
			<Calendar
				localizer={localizer}
				events={myEventsList}
				startAccessor="start"
				endAccessor="end"
				style={{ height: 600 }}
			/>
		</div>
	);
};

export default CalendarComponent;
