import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Table, message, Select, Card } from "antd";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";
import Dragger from "antd/es/upload/Dragger";

const { Option } = Select;

const EducationDetails = () => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState([]);
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const [file, setFile] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);
	const [id, setId] = useState(null);
	const { token, apiurl } = useAuth();

	useEffect(() => {
		fetchEducationDetails();
	}, []);

	const fetchEducationDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/education/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch EducationDetails");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching EducationDetails");
		}
	};

	const handleEdit = (item) => {
		setSelectedItem(item);
		setEditMode(true);
		form.setFieldsValue(item);
	};

	const handleCancelEdit = () => {
		setEditMode(false);
		setSelectedItem(null);
		form.resetFields();
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/education/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details created successfully");
				setCreateMode(false);
				fetchEducationDetails();
                form.resetFields();
			} else {
				message.error("Failed to create details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error creating details");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
        values = { ...values, id: selectedItem.id };
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/education/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				setEditMode(false);
				setSelectedItem(null);
				fetchEducationDetails();
                
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	const columns = [
		{
			title: "Institution",
			dataIndex: "institution",
			key: "institution",
		},
		{
			title: "Degree",
			dataIndex: "degree",
			key: "degree",
		},
		{
			title: "Field of Study",
			dataIndex: "field_of_study",
			key: "field_of_study",
		},
		{
			title: "Start Date",
			dataIndex: "start_date",
			key: "start_date",
		},
		{
			title: "End Date",
			dataIndex: "end_date",
			key: "end_date",
		},
		{
			title: "Education Proof",
			key: "education_proof",
			render: (text, record) => (
				<span>
					{record.education_proof ? (
						<a
							href={`${apiurl}${record?.education_proof}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					) : (
						<button
							onClick={() => {
								setId(record.id);
								setImageEditMode(true);
							}}>
							Add
						</button>
					)}
				</span>
			),
		},
	];

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};

	const updateImages = async (type) => {
		console.log("entered");
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append("type", type);
			formData.append("image", file);
			formData.append("id", id);

			try {
				const response = await fetch(`${apiurl}/handleimages/`, {
					method: "PUT",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});
				if (response.ok) {
					const data = await response.json();
					message.success(data.message);
					setImageEditMode(false);
					setLoading(false);
					fetchEducationDetails();
				} else {
					const data = await response.json();
					message.error(data.error);
					setLoading(false);
				}
			} catch (error) {
				message.error("Error fetching images");
				setLoading(false);
			}
		} else {
			setLoading(false);
			message.warning("Please select a file to upload.");
		}
	};

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Education Details"
				extra={
					<Button
						icon={<PlusOutlined />}
						onClick={() => setCreateMode(true)}
						disabled={editMode}
					/>
				}>
				<Table
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20, overflow: "auto" }}
				/>
			</Card>
			<Modal
				open={editMode}
				title="Edit Details"
				onCancel={handleCancelEdit}
				footer={[
					<Button key="cancel" onClick={handleCancelEdit}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					destroyOnClose
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={selectedItem}>
					<Form.Item name="institution" label="Institution">
						<Input />
					</Form.Item>
					<Form.Item name="degree" label="Degree">
						<Select>
							<Option value="High School">High School</Option>
							<Option value="Bachelor's">Bachelor's</Option>
							<Option value="Master's">Master's</Option>
							<Option value="Doctorate">Doctorate</Option>
							<Option value="Intermediate">Intermediate></Option>
							<Option value="Other">Other</Option>
						</Select>
					</Form.Item>
					<Form.Item name="field_of_study" label="Field of Study">
						<Input />
					</Form.Item>
					<Form.Item name="start_date" label="Start Date">
						<Input type="date" />
					</Form.Item>
					<Form.Item name="end_date" label="End Date">
						<Input type="date" />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={createMode}
				title="Add New Education"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					initialValues={false}
					onFinish={handleCreate}>
					<Form.Item name="institution" label="Institution">
						<Input />
					</Form.Item>
					<Form.Item name="degree" label="Degree">
						<Select>
							<Option value="High School">High School</Option>
							<Option value="Bachelor's">Bachelor's</Option>
							<Option value="Master's">Master's</Option>
                            <Option value="Intermediate">Intermediate</Option>
							<Option value="Doctorate">Doctorate</Option>
							<Option value="Other">Other</Option>
						</Select>
					</Form.Item>
					<Form.Item name="field_of_study" label="Field of Study">
						<Input />
					</Form.Item>
					<Form.Item name="start_date" label="Start Date">
						<Input type="date" />
					</Form.Item>
					<Form.Item name="end_date" label="End Date">
						<Input type="date" />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={imageEditMode}
				destroyOnClose
				title="Edit Image"
				onCancel={() => {
					setImageEditMode(false);
				}}
				footer={[
					<Button key="cancel" onClick={() => setImageEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => updateImages("education_proof")}>
						Submit
					</Button>,
				]}>
				<Form layout="vertical">
					<Form.Item>
						<Dragger
							className="image-upload"
							accept=".png,.jpg,.jpeg"
							maxCount={1}
							customRequest={customRequest}
							onChange={handleFileChange}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag image to this area to upload
							</p>
							<p className="ant-upload-hint">Upload only image files</p>
						</Dragger>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default EducationDetails;
