import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import { message, Modal, Table, Spin, Skeleton } from "antd";

const HolidayMonths = () => {
	const { token, apiurl } = useAuth();
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
	const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
	const [holidaysData, setHolidaysData] = useState([]);
	const [holidaysModalData, setHolidaysModalData] = useState([]);
	const [modalVisible, setModalVisible] = useState(false);
	const [loading, setLoading] = useState(false); 

	const fetchHolidays = async (month, year) => {
		setLoading(true); 
		try {
			const response = await fetch(`${apiurl}/getholidays/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					month: month,
					year: year,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to fetch Holidays Data");
			}

			const data = await response.json();
			setLoading(false); 
			return data;
		} catch (error) {
			setLoading(false); 
			message.error("Error fetching Holidays Data");
		}
	};

	const getMonthName = (month, year) => {
		const monthNames = [
			"Jan",
			"Feb",
			"Mar",
			"Apr",
			"May",
			"Jun",
			"Jul",
			"Aug",
			"Sep",
			"Oct",
			"Nov",
			"Dec",
		];
		return `${monthNames[(month - 1) % 12]} ${year}`;
	};

	useEffect(() => {
		const fetchData = async () => {
			const currentMonthData = await fetchHolidays(currentMonth, currentYear);
			const nextMonth = (currentMonth + 1) % 12 || 12;
			const nextMonthData = await fetchHolidays(
				nextMonth,
				nextMonth === 12 ? currentYear + 1 : currentYear
			);
			const nextNextMonth = (currentMonth + 2) % 12 || 12;
			const nextNextMonthData = await fetchHolidays(
				nextNextMonth,
				nextNextMonth === 12 ? currentYear + 1 : currentYear
			);
			const nextNextNextMonth = (currentMonth + 3) % 12 || 12;
			const nextNextNextMonthData = await fetchHolidays(
				nextNextNextMonth,
				nextNextNextMonth === 12 ? currentYear + 1 : currentYear
			);

			setHolidaysData([
				currentMonthData,
				nextMonthData,
				nextNextMonthData,
				nextNextNextMonthData,
			]);
		};

		fetchData();
	}, [currentMonth, currentYear]);

	const handleItemClick = async (month) => {
        setModalVisible(true);
		const data = await fetchHolidays(
			month,
			month === 12 && currentMonth === 1 ? currentYear + 1 : currentYear
		);
		setHolidaysModalData(data.holidays);
	};

	const columns = [
		{
			title: "Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
	];

	return (
		<div>
			<div className="holidays">
				<div>
					<div className="sec-head">Upcoming Holidays</div>
					<div className="holidays-main">
						{holidaysData.map((data, index) => (
							<div
								className="item"
								key={index}
								onClick={() => handleItemClick(currentMonth + index)}>
								<div className="title">
									{getMonthName(currentMonth + index, currentYear)}
								</div>
								<div className="value">{data.count} days</div>
							</div>
						))}

						<Modal
							open={modalVisible}
							onCancel={() => setModalVisible(false)}
							footer={null}>
							{loading ? (
								<Spin />
							) : (
								<Table columns={columns} dataSource={holidaysModalData} pagination={false}/>
							)}
						</Modal>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HolidayMonths;
