import React, { useState } from "react";
import { Button, Input, message } from "antd";
import {
	Card,
	Spin,
	Form,
} from "antd";
import image from "../userauth/loginlogo.svg";
import './file.css';
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";

function ForgotPassword() {
	const [form] = Form.useForm();
	
	const { apiurl } = useAuth();
	const [loading, setLoading] = useState(false);



	const handleForgotPassword = async (values) => {
		setLoading(true)
		
		try {
			const response = await fetch(`${apiurl}/forgotpassword/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			});

			const data = await response.json();
			if (response.ok) {
				setLoading(false);
				message.success(data.success);
				form.resetFields();
				
			} else {
				setLoading(false);
				message.success(data.error);
			}
		} catch (error) {
			setLoading(false);
			message.error("An error occurred. Please try again.");
		}
	};

	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<>
					<div className="bgg">
						<Card className="cont bg">
							<Card className="innercard">
								<div className="grid-2">
									<div className="mt-40">
										<img
											src={image}
											alt="logo"
											className="img-fluid img-animated"></img>
									</div>

									<div className="centerdflex">
										<center>
											<h1>Reset Password</h1>
										</center>
										<div className="centerdflex">
											<Form
												form={form}
												className="mt-50"
												layout="vertical"
												labelCol={{
													span: 8,
												}}
												wrapperCol={{
													span: 16,
												}}
												style={{
													maxWidth: 600,
												}}
												initialValues={{
													remember: true,
												}}
												onFinish={handleForgotPassword}
												autoComplete="off">
												<Form.Item
													label="Email ID"
													name="email"
													rules={[
														{
															required: true,
															message: "Please input your username!",
														},
														{
															type:"email",
															message: "Please input your valid Email ID",
														},
													]}>
													<Input className="inp" />
												</Form.Item>
												

												<Form.Item
													wrapperCol={{
														offset: 8,
														span: 16,
													}}>
													<Button
														type="primary"
														htmlType="submit"
														className="lb">
														Submit
													</Button>
												</Form.Item>
											</Form>
										</div>
									</div>
								</div>
							</Card>
						</Card>
					</div>
				</>
			)}
		</>
	);
}

export default ForgotPassword;
