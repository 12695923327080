// NotificationComponent.js
import React from "react";

class NotificationComponent extends React.Component {
	handlePushNotification = () => {
		if (!("Notification" in window)) {
			alert("This browser does not support desktop notification");
			return;
		}

		Notification.requestPermission().then((permission) => {
			if (permission === "granted") {
				const options = {
					body: "Do you want to take action?",
					icon: "http://url-to-an-icon/icon.png",
					actions: [
						{ action: "yes", title: "Yes" },
						{ action: "no", title: "No" },
					],
				};

				navigator.serviceWorker.ready.then((registration) => {
					registration.showNotification("React Web Notification", options);
				});
			}
		});
	};

	render() {
		return (
			<div>
				<button onClick={this.handlePushNotification}>Push Notification</button>
			</div>
		);
	}
}

export default NotificationComponent;
