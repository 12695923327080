import React, { useState, useEffect } from "react";
import { List } from "antd";
import { useAuth } from "../utils/useAuth";

const GreetingsList = () => {
	const [employeeGreetings, setEmployeeGreetings] = useState([]);
	const [childGreetings, setChildGreetings] = useState([]);
  const {apiurl,token} = useAuth();

	useEffect(() => {
		const fetchEmployeeGreetings = async () => {
			try {
				const response = await fetch(`${apiurl}/greetings/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				const data = await response.json();
				if (response.ok) {
					setEmployeeGreetings(data.data);
				} else {
					console.error("Error fetching employee greetings:", data);
				}
			} catch (error) {
				console.error("Error fetching employee greetings:", error);
			}
		};

		const fetchChildGreetings = async () => {
			try {
				const response = await fetch(`${apiurl}/greetings/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						childid: sessionStorage.getItem("selectedChildId"),
					}),
				});
				const data = await response.json();
				if (response.ok) {
					setChildGreetings(data.data);
				} else {
					console.error("Error fetching child greetings:", data);
				}
			} catch (error) {
				console.error("Error fetching child greetings:", error);
			}
		};

		fetchEmployeeGreetings();
		fetchChildGreetings();
	}, []);

	return (
		<div>
			<List
				header={<div>Employee Greetings</div>}
				bordered
				dataSource={employeeGreetings}
				renderItem={(item) => (
					<List.Item>
						{item.type === "birthday"
							? `Wish the employee for their birthday on ${item.date}`
							: ""}
					</List.Item>
				)}
			/>
			<List
				header={<div>Child Greetings</div>}
				bordered
				dataSource={childGreetings}
				renderItem={(item) => (
					<List.Item>
						{item.type === "birthday"
							? `Wish the child for their birthday on ${item.date}`
							: ""}
					</List.Item>
				)}
			/>
		</div>
	);
};

export default GreetingsList;
