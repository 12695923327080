import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Modal, message } from "antd";
import "./auth.css";
import logo from "./../../Images/LOGO-10.png";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";
import OtpVerification from "../OTP/otp";

const Login = () => {
	const [form] = Form.useForm();
	const { handleLogin, apiurl, handleSessionLogin } = useAuth();
	const navigate = useNavigate();
	const [rememberMe, setRememberMe] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const handleAuthentication = async (value) => {
		setLoading(true);
		const response = await fetch(`${apiurl}/login/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(value),
		});
		if (response.ok) {
			const data = await response.json();
			if (data) {
				if (rememberMe) {
					handleLogin(data.access_token, data.issup);
				} else {
					handleSessionLogin(data.access_token, data.issup);
				}
				navigate(`/switchchild`);
			}
			setLoading(false);
		} else {
			const data = await response.json();
			message.error(data.error);
			setLoading(false);
		}
		setLoading(false);
	};

	const handleModalClose = () => {
		setShowModal(false);
	};

	return (
		<div className="login-main">
			{loading ? (
				<Loader />
			) : (
				<div className="login-container">
					<div className="login-grid">
						<div className="left-section">
							<h2>Login</h2>
							<Form
								form={form}
								className="login-form"
								layout="vertical"
								initialValues={{
									remember: true,
								}}
								onFinish={handleAuthentication}
								autoComplete="off">
								<Form.Item
									label="Email"
									name="username"
									rules={[
										{
											required: true,
											message: "Please enter the email!",
										},
									]}>
									<Input className="inpp" />
								</Form.Item>
								<Form.Item
									label="Password"
									name="password"
									rules={[
										{
											required: true,
											message: "Please enter the password!",
										},
									]}>
									<Input.Password className="inpp" />
								</Form.Item>
								<div className="forgotpass">
									<Link to="/forgot">Forgot Password?</Link>
								</div>
								<Form.Item className="sub-btn">
									<Button type="primary" htmlType="submit" loading={loading}>
										Login
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div className="right-section">
							<img src={logo} alt="Logo" className="logo" />
							<p className="description">
								Streamlining Organizational Processes for Seamless
								Synchronization.
							</p>
						</div>
					</div>
				</div>
			)}
			<Modal
				open={showModal}
				onCancel={handleModalClose}
				footer={null}
				wrapClassName="modal-wrapper"
				centered>
				<OtpVerification />
			</Modal>
		</div>
	);
};

export default Login;
