import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { Card, message, Button, Table } from "antd";
import './Home.css';

const EmployeeBirthdays= () => {
    const [info,setInfo]=useState([])
    const { apiurl, token } = useAuth();

    useEffect(() => {
        fetchEmpBirthdays();
    },[]);

    const columns = [
        {
            title: "Name",
            dataIndex: "userName",
            key: "userName",
           
        },
        {
            title: "Date of Birth",
            dataIndex: "dateOfBirth",
            key: "dateOfBirth",
        },
       
    ];

    const fetchEmpBirthdays = async () => {
        try {
            const response = await fetch(`${apiurl}/getempbirthdays/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    'childid': sessionStorage.getItem('selectedChildId'),
                    
                })
            });

            const data = await response.json();

            if (response.ok) {
                setInfo(data);
            } else {
                message.error(data.error);
            }
        } catch (error) {
            message.error("An error occurred while processing your request.");
        }
    };

    return (
        <div className="empdata-table">
            <h1>Employee Birthdays </h1>
            <Table
                dataSource={info}
                columns={columns}
               
            />
        </div>
    );
};

export default EmployeeBirthdays;
