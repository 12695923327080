import React, { useState } from "react";
import { Upload, Button, message, Spin, Table } from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Papa from "papaparse";
import Success from "../utils/Success";

const { Dragger } = Upload;

const AttUpload = () => {
	const [file, setFile] = useState(null);
	const [year, setYear] = useState(new Date().getFullYear());
	const [workingDays, setWorkingDays] = useState(0);
	const { apiurl, token } = useAuth();
	const [loading, setLoading] = useState(false);
	const [tableData, setTableData] = useState([]);
	const [success, setSuccess] = useState(false);
	const [month, setMonth] = useState(0);

	const handleFileChange = (info) => {
		if (info.file.status === "done") {
			setFile(info.file.originFileObj);
		} else if (info.file.status === "error") {
			message.error("Upload failed.");
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		onSuccess("ok");
		Papa.parse(file, {
			header: true,
			skipEmptyLines: true,
			complete: (results) => {
				setTableData(results.data);
			},
		});
	};

	const handleSubmit = async () => {
		if (!file || !year || !workingDays) {
			message.warning("Please fill all fields before submitting.");
			return;
		}
		setLoading(true);

		const formData = new FormData();
		formData.append("childid", sessionStorage.getItem("selectedChildId"));
		formData.append("month", month);
		formData.append("year", year);
		formData.append("workingDays", workingDays);
		formData.append("file", file);

		const headers = {
			Authorization: `Bearer ${token}`,
		};

		const response = await fetch(`${apiurl}/uploadatt/`, {
			method: "POST",
			body: formData,
			headers: headers,
		});

		if (response.ok) {
			const data = await response.json();
			setLoading(false);
			message.success(data.message);
			setSuccess(true);
		} else {
			const data = await response.json();
			setLoading(false);
			message.error(data.error || "An error occurred during the upload.");
		}
	};

	if (success) {
		return <Success title="Attendance Submitted Successfully" />;
	}

	if (loading) {
		return (
			<div className="loading-container">
				<Spin
					tip="Uploading..."
					size="large"
					indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
				/>
			</div>
		);
	}

	const columns =
		tableData.length > 0
			? Object.keys(tableData[0]).map((key) => ({
					title: key,
					dataIndex: key,
					key: key,
			  }))
			: [];

	return (
		<div className="name-sheet-main">
			<h3>Upload Attendance</h3>
			<h4>Give a CSV file with fields: email, date, timein, timeout</h4>
			<h5 style={{ color: "red" }}>
				Make sure the header is the same as above
			</h5>
			<select
				name="month"
				onChange={(e) => setMonth(e.target.value)}
				value={month}>
				<option value={1}>January</option>
				<option value={2}>February</option>
				<option value={3}>March</option>
				<option value={4}>April</option>
				<option value={5}>May</option>
				<option value={6}>June</option>
				<option value={7}>July</option>
				<option value={8}>August</option>
				<option value={9}>September</option>
				<option value={10}>October</option>
				<option value={11}>November</option>
				<option value={12}>December</option>
			</select>
			<input
				name="year"
				type="number"
				placeholder="Year"
				value={year}
				onChange={(e) => setYear(e.target.value)}
				style={{ marginLeft: 10, marginRight: 10 }}
			/>
			<input
				name="workingDays"
				type="number"
				placeholder="Number of Working Days"
				value={workingDays}
				onChange={(e) => setWorkingDays(e.target.value)}
			/>
			<Dragger
				className="name-sheet-upload"
				accept=".csv"
				maxCount={1}
				customRequest={customRequest}
				onChange={handleFileChange}>
				<p className="ant-upload-drag-icon">
					<InboxOutlined />
				</p>
				<p className="ant-upload-text">
					Click or drag file to this area to upload
				</p>
				<p className="ant-upload-hint">Upload only .csv files</p>
			</Dragger>
			{tableData.length > 0 && (
				<Table
					dataSource={tableData}
					columns={columns}
					rowKey={(record) => record.email}
				/>
			)}
			<Button className="name-sheet-btn" type="primary" onClick={handleSubmit}>
				Upload
			</Button>
		</div>
	);
};

export default AttUpload;
