import React, { useState,useEffect } from 'react';
import { Form, Select ,Input,message, Button} from 'antd';
import { useAuth } from '../../utils/useAuth';
import Main from '../Layouts/Main';

const Appraisal= () => {
  const [form] = Form.useForm();
  const [employeectc, setEmployeectc] = useState('');
  const [employees,setEmployees] = useState([]);
  const {apiurl,token}=useAuth();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchemployees();
}, []);
const onFinish = async (values) => {
    console.log(values);
   


    setLoading(true);
    values = { ...values, childid: sessionStorage.getItem("selectedChildId") };
    try {
        const response = await fetch(`${apiurl}/appraisals/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(values),
        });
        const data= await response.json()
        if (response.ok) {
            setLoading(false);
            message.success(data.success);
            form.resetFields();
            fetchemployees();
        } else {
            setLoading(false);
            message.error(data.error);
        }
    } catch (error) {
    
        setLoading(false);
        message.error("Failed to send an appraisal please try again!");
    }
};


const fetchemployees = async () => {
    try {
        const response = await fetch(`${apiurl}/fetchemployees/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                childid: sessionStorage.getItem("selectedChildId"),
            }),
        });
        if (response.ok) {
            const data = await response.json();
            console.log(data);
            setEmployees(data.data);
        } else {
            message.error("Unable to find Employees. Please try again.");
        }
    } catch (error) {
        message.error(error);
    }
};


  const handleEmployeeChange = (value) => {
    const selectedEmployee = employees.find(employee => employee.id === value);
    if (selectedEmployee) {
      setEmployeectc(selectedEmployee.ctc);
      console.log(selectedEmployee.ctc)
      form.setFieldsValue({ previousctc: selectedEmployee.ctc });
    }
  };
  if (loading){
    return <div>Loading...</div>
}

  return (
    
    <div>
        <Main>
      <Form form={form} className="leave-form" name="AppraisalForm" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="Select Employee"
          name="employeeid"
          rules={[{ required: true, message: 'Please select an employee' }]}
        >
          <Select placeholder="Select an Employee" onChange={handleEmployeeChange}>
            {employees?.map((employee) => (
              <Select.Option key={employee.id} value={employee.id}>
                {employee.userName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Previous CTC" name="previousctc">
          <Input value={employeectc} readOnly />
        </Form.Item>
        <Form.Item label="Current CTC" name="currentctc" 
         rules={[{ required: true, message: 'Please enter the new CTC' }]}  >
          <Input/>
        </Form.Item>
        <Form.Item  label="Message" name='description'
        rules={[{ required: true, message: 'Please enter the new CTC' }]} >
            <Input.TextArea/>
        </Form.Item>
        <Form.Item>
        <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>
      </Main>
    </div>
  );
};
export default Appraisal;
