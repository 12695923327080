import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Policy.css";

const AttendanceRequestPolicy = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [isPolicy, setIspolicy] = useState(false);
	const [id, setId] = useState("");

	useEffect(() => {
		fetchPolicy();
	}, []);

	const fetchPolicy = async () => {
		try {
			const response = await fetch(`${apiurl}/attendance-request-policy1/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setInitialValues(data.data);
				setIspolicy(true);
			} else {
				setIspolicy(false);
			}
		} catch (error) {
			message.error(error);
			setIspolicy(false);
		}
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			values = {
				...values,
				childid: sessionStorage.getItem("selectedChildId"),
			};
			const response = await fetch(`${apiurl}/attendance-request-policy/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Policy framed successfully");
				setCreateMode(false);
				fetchPolicy();
			} else {
				message.error("Failed to frame policy");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error framing policy");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		values = {
			...values,
			childid: sessionStorage.getItem("selectedChildId"),
			id: id,
		};
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/attendance-request-policy/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				fetchPolicy();
				setEditMode(false);
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	return (
		<div className="policy-main">
			{isPolicy ? (
				<>
					<Card
						className="policy"
						bordered={false}
						title="Attendance Request Policy"
						extra={
							<Button
								icon={<EditOutlined />}
								onClick={() => {
									setEditMode(true);
									setId(initialValues.id);
								}}
								disabled={editMode}
							/>
						}>
						<p>
							<span>No of Attendance Requests Allowed(%)</span>{" "}
							{initialValues.no_of_requests}{" "}
						</p>
					</Card>
				</>
			) : (
				<>
					<h3>Attendance Request Policy Not Yet Created.</h3>
					<Button onClick={() => setCreateMode(true)}>Create Policy</Button>
				</>
			)}
			<Modal
				open={createMode}
				title="Create Attendance Request Policy"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={handleCreate}>
					<Form.Item
						required
						name="no_of_requests"
						label="No of Attendance Requests Allowed">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={editMode}
				destroyOnClose
				title="Edit Details"
				onCancel={() => setEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					initialValues={initialValues}
					layout="vertical"
					onFinish={handleUpdate}>
					<Form.Item
						required
						name="no_of_requests"
						label="No of Attendance Requests Allowed">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default AttendanceRequestPolicy;
