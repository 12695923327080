import React,{useEffect, useState} from "react";
import { useAuth } from '../utils/useAuth'
import Main from '../Homepages/Layouts/Main';
import { message ,Table,Popconfirm,Button} from 'antd';


const HRCompOffRequest = () => { 

    const { apiurl ,token} = useAuth();
    const [data,setData]=useState(null);
    const [loading, setLoading] = useState(true);

    const [reviewcompoff, setReviewCompOff] = useState([]);
	const [compoffRequests, setCompOffRequests] = useState([]);

 useEffect(()=>{
fetchCompOffRequests();
// updateStatus();
 },[]);

 const fetchCompOffRequests = async () => {
    try {
        const response = await fetch(`${apiurl}/hrdisplaycompoffrequests/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})
        });
        const data = await response.json();
        setCompOffRequests(data.data);
        // setReviewCompOff(data.my_data);
    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false);
    }
};

 const updateCompOffStatus = async (id, status) => {
    try {
        const response = await fetch(`${apiurl}/hrcomprequest/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id: id,
                hrstatus: status,
                childid: sessionStorage.getItem("selectedChildId"),
            }),
        });
        const data = await response.json();
        if (response.ok) {
            message.success(data.message);
            fetchCompOffRequests();
        } else {
            message.error(data.error);
        }
    } catch (error) {
        message.error(error);
    }
};


const columns=[
    { title: "Parent", dataIndex: "parent", key: "parent" },
		{ title: "Child", dataIndex: "child", key: "child" },
		{ title: "ManagerStatus", dataIndex: "managerstatus", key: "managerstatus" },
		{ title: "HRStatus", dataIndex: "hrstatus", key: "hrstatus" },
		{ title: "HR", dataIndex: "approvedbyhr", key: "approvedbyhr" },
		{ title: "Reporting Manager", dataIndex: "approvedbyrm", key: "approvedbyrm" },
		{ title: "Employee", dataIndex: "employee", key: "employee" },
		{ title: "Date", dataIndex: "Date", key: "Date" },
		{ title: "Reason", dataIndex: "reason", key: "reason" },
    
   {
        title: "Action",
        dataIndex: "action",
        key: "action",
        render: (_, record) => (
            <>
                {record.hrstatus === "underreview" && (
                    <>
                        <Popconfirm
                            title="Confirm"
                            onConfirm={() => updateCompOffStatus(record.id, "approved")}
                            okText="Approve">
                            <Button>Approve</Button>
                        </Popconfirm>
                        <Popconfirm
                            title="Confirm"
                            onConfirm={() => updateCompOffStatus(record.id, "rejected")}
                            okText="Reject">
                            <Button>Reject</Button>
                        </Popconfirm>
                    </>
                )}
            </>
        ),
    },
]

  return (
<Main>
<div className="approvals-main">
<div className="section-head">Compensation Off Requests</div>

<Table columns={columns} dataSource ={compoffRequests} />

</div>
  </Main>
 
);
};

export default HRCompOffRequest;