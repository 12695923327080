import React, { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { useNavigate } from "react-router-dom";
import "./Style.css";
import HeaderComponent from "../../Header/Header";


const ChildDetails = () => {
	const navigate = useNavigate();
	const { apiurl, token } = useAuth();
	const [childdata, setChildData] = useState(null);

	useEffect(() => {
		fetchChilds();
	}, []);
	const handleChildClick = (id,type) => {
		console.log(id);
		sessionStorage.setItem("selectedChildId", id);
		sessionStorage.setItem("selectedAttType", type);
		navigate(`/home`);
	};
	const fetchChilds = async () => {
		const response = await fetch(`${apiurl}/fetchchildaccounts/`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});

		if (response.ok) {
			const info = await response.json();
			if (info.data === "SUPER_USER") {
				navigate("/home");
			}
			setChildData(info.data);
		}
	};

	return (
		<HeaderComponent>
			<div className="switch-main">
				<div className="section-head">Select child</div>
				{childdata && (
					<div className="switch-childs">
						{childdata.map((child, index) => (
							<div
								className="child"
								key={index}
								onClick={() =>
									handleChildClick(child.id, child.attendance_type)
								}
								style={{ cursor: "pointer" }}>
								<center>{child.child}</center>
							</div>
						))}
					</div>
				)}
			</div>
		</HeaderComponent>
	);
};

export default ChildDetails;
