import React, { useEffect, useState } from 'react'
import Main from '../Layouts/Main'
import { useAuth } from '../../utils/useAuth';
import { Button, Form, Modal,  message } from 'antd';
import BasicDetailsModal from '../../Employee/BasicDetails';
import { InboxOutlined, EditOutlined } from "@ant-design/icons";
import Dragger from 'antd/es/upload/Dragger';
import './Profile.css';
import Documents from '../../Employee/Documents';
import EducationDetails from '../../Employee/EducationDetails';
import Relationships from '../../Employee/Relationships';
import ExperienceDetails from '../../Employee/WorkExperience';
import Occasions from '../../Employee/Occasions';

const Profile = () => {
    const [empData, setEmpData] = useState(null);
    const { apiurl, token } = useAuth();
	const [profileImg, setProfileImg] = useState("");
	const [loading,setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);

	const [form] = Form.useForm();


	useEffect(() => {
		fetchEmployeeDetails();
		fetchImages();
	}, []);

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};
	
	const updateImages = async (type) => {
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append("type", type);
			formData.append("image", file);

			console.log(formData);
			try {
				const response = await fetch(`${apiurl}/handleimages/`, {
					method: "PUT",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});
				if (response.ok) {
					const data = await response.json();
					message.success(data.message);
					setImageEditMode(false);
					fetchImages();
					setLoading(false);
				} else {
					const data = await response.json();
					message.error(data.error);
					setLoading(false);
				}
			} catch (error) {
				message.error("Error fetching images");
				setLoading(false);
			}
		} else {
			setLoading(false);
			message.warning("Please select a file to upload.");
		}
	};
    

    const fetchEmployeeDetails = async () => {
			try {
				const response = await fetch(`${apiurl}/createemployee/`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});

				const data = await response.json();

				if (response.ok) {
					setEmpData(data.data);
				} else {
					message.error(data.error);
				}
			} catch (error) {
				message.error("An error occurred while processing your request.");
			}
		};

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};
    return (
			<Main>
				<div className="section-1">
					<div className="profile-card">
						<div className="left-sec">
							<div>
								<img src={`${apiurl}${profileImg}`} alt="profile" />
								<Button
									shape="circle"
									icon={<EditOutlined />}
									onClick={() => setImageEditMode(true)}></Button>
							</div>
							<div className="user-details">
								<h2>{empData?.userName}</h2>
								<h3>
									{empData?.parent} {empData?.child && <> - {empData?.child}</>}
								</h3>
								<h3>{empData?.type}</h3>
							</div>
						</div>
					</div>
					<div className="right-sec">
						<div>
							<span>Email: </span>
							{empData?.email}
						</div>
						<div>
							<span>Date Of Birth: </span>
							{empData?.dateOfBirth}
						</div>
						<div>
							<span>Gender: </span>
							{empData?.gender}
						</div>
						<div>
							<span>Contact: </span>
							{empData?.phoneNumber}
						</div>
					</div>
				</div>
				<div className="section">
					<BasicDetailsModal />
				</div>
				<div className="section">
					<Documents />
				</div>

				<div className="section">
					<EducationDetails />
				</div>

				<div className="section">
					<Relationships />
				</div>

				<div className="section">
					<Occasions />
				</div>

				<div className="section">
					<ExperienceDetails />
				</div>

				<Modal
					open={imageEditMode}
					destroyOnClose
					title="Edit Image"
					onCancel={() => {
						setImageEditMode(false);
						fetchImages();
					}}
					footer={[
						<Button key="cancel" onClick={() => setImageEditMode(false)}>
							Cancel
						</Button>,
						<Button
							key="submit"
							type="primary"
							loading={loading}
							onClick={() => form.submit()}>
							Submit
						</Button>,
					]}>
					<Form
						form={form}
						layout="vertical"
						onFinish={() => updateImages("profile")}>
						<Form.Item>
							<Dragger
								className="image-upload"
								accept=".png,.jpg,.jpeg"
								maxCount={1}
								customRequest={customRequest}
								onChange={handleFileChange}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag image to this area to upload
								</p>
								<p className="ant-upload-hint">Upload only image files</p>
							</Dragger>
						</Form.Item>
					</Form>
				</Modal>
			</Main>
		);
}

export default Profile
