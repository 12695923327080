import React, { useState, useEffect } from "react";
import {
	Modal,
	Form,
	Input,
	Button,
	Table,
	message,
	Card,
} from "antd";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";
import Dragger from "antd/es/upload/Dragger";


const ExperienceDetails = () => {
	
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState([]);
	const [type,setType] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const { token, apiurl } = useAuth();
	const [file, setFile] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);
	const [id, setId] = useState(null);


	useEffect(() => {
		fetchExperienceDetails();
	}, []);

	const fetchExperienceDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/experience/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Experience Details");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching Experience Details");
		}
	};

	const handleEdit = (item) => {
		setSelectedItem(item);
		setEditMode(true);
		form.setFieldsValue(item);
	};

	const handleCancelEdit = () => {
		setEditMode(false);
		setSelectedItem(null);
		form.resetFields();
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/experience/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details created successfully");
				setCreateMode(false);
				fetchExperienceDetails();
				form.resetFields();
			} else {
				message.error("Failed to create details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error creating details");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		// values.worked_from = values.worked_from.toISOString().split("T")[0];
		// values.worked_to = values.worked_to.toISOString().split("T")[0];
		values = { ...values, id: selectedItem.id };
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/experience/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				setEditMode(false);
				setSelectedItem(null);
				fetchExperienceDetails();
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	const columns = [
		{
			title: "Organization",
			dataIndex: "organization",
			key: "organization",
		},
		{
			title: "Worked From",
			dataIndex: "worked_from",
			key: "worked_from",
		},
		{
			title: "Worked To",
			dataIndex: "worked_to",
			key: "worked_to",
		},

		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
		},
		{
			title: "Reason for Resign",
			dataIndex: "reason_for_resign",
			key: "reason_for_resign",
		},
		{
			title: "Relieving Letter",
			key: "relieving_letter",
			render: (text, record) => (
				<span>
					{record.relieving_letter ? (
						<a
							href={`${apiurl}${record?.relieving_letter}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					) : (
						<button
							onClick={() => {
								setId(record.id);
								setType("rletter");
								setImageEditMode(true);
							}}>
							Add
						</button>
					)}
				</span>
			),
		},
		{
			title: "Pay Slip 1",
			key: "payslip_1",
			render: (text, record) => (
				<span>
					{record.payslip_1 ? (
						<a
							href={`${apiurl}${record?.payslip_1}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					) : (
						<button
							onClick={() => {
								setId(record.id);
								setType("pay1");
								setImageEditMode(true);
							}}>
							Add
						</button>
					)}
				</span>
			),
		},
		{
			title: "Pay Slip 2",
			key: "payslip_2",
			render: (text, record) => (
				<span>
					{record.payslip_2 ? (
						<a
							href={`${apiurl}${record?.payslip_2}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					) : (
						<button
							onClick={() => {
								setId(record.id);
								setType("pay2");
								setImageEditMode(true);
							}}>
							Add
						</button>
					)}
				</span>
			),
		},
		{
			title: "Pay Slip 3",
			key: "payslip_3",
			render: (text, record) => (
				<span>
					{record.payslip_3 ? (
						<a
							href={`${apiurl}${record?.payslip_3}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					) : (
						<button
							onClick={() => {
								setId(record.id);
								setType("pay3")
								setImageEditMode(true);
							}}>
							Add
						</button>
					)}
				</span>
			),
		},
		
	];

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};

	const updateImages = async (type) => {
		console.log("entered");
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append("type", type);
			formData.append("image", file);
			formData.append("id", id);

			try {
				const response = await fetch(`${apiurl}/handleimages/`, {
					method: "PUT",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});
				if (response.ok) {
					const data = await response.json();
					message.success(data.message);
					setImageEditMode(false);
					setLoading(false);
					fetchExperienceDetails();
				} else {
					const data = await response.json();
					message.error(data.error);
					setLoading(false);
				}
			} catch (error) {
				message.error("Error fetching images");
				setLoading(false);
			}
		} else {
			setLoading(false);
			message.warning("Please select a file to upload.");
		}
	};

	const onDownload = (src) => {
		fetch(src)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");
				link.href = url;
				link.download = "experience.png";
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
				link.remove();
			});
	};

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Experience Details"
				extra={
					<Button
						icon={<PlusOutlined />}
						onClick={() => setCreateMode(true)}
						disabled={editMode}
					/>
				}>
				<Table
					className="experience-table"
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20, overflow: "auto" }}
				/>
			</Card>
			<Modal
				open={editMode}
				title="Edit Details"
				onCancel={handleCancelEdit}
				footer={[
					<Button key="cancel" onClick={handleCancelEdit}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					destroyOnClose
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={selectedItem}>
					<Form.Item name="worked_from" label="Worked From">
						<Input type="date" />
					</Form.Item>
					<Form.Item name="worked_to" label="Worked To">
						<Input type="date" />
					</Form.Item>
					<Form.Item name="organization" label="Organization">
						<Input />
					</Form.Item>
					<Form.Item name="designation" label="Designation">
						<Input />
					</Form.Item>
					<Form.Item name="department" label="Department">
						<Input />
					</Form.Item>
					<Form.Item name="reason_for_resign" label="Reason for Resign">
						<Input.TextArea />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={createMode}
				title="Add New Experience"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					initialValues={false}
					onFinish={handleCreate}>
					<Form.Item name="worked_from" label="Worked From">
						<Input type="date" />
					</Form.Item>
					<Form.Item name="worked_to" label="Worked To">
						<Input type="date" />
					</Form.Item>
					<Form.Item name="organization" label="Organization">
						<Input />
					</Form.Item>
					<Form.Item name="designation" label="Designation">
						<Input />
					</Form.Item>
					<Form.Item name="department" label="Department">
						<Input />
					</Form.Item>
					<Form.Item name="reason_for_resign" label="Reason for Resign">
						<Input.TextArea />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={imageEditMode}
				destroyOnClose
				title="Edit Image"
				onCancel={() => {
					setImageEditMode(false);
				}}
				footer={[
					<Button key="cancel" onClick={() => setImageEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => updateImages(type)}>
						Submit
					</Button>,
				]}>
				<Form layout="vertical">
					<Form.Item>
						<Dragger
							className="image-upload"
							accept=".png,.jpg,.jpeg"
							maxCount={1}
							customRequest={customRequest}
							onChange={handleFileChange}>
							<p className="ant-upload-drag-icon">
								<InboxOutlined />
							</p>
							<p className="ant-upload-text">
								Click or drag image to this area to upload
							</p>
							<p className="ant-upload-hint">Upload only image files</p>
						</Dragger>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default ExperienceDetails;
