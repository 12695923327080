import React, { useState } from "react";
import { Button, Input, message } from "antd";
import {  EyeInvisibleOutlined } from "@ant-design/icons";
import { Card, Form } from "antd";
import image from "../userauth/loginlogo.svg";
import { EyeOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";
import {useNavigate } from "react-router-dom";
const ChangePasswordForm = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm();
	const [showPassword, setShowPassword] = useState(false);
	const [loading,setLoading] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};
	
	const { apiurl, token, handleLogout } = useAuth();

	const handleSubmit = (values) => {
		setLoading(true);
		const currentPassword = values.currentPassword;
		const newPassword = values.newPassword;
		const confirmPassword = values.confirmPassword;

		fetch(`${apiurl}/changepassword/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				currentPassword,
				newPassword,
				confirmPassword,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					message.success("Password changed successfully.");
					navigate('/change/success')
					form.resetFields();
					handleLogout();
					setLoading(false);
				} else {
					setLoading(false);
					message.error(data.message);
				}
				
			})
			.catch((error) => {
				setLoading(false);
				message.error("An error occurred while changing the password.");
			});
	};

	if (loading) {
		return (
				<Loader />
		);
	}

	return (
		<div>
					<>
						<div className="bgg">
							<Card className="cont bg">
								<Card className="innercard">
									<div className="grid-2">
										<div className="mt-40">
											<img
												src={image}
												alt="logo"
												className="img-fluid img-animated"></img>
										</div>

										<div className="centerdflex">
											<center>
												<h1>Reset Password</h1>
											</center>

											<Form
												form={form}
												className="mt-50"
												layout="vertical"
												wrapperCol={{
													span: 16,
												}}
												style={{
													maxWidth: 600,
												}}
												initialValues={{
													remember: true,
												}}
												onFinish={handleSubmit}
												autoComplete="off">
												<Form.Item
													label="Current Password"
													name="currentPassword"
													rules={[
														{
															required: true,
															message: "Please input your password!",
														},
													]}>
													<Input
														type={showPassword ? "text" : "password"}
														className="inp"
													/>
												</Form.Item>
												<Button
													type="text"
													className="showbt"
													icon={
														showPassword ? (
															<EyeOutlined />
														) : (
															<EyeInvisibleOutlined />
														)
													}
													onClick={togglePasswordVisibility}
												/>

												<Form.Item
													label="New Password"
													name="newPassword"
													style={{ marginTop: "-37px" }}
													rules={[
														{
															required: true,
															message: "Please input your password!",
														},
													]}>
													<Input
														type={showPassword ? "text" : "password"}
														className="inp"
													/>
												</Form.Item>
												<Button
													type="text"
													className="showbt"
													icon={
														showPassword ? (
															<EyeOutlined />
														) : (
															<EyeInvisibleOutlined />
														)
													}
													onClick={togglePasswordVisibility}
												/>
												<Form.Item
													label="Confirm New Password"
													name="confirmPassword"
													style={{ marginTop: "-37px" }}
													rules={[
														{
															required: true,
															message: "Please input your password!",
														},
													]}>
													<Input
														type={showPassword ? "text" : "password"}
														className="inp"
													/>
												</Form.Item>
												<Button
													type="text"
													className="showbt"
													icon={
														showPassword ? (
															<EyeOutlined />
														) : (
															<EyeInvisibleOutlined />
														)
													}
													onClick={togglePasswordVisibility}
												/>

												<Form.Item
													wrapperCol={{
														offset: 8,
														span: 16,
													}}>
													<Button
														type="primary"
														htmlType="submit"
														className="lb">
														Submit
													</Button>
												</Form.Item>
											</Form>
										</div>
									</div>
								</Card>
							</Card>
						</div>
					</>
		</div>
	);
};

export default ChangePasswordForm;
