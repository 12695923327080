import React, { useState, useContext } from "react";
import { Form, Input, Button, Steps, Select, message } from "antd";
import "./ParentAccount.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";

const { Step } = Steps;

const ParentAccountForm = () => {
	const navigate = useNavigate();
	const [currentStep, setCurrentStep] = useState(0);
	const [form] = Form.useForm();
	const { apiurl, token } = useAuth();
	const [editMode, setEditMode] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleNext = (values) => {
		setCurrentStep(currentStep + 1);
	};

	const handlePrev = () => {
		setCurrentStep(currentStep - 1);
	};
	const handleFinish = async (values) => {
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/add-organization/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (!response.ok) {
				const data = await response.json();
				message.error(data.error);
				setLoading(false);
			} else {
				const data = await response.json();
				if (data) {
					message.success(data.message);
					setLoading(false);
					navigate("/home");
				}
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			message.warning(error.message);
		}
	};

	const handleEdit = () => {
		setEditMode(true);
	};

	if (loading) {
		return <Loader />;
	}

	const renderStep1 = () => (
		<Form
			className="f"
			layout="vertical"
			form={form}
			name="parentAccountStep1"
			initialValues={{ remember: true }}
			onFinish={handleNext}>
			<div className="m-50">
				<Form.Item
					label="Name of the Organization"
					name="orgName"
					rules={[
						{ required: true, message: "Please enter the organization name" },
					]}>
					<Input className="inp" />
				</Form.Item>

				<Form.Item
					label="Organization Type"
					name="type"
					rules={[
						{ required: true, message: "Please select the organization type" },
					]}>
					<Select className="in">
						<option key="proprietorship" value="proprietorship">
							Proprietorship
						</option>
						<option key="partnershipFirm" value="partnershipFirm">
							Partnership Firm
						</option>
						<option key="llc" value="llc">
							LLC
						</option>
						<option key="Pvt.Ltdcompany" value="Pvt.Ltdcompany">
							Pvt.Ltd Company
						</option>
					</Select>
				</Form.Item>

				<Form.Item
					label="Name of Contact Person"
					name="contactPerson"
					rules={[
						{ required: true, message: "Please enter the contact person name" },
					]}>
					<Input className="inp" />
				</Form.Item>
			</div>
			<div className="m-50">
				<Form.Item
					label="Designation of the Contact Person"
					name="designation"
					rules={[{ required: true, message: "Please enter the designation" }]}>
					<Input className="inp" />
				</Form.Item>

				<Form.Item
					label="Email ID"
					name="email"
					rules={[
						{ required: true, message: "Please enter the email ID" },
						{ type: "email", message: "Please enter a valid email ID" },
					]}>
					<Input className="inp" />
				</Form.Item>

				<Form.Item
					label="Contact Number"
					name="contactNo"
					rules={[
						{ required: true, message: "Please enter the contact number" },
					]}>
					<Input className="inp" type="number" />
				</Form.Item>
				<Form.Item>
					<div className="btn">
						<Button type="primary" htmlType="submit" className="lb">
							Next
						</Button>
					</div>
				</Form.Item>
			</div>
		</Form>
	);

	const renderStep2 = () => (
		<Form
			className="f"
			layout="vertical"
			form={form}
			name="parentAccountStep2"
			initialValues={{ remember: true }}
			onFinish={handleNext}>
			<div className="m-50">
				<Form.Item
					label="Registration Number"
					name="regNo"
					rules={[
						{
							required: true,
							message: "Please enter the registration number",
						},
						{
							len: 10,
							message: "Must Contain 10 Characters",
						},
					]}>
					<Input className="inp" />
				</Form.Item>

				<Form.Item
					label="Date of Company Registration"
					name="companyRegistrationDate"
					rules={[
						{
							required: true,
							message: "Please enter the date of company registration",
						},
						{
							len: 10,
							message: "Only 10 Characters allowed",
						},
					]}>
					<Input className="inp" type="date" />
				</Form.Item>

				<Form.Item
					label="Company GST Registration Number"
					name="companyGstRegNo"
					rules={[
						{
							required: true,
							message: "Please enter the GST registration number",
						},
						{
							len: 15,
							message: "Only 15 Characters allowed",
						},
					]}>
					<Input className="inp" />
				</Form.Item>
			</div>
			<div className="m-50">
				<Form.Item
					label="Company PAN Number"
					name="companyPanNo"
					rules={[
						{ required: true, message: "Please enter the PAN number" },
						{
							len: 10,
							message: "Only 10 Characters allowed",
						},
					]}>
					<Input className="inp" />
				</Form.Item>

				<Form.Item
					label="Company TAN Number"
					name="CompanyTanNo"
					rules={[
						{ required: true, message: "Please enter the TAN number" },
						{
							len: 10,
							message: "Only 10 Characters allowed",
						},
					]}>
					<Input className="inp" />
				</Form.Item>

				<Form.Item
                                        label="Company Address"
                                        name="address"
                                        rules={[
                                                { required: true, message: "Please enter the company address" },
                                        ]}>
                                        <Input className="inp" />
                                </Form.Item>

				<div className="bt" style={{ marginTop: "50px" }}>
					<Button
						style={{ width: "102px", height: "46px", fontSize: "18px" }}
						onClick={handlePrev}>
						Previous
					</Button>
					<Button
						style={{
							width: "102px",
							height: "46px",
							fontSize: "18px",
							marginLeft: "10px",
						}}
						type="primary"
						htmlType="submit">
						Next
					</Button>
				</div>
			</div>
		</Form>
	);

	const renderStep3 = () => (
		<div className="confirmation">
			<Form
				className="f"
				layout="vertical"
				form={form}
				name="parentAccountStep1"
				initialValues={{ remember: true }}
				onFinish={handleFinish}>
				<div className="m-50">
					<Form.Item
						label="Name of the Organization"
						name="orgName"
						rules={[
							{ required: true, message: "Please enter the organization name" },
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Organization Type"
						name="type"
						rules={[
							{
								required: true,
								message: "Please select the organization type",
							},
						]}>
						<Select className="in" disabled={!editMode}>
							<option key="proprietorship" value="proprietorship">
								Proprietorship
							</option>
							<option key="partnershipFirm" value="partnershipFirm">
								Partnership Firm
							</option>
							<option key="llc" value="llc">
								LLC
							</option>
							<option key="Pvt.Ltdcompany" value="Pvt.Ltdcompany">
								Pvt.Ltd Company
							</option>
						</Select>
					</Form.Item>

					<Form.Item
						label="Name of Contact Person"
						name="contactPerson"
						rules={[
							{
								required: true,
								message: "Please enter the contact person name",
							},
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Designation of the Contact Person"
						name="designation"
						rules={[
							{ required: true, message: "Please enter the designation" },
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Email ID"
						name="email"
						rules={[
							{ required: true, message: "Please enter the email ID" },
							{ type: "email", message: "Please enter a valid email ID" },
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Contact Number"
						name="contactNo"
						rules={[
							{ required: true, message: "Please enter the contact number" },
						]}>
						<Input className="inp" type="number" disabled={!editMode} />
					</Form.Item>
				</div>
				<div className="m-50">
					<Form.Item
						label="Registration Number"
						name="regNo"
						rules={[
							{
								required: true,
								message: "Please enter the registration number",
							},
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Date of Company Registration"
						name="companyRegistrationDate"
						rules={[
							{
								required: true,
								message: "Please enter the date of company registration",
							},
						]}>
						<Input className="inp" type="date" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Company GST Registration Number"
						name="companyGstRegNo"
						rules={[
							{
								required: true,
								message: "Please enter the GST registration number",
							},
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Company PAN Number"
						name="companyPanNo"
						rules={[
							{ required: true, message: "Please enter the PAN number" },
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					<Form.Item
						label="Company TAN Number"
						name="CompanyTanNo"
						rules={[
							{ required: true, message: "Please enter the TAN number" },
						]}>
						<Input className="inp" disabled={!editMode} />
					</Form.Item>

					 <Form.Item
	                                        label="Company Address"
        	                                name="address"
                	                        rules={[
                        	                        { required: true, message: "Please enter the company address" },
                                	        ]}>
                                        	<Input className="inp" />
	                                </Form.Item>

					<div className="bt">
						{!editMode && (
							<Button
								style={{
									width: "102px",
									height: "46px",
									fontSize: "18px",
									marginRight: "10px",
								}}
								danger
								onClick={handleEdit}>
								Edit
							</Button>
						)}
						{editMode && (
							<>
								<Button
									style={{
										width: "102px",
										height: "46px",
										fontSize: "18px",
										marginRight: "10px",
									}}
									danger
									onClick={() => setEditMode(false)}>
									Cancel
								</Button>
							</>
						)}
						<Button
							style={{ width: "102px", height: "46px", fontSize: "18px" }}
							htmlType="submit"
							type="primary">
							Confirm
						</Button>
					</div>
				</div>
			</Form>
		</div>
	);

	const renderCurrentStep = () => {
		switch (currentStep) {
			case 0:
				return renderStep1();
			case 1:
				return renderStep2();
			case 2:
				return renderStep3();
			default:
				return null;
		}
	};

	return (
		<div className="bgg">
			<div className="cont">
				<div className="stepcontt">
					<Steps current={currentStep}>
						<Step title="Step 1" />
						<Step title="Step 2" />
						<Step title="Step 3" />
					</Steps>
				</div>
				<div className="contentstep">{renderCurrentStep()}</div>
			</div>
		</div>
	);
};

export default ParentAccountForm;
