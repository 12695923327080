import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import './Policy.css';

const LeavePolicy = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [isPolicy, setIspolicy] = useState(false);

	useEffect(() => {
		fetchPolicy();
	}, []);

	const fetchPolicy = async () => {
		try {
			const response = await fetch(`${apiurl}/leavepolicy1/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setInitialValues(data.data);
				setIspolicy(true);
			} else {
				setIspolicy(false);
			}
		} catch (error) {
			message.error(error);
			setIspolicy(false);
		}
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			values = {...values,'childid':sessionStorage.getItem('selectedChildId')}
			const response = await fetch(`${apiurl}/leavepolicy/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Policy framed successfully");
				setCreateMode(false);
				fetchPolicy();
			} else {
				message.error("Failed to frame policy");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error framing policy");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		values = {...values,'childid':sessionStorage.getItem('selectedChildId')}
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/leavepolicy/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				fetchPolicy();
				setEditMode(false);
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	return (
		<div className="policy-main">
			{isPolicy ? (
				<>
					<Card
						className="policy"
						bordered={false}
						title="Leave Policy"
						extra={
							<Button
								icon={<EditOutlined />}
								onClick={() => setEditMode(true)}
								disabled={editMode}
							/>
						}>
						<p>
							<span>Sick Leaves Per Year</span> {initialValues.sickLeaves}{" "}
						</p>
						<p>
							<span>Casual Leaves Per Year</span> {initialValues.casualLeaves}{" "}
						</p>
						<p>
							<span>Maternity Leaves Per Year</span>{" "}
							{initialValues.maternityLeaves}{" "}
						</p>
						<p>
							<span>Paternity Leaves Per Year</span>{" "}
							{initialValues.paternity_leaves}{" "}
						</p>
						<p>
							<span>Privilege Leaves Per Year</span>{" "}
							{initialValues.privilege_leaves}{" "}
						</p>
						<p>
							<span>Bereavement Leaves Per year</span>{" "}
							{initialValues.bereavement_leaves}
						</p>
						<p>
							<span>Leaves Per Year</span>
							{initialValues.leaves_per_year}{" "}
						</p>
					</Card>
				</>
			) : (
				<>
					<h3>Policy Not Yet Created.</h3>
					<Button onClick={() => setCreateMode(true)}>Create Policy</Button>
				</>
			)}
			<Modal
				open={createMode}
				title="Create Leave Policy Anually"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={handleCreate}>
					<Form.Item required name="sickLeaves" label="Sick Leaves Per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="casualLeaves"
						label="Casual Leaves  per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="maternityLeaves"
						label="Maternity Leaves  per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="paternity_leaves"
						label="Paternity Leaves  per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="bereavement_leaves"
						label="Bereavement leaves per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="privilege_leaves"
						label="Privilege leaves per Year">
						<Input />
					</Form.Item>
					<Form.Item required name="leaves_per_year" label="Leaves Per Year">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={editMode}
				destroyOnClose
				title="Edit Details"
				onCancel={() => setEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={initialValues}>
					<Form.Item required name="sickLeaves" label="Sick Leaves Per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="casualLeaves"
						label="Casual Leaves  per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="maternityLeaves"
						label="Maternity Leaves  per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="paternity_leaves"
						label="Paternity Leaves  per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="bereavement_leaves"
						label="Bereavement leaves per Year">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="privilege_leaves"
						label="Privilege leaves per Year">
						<Input />
					</Form.Item>
					<Form.Item required name="leaves_per_year" label="Leaves Per Year">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default LeavePolicy;
