import React, { useState } from 'react';
import { Form, Checkbox, Button, Radio,message } from 'antd';
import './exitinterview.css'

const ExitInterviewForm = () => {
  const [reasons, setReasons] = useState([]);
  const [jobRankings, setJobRankings] = useState({});
  const [remunerationBenefitsRankings, setRemunerationBenefitsRankings] = useState({});
  const [companyRankings, setCompanyRankings] = useState({});
  const [supervisorRankings, setsupervisorRankings] = useState({});
  const [managementRankings, setmanagementRankings] = useState({});
  const [form]=Form.useForm();

  const handleReasonsChange = (checkedValues) => {
    setReasons(checkedValues);
  };
  const handleSubmit=()=>{
    message.success('Form submitted successfully');
    form.resetFields();
  }

  const handleRankingsChange = (section, question, value) => {
    switch (section) {
      case 'job':
        setJobRankings({ ...jobRankings, [question]: value });
        break;
      case 'remunerationBenefits':
        setRemunerationBenefitsRankings({ ...remunerationBenefitsRankings, [question]: value });
        break;
      case 'company':
        setCompanyRankings({ ...companyRankings, [question]: value });
        break;
      case 'supervisorRankings':
        setsupervisorRankings({ ...supervisorRankings, [question]: value });
        break;
      case 'managementRankings':
        setmanagementRankings({ ...managementRankings, [question]: value });
        break;
    
      default:
        break;
    }
  };

  const onFinish = (values) => {
    console.log('Selected reasons:', values.reasons);
    console.log('Job rankings:', jobRankings);
    console.log('Remuneration & Benefits rankings:', remunerationBenefitsRankings);
    console.log('Company rankings:', companyRankings);
    // Here you can send the selected reasons and rankings to your backend or perform any other actions
  };

  return (
    <Form
    form={form}
      name="exit_interview"
      className="exit-interview-form"
      initialValues={{ reasons: [] }}
      onFinish={onFinish}
    >
      <Form.Item
        name="reasons"
        label="Reasons for leaving"
        
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please select at least one reason',
        //   },
        // ]}
      >
        <Checkbox.Group onChange={handleReasonsChange}>
          <Checkbox value="Higher pay">Higher pay</Checkbox>
          <Checkbox value="Better benefits">Better benefits</Checkbox>
          <Checkbox value="Better career opportunity">Better career opportunity</Checkbox>
          <Checkbox value="Improved work life balance">Improved work life balance</Checkbox>
          <Checkbox value="Career change">Career change</Checkbox>
          <Checkbox value="Closer to home">Closer to home</Checkbox>
          <Checkbox value="Conflict with other employees">Conflict with other employees</Checkbox>
          <Checkbox value="Conflict with managers">Conflict with managers</Checkbox>
          <Checkbox value="Family and/or personal reasons">Family and/or personal reasons</Checkbox>
          <Checkbox value="Company instability">Company instability</Checkbox>
          <Checkbox value="Other">Other</Checkbox>
        </Checkbox.Group>
      </Form.Item>

      <Form.Item label="Rank the following:">
        <Form.Item
          name="jobRankings"
          noStyle
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Please rank the following',
        //     },
        //   ]}
        >
          <div>
            <p>Job was challenging:</p>
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'Job was challenging', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>There were sufficient opportunities for advancement :</p>
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'There were sufficient opportunities for advancement ', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Workload was manageable:</p>
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'Workload was manageable', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Sufficient resources and staff were available												</p>
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'Sufficient resources and staff were available', e.target.value)}>											', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Your colleagues listened and appreciated your suggestions 												</p>
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'Your colleagues listened and appreciated your suggestions', e.target.value)}> 												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Your skills were effectively used</p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'Your colleagues listened and appreciated your suggestions', e.target.value)}> 												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>You had access to adequate training and development programs </p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('job', 'YYou had access to adequate training and development programs ', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
        </Form.Item>
      </Form.Item>

      <Form.Item label="Remuneration & Benefits:">
        <Form.Item
          name="remunerationBenefitsRankings"
          noStyle
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Please rank the following',
        //     },
        //   ]}
        >
          <div>
            <p>The salary was adequate in relation to responsibilities:</p>
            <Radio.Group onChange={(e) => handleRankingsChange('remunerationBenefits', 'Salary adequacy', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other remuneration & benefits ranking questions here */}
          </div>
          <div>
            <p>Wages were paid on time</p>
            <Radio.Group onChange={(e) => handleRankingsChange('remunerationBenefits', 'Wages were paid on time', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other remuneration & benefits ranking questions here */}
          </div>
          <div>
            <p>Other benefits were good :</p>
            <Radio.Group onChange={(e) => handleRankingsChange('remunerationBenefits', 'Other benefits were good ', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other remuneration & benefits ranking questions here */}
          </div>
          <div>
            <p>The company’s superannuation fund returned good results											</p>
            <Radio.Group onChange={(e) => handleRankingsChange('remunerationBenefits', 'The company’s superannuation fund returned good results', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other remuneration & benefits ranking questions here */}
          </div>
          <div>
            <p>Work-life balance was promoted and practiced												</p>
            <Radio.Group onChange={(e) => handleRankingsChange('remunerationBenefits', 'Work-life balance was promoted and practiced', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other remuneration & benefits ranking questions here */}
          </div>
        </Form.Item>
      </Form.Item>

      <Form.Item label="The Company:">
        <Form.Item
          name="companyRankings"
          noStyle
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Please rank the following',
        //     },
        //   ]}
        >
          <div>
            <p>When you started, did the induction help and was it accurate:</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'Induction accuracy', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>Was a good and positive environment to work in												</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'Was a good and positive environment to work in', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>Had adequate equipment to do the work</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'Had adequate equipment to do the work', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>Got on well with other staff within the company												</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'Got on well with other staff within the company												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>There were sufficient staff to cover the work</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'There were sufficient staff to cover the work', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>The company was efficient in it’s dealings</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'The company was efficient in it’s dealings', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>Internal communication worked well</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'Internal communication worked well', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>There was no bullying or harassment </p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'There was no bullying or harassment ', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>There are adequate parking facilities</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'There are adequate parking facilities', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
          <div>
            <p>The business did not discriminate against any employee</p>
            <Radio.Group onChange={(e) => handleRankingsChange('company', 'The business did not discriminate against any employee', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other company ranking questions here */}
          </div>
        </Form.Item>
      </Form.Item>
      <Form.Item label="SupervisorRankings">
        <Form.Item
          name="supervisorRankings"
          noStyle
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Please rank the following',
        //     },
        //   ]}
        >
          <div>
            <p>Had sufficient knowledge of the job</p>
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Had sufficient knowledge of the job', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Is experienced in supervision</p>
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Is experienced in supervision', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Was open to suggestions</p>
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Was open to suggestions', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Recognised and acknowledged achievements												</p>
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings','Recognised and acknowledged achievements', e.target.value)}>											', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Acknowledged employees contributions</p>
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Acknowledged employees contributions', e.target.value)}> 												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Provided constructive feedback</p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Provided constructive feedback', e.target.value)}> 												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Clearly communicated management decisions and how they would effect your work</p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Clearly communicated management decisions and how they would effect your work', e.target.value)}> 												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Maintained a professional relationship with you</p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('supervisorRankings', 'Maintained a professional relationship with you', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
        </Form.Item>
      </Form.Item>


      <Form.Item>
      <Form.Item label="ManagementRankings">
        <Form.Item
          name="managementRankings"
          noStyle
        //   rules={[
        //     {
        //       required: true,
        //       message: 'Please rank the following',
        //     },
        //   ]}
        >
          <div>
            <p>Gave fair and equal treatment</p>
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Gave fair and equal treatment', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Was available to discuss job related issues</p>
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Was available to discuss job related issues', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Encouraged feedback and suggestions</p>
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Encouraged feedback and suggestions', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Maintained consistent policies and practices </p>
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings','Maintained consistent policies and practices', e.target.value)}>											', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>
            <p>Provided recognition for achievements</p>
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Provided recognition for achievements', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Gave opportunities to develop </p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Gave opportunities to develop ', e.target.value)}> 												', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Provided constructive feedback</p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Provided constructive feedback', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
          <div>

            <p>Clearly communicated decisions and how they would effect your work</p>
    									
            <Radio.Group onChange={(e) => handleRankingsChange('managementRankings', 'Clearly communicated decisions and how they would effect your work', e.target.value)}>
              <Radio value="Strongly Disagree">Strongly Disagree</Radio>
              <Radio value="Disagree">Disagree</Radio>
              <Radio value="Agree">Agree</Radio>
              <Radio value="Strongly Agree">Strongly Agree</Radio>
            </Radio.Group>
            {/* Add other job ranking questions here */}
          </div>
        </Form.Item>
      </Form.Item>
        <Button type="primary" htmlType="submit" onClick={handleSubmit}>
          Submit
        </Button>
     </Form.Item>


    </Form>
  );
};

export default ExitInterviewForm;
