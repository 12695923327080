import React, { useState, useEffect } from "react";
import {
	Modal,
	Form,
	Input,
	Button,
	Table,
	message,
	Card,
} from "antd";
import { PlusOutlined, InboxOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";
import Dragger from "antd/es/upload/Dragger";
import { useParams } from "react-router-dom";


const ExperienceDetails = () => {
	
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [initialData, setInitialData] = useState([]);
	const [type,setType] = useState("");
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	const { token, apiurl } = useAuth();
	const [file, setFile] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);
	const [id, setId] = useState(null);
	const id1=useParams();


	useEffect(() => {
		fetchExperienceDetails();
	}, []);

	const fetchExperienceDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/getexperience/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify(id1),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Experience Details");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching Experience Details");
		}
	};

	const columns = [
		{
			title: "Organization",
			dataIndex: "organization",
			key: "organization",
		},
		{
			title: "Worked From",
			dataIndex: "worked_from",
			key: "worked_from",
		},
		{
			title: "Worked To",
			dataIndex: "worked_to",
			key: "worked_to",
		},

		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
		},
		{
			title: "Reason for Resign",
			dataIndex: "reason_for_resign",
			key: "reason_for_resign",
		},
		{
			title: "Relieving Letter",
			key: "relieving_letter",
			render: (text, record) => (
				<span>
					
						<a
							href={`${apiurl}${record?.relieving_letter}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					
				</span>
			),
		},
		{
			title: "Pay Slip 1",
			key: "payslip_1",
			render: (text, record) => (
				<span>
					
						<a
							href={`${apiurl}${record?.payslip_1}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					
				</span>
			),
		},
		{
			title: "Pay Slip 2",
			key: "payslip_2",
			render: (text, record) => (
				<span>
					
						<a
							href={`${apiurl}${record?.payslip_2}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					
				</span>
			),
		},
		{
			title: "Pay Slip 3",
			key: "payslip_3",
			render: (text, record) => (
				<span>
					
						<a
							href={`${apiurl}${record?.payslip_3}`}
							target="_blank"
							rel="noopener noreferrer">
							View
						</a>
					
				</span>
			),
		},
		
	];

	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Experience Details"
				>
				<Table
					className="experience-table"
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20, overflow: "auto" }}
				/>
			</Card>
			
		</div>
	);
};

export default ExperienceDetails;
