import { Button, Form, Input, message } from "antd";
import React from "react";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";

const CompOffForm = () => {
	const { apiurl, token } = useAuth();
	const handleRequestCompensation = async (values) => {
		values = {
			...values,
			childid: sessionStorage.getItem("selectedChildId"),
		};
		try {
			const response = await fetch(`${apiurl}/comprequest/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				const data = await response.json();
				message.success(data.sucess);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			console.error("Error during Compensation-Off Request:", error);
		}
	};
	return (
		<Main>
			<div className="req-att-main">
			<Form onFinish={handleRequestCompensation} className="form" layout="vertical">
				<Form.Item
					name="Date"
					label="Date"
					rules={[{ required: true, message: "Please enter the date!" }]}>
					<Input type="Date" />
				</Form.Item>
				<Form.Item
					name="Description"
					label="Description"
					rules={[{ required: true, message: "Please enter your request!" }]}>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Submit
					</Button>
				</Form.Item>
			</Form>
			</div>
		</Main>
	);
};

export default CompOffForm;
