import React, { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { Card, message, Button, Statistic } from "antd";
import SUPER_USER_HOME from "../Super_User/Home";
import BUSINESS_OWNER_HOME from "../Bussiness_Owner/Home";
import HR_HEAD_HOME from "../HR_HEAD/Home";
import TALENT_MANAGER_HOME from "../TALENT_MANAGER/Home";
import Main from "../Layouts/Main";
import PaymentComponent from "../../RegistrationPage/Payment";
import Loader from "../../Loader/Loader";
import "./style.css";
import PayrollManagerHome from "../Payroll_Manager/Home";
import { useNavigate } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, theme } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import Lottie from "react-lottie";
import animationData from "./../../../Images/quoteanimation.json";
import animationData1 from "./../../../Images/quoteanimi.json";

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: animationData,
};

const defaultOptions1 = {
	loop: true,
	autoplay: true,
	animationData: animationData1,
};
const MAIN_HOME = () => {
	const [empData, setEmpData] = useState(null);
	const [profileImg, setProfileImg] = useState("");
	const { apiurl, token } = useAuth();
	const [employees, SetEmployees] = useState(null);
	const [role, setRole] = useState([]);
	const [loading, setLoading] = useState(true);
	const [isSuperUser, setIsSuperUser] = useState(false);
	const [isPaymentNeeded, setIsPaymentNeeded] = useState(false);
	const [preabs, setPreAbsData] = useState();

	const navigate = useNavigate();

	useEffect(() => {
		fetchUserData();
		fetchEmpData();
	}, []);

	useEffect(() => {
		if (isSuperUser) {
			fetchSubStatus();
		} else {
			fetchEmployeeDetails();
			fetchImages();
			fetchPreAbs();
		}
	}, [isSuperUser]);

	const fetchEmpData = async () => {
		try {
			const response = await fetch(`${apiurl}/isrm/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					
				}),
			});

			const data = await response.json();
			if (response.ok) {
				SetEmployees(data.isRM);
				console.log(data)
			} else {
				message.error(data.error);
			}
			console.log(employees);
		} catch (error) {
			// message.error("An error occurred while processing your request.");
		}
	};

	const getItems = (panelStyle) => [
		{
			key: "1",
			label: "JOB DESCRIPTION",
			children: <p>{empData?.jobdescription}</p>,
			style: panelStyle,
		},
		{
			key: "2",
			label: "KRA's",
			children: <p>{empData?.kras}</p>,
			style: panelStyle,
		},
		{
			key: "3",
			label: "CAREER PATH",
			children: <p>{empData?.careerpath}</p>,
			style: panelStyle,
		},
	];

	const panelStyle = {
		marginBottom: 24,
		background: "#FFFFFF",
		boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 1px",
		border: "none",
		borderRadius: "10px",
	};

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};

	const fetchUserData = async () => {
		try {
			const response = await fetch(`${apiurl}/get-user-details/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setRole(data.role);
				setIsSuperUser(data.role.includes("SUPER_USER"));
				setLoading(false);
			} else {
				message.error(response.statusText);
				setLoading(false);
			}
		} catch (error) {
			message.error(error);
			setLoading(false);
		}
	};

	const ShowEmpData = async () => {
		navigate("/empdataforrm");
	};

	const fetchEmployeeDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/createemployee/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			const data = await response.json();

			if (response.ok) {
				setEmpData(data.data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while processing your request.");
		}
	};

	const fetchSubStatus = async () => {
		try {
			const response = await fetch(`${apiurl}/is-sub`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setIsPaymentNeeded(!data.status);
				setLoading(false);
			} else {
				message.error("Unable to find organization, please try again");
				setLoading(false);
			}
		} catch (error) {
			message.error(error);
			setLoading(false);
		}
	};

	const fetchPreAbs = async () => {
		try {
			const response = await fetch(`${apiurl}/getpresentabs`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setPreAbsData(data);
				setLoading(false);
			} else {
				message.error("Unable to find organization, please try again");
				setLoading(false);
			}
		} catch (error) {
			message.error(error);
			setLoading(false);
		}
	};

	const getDaySuffix = (day) => {
		if (day > 3 && day < 21) return "th";
		switch (day % 10) {
			case 1:
				return "st";
			case 2:
				return "nd";
			case 3:
				return "rd";
			default:
				return "th";
		}
	};

	const formatDate = (date) => {
		const day = date.getDate();
		const dayOfWeek = date.toLocaleString("default", { weekday: "long" });
		const month = date.toLocaleString("default", { month: "long" });
		const year = date.getFullYear();

		return `${dayOfWeek}, ${day}${getDaySuffix(day)} ${month} ${year}`;
	};

	const today = new Date();
	const formattedDate = formatDate(today);
	if (loading) {
		return <Loader />;
	}

	if (isSuperUser && isPaymentNeeded) {
		return <PaymentComponent />;
	}

	return (
		<>
			{isSuperUser ? (
				<SUPER_USER_HOME />
			) : (
				<Main>
					<div className="greeting">
						Hello...! <span>{empData?.userName}</span>
					</div>

					<div className="main-home-section-1">
						<div className="profile-img">
							<img src={`${apiurl}${profileImg}`}></img>
						</div>
						<div className="">
							<div className="today-info">
								<div className="tit">Today</div>
								<div className="date">{formattedDate}</div>
							</div>
							<div className="info-sec">
								<div>
									<span>Designation</span>
									{empData?.designation}
								</div>
								<div>
									<span>Department</span>
									{empData?.department}
								</div>
								<div>
									<span>Reporting Manager</span>
									{empData?.rm}
								</div>
							</div>
						</div>
						<div className="quote">
							<div className="quote-image">
								<Lottie
									isClickToPauseDisabled={true}
									options={defaultOptions}
								/>
							</div>
							<div className="quote-bg-img">
								<Lottie
									isClickToPauseDisabled={true}
									options={defaultOptions1}
								/>
							</div>
							<div className="quote-cont">
								<h2>Quote for the Day</h2>
								<div className="quote-content">{empData?.quote}</div>
							</div>
						</div>
					</div>

					<div className="main-home-section-2">
						<Collapse
							accordion
							bordered={false}
							collapsible
							expandIcon={({ isActive }) => (
								<CaretRightOutlined rotate={isActive ? 90 : 0} />
							)}
							style={{
								background: "#f5f5f5",
							}}
							items={getItems(panelStyle)}
						/>
					</div>

					{employees && (
						<div className="action-item" onClick={ShowEmpData}>
							<div className="action-item-header">View your Employees</div>
							<div className="action-item-body">
								Click here to view the employees who are reporting to you in your organization.
							</div>
						</div>
					)}

					{role.includes("BUSINESS_OWNER") && <BUSINESS_OWNER_HOME />}
					{role.includes("HR_HEAD") && <HR_HEAD_HOME />}
					{role.includes("TALENT_MANAGER") && <TALENT_MANAGER_HOME />}
					{role.includes("PAYROLL_MANAGER") && <PayrollManagerHome />}
				</Main>
			)}
		</>
	);
};

export default MAIN_HOME;
