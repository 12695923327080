import React, { useState, useEffect } from "react";
import { loadScript } from "./LoadScript";
import { useAuth } from "./useAuth";
import { message } from "antd";
import { Navigate, useNavigate } from "react-router-dom";

const PaymentComponent = () => {
    const navigate = useNavigate();
	const [amount, setAmount] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
    const {apiurl,token} = useAuth();

	useEffect(() => {
		fetchAmount();
	}, []);

    const fetchAmount = async () => {
			try {
				const response = await fetch(`${apiurl}/create-payment-intent/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						currency: "INR",
					}),
				});
				const data = await response.json();
				if (response.ok) {
					setAmount(data.amount);
				} else {
					setError(data.error);
				}
			} catch (error) {
				setError("Error fetching payment amount");
			} finally {
				setLoading(false);
			}
		};

    const sendPaymentId = async (Id) => {
			try {
				const response = await fetch(`${apiurl}/paymentsuccess/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						payment_id: Id,
					}),
				});
				const data = await response.json();
				if (response.ok) {
					message.success(data.message);
                    navigate('/home');
				} else {
					message.errror(response.statusText);
				}
			} catch (error) {
				setError("Error fetching payment amount");
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		const initializeRazorpay = async () => {
			try {
				await loadScript("https://checkout.razorpay.com/v1/checkout.js");
			} catch (error) {
				console.error("Error loading Razorpay script:", error);
				setError("Error loading Razorpay script");
			}
		};

		initializeRazorpay();
	}, []);

	const initiatePayment = async () => {
		try {
			const options = {
				key: "rzp_test_u4AVcofZe0M51I",
				amount: amount ,
				currency: "INR",
				name: "GA",
				description: "Payment for GA ORG SYNC",
				handler: async function (response) {
					console.log(response);
					await sendPaymentId(response.razorpay_payment_id);
				},
			};
			const rzp = new window.Razorpay(options);
			rzp.open();
		} catch (error) {
			console.error("Error initializing Razorpay:", error);
			setError("Error initializing Razorpay");
		}
	};

	if (loading) {
		return <div>Loading...</div>;
	}

	if (error) {
		return <div>Error: {error}</div>;
	}

	return (
		<div>
			{amount ? (
				<div>
					<p>Amount to pay: {amount}</p>
					<button onClick={initiatePayment}>Pay Now</button>
				</div>
			) : (
				<div>No amount fetched</div>
			)}
		</div>
	);
};

export default PaymentComponent;
