import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, DatePicker, message } from "antd";
import { useAuth } from "../utils/useAuth";
import Success from "../utils/Success";
import moment from "moment";
import "./Employee.css";
import Loader from "../Loader/Loader";

const { Option } = Select;

const AddEmployee = ({ role ,child_id }) => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [departments, setDepartments] = useState([]);
	const [designations, setDesignations] = useState([]);
	const [employees, setEmployees] = useState([]);

	useEffect(() => {
		if (role !== "BUSINESS_OWNER" && role !== "HR_HEAD") {
			fetchDepartments();
			fetchDesignations();
			fetchemployees();
		}
	}, [role]);

	
	const onFinish = async (values) => {
		setLoading(true);
		values.dateOfBirth = moment(values.dateOfBirth).format("YYYY-MM-DD");
		values.dateOfJoining = moment(values.dateOfJoining).format("YYYY-MM-DD");
		values = { ...values, role: role, childId: child_id };

		try {
			const response = await fetch(`${apiurl}/createemployee/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			const data = await response.json();

			if (response.ok) {
				setIsSuccess(true);
				message.success(data.message);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while processing your request.");
		} finally {
			setLoading(false);
		}
	};



	const fetchemployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setEmployees(data.data);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};


	const fetchDepartments = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdepartments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})
			});
			if (response.ok) {
				const data = await response.json();
				setDepartments(data.data);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	const fetchDesignations = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchdesignations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify({'childid':sessionStorage.getItem('selectedChildId')})
			});
			if (response.ok) {
				const data = await response.json();
				setDesignations(data.data);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		}
	};

	if (loading) {
		return <Loader></Loader>;
	}

	if (isSuccess) {
		return <Success title={role + " Added Successfully"} />;
	}

	return (
		<div className="add-employee-cont">
			<Form
				className="add-emp-form"
				form={form}
				layout="vertical"
				onFinish={onFinish}>
				<Form.Item required name="empid" label="Employee ID">
					<Input />
				</Form.Item>
				<Form.Item required name="userName" label="Name of the Employee">
					<Input />
				</Form.Item>
				<Form.Item required name="email" label="Email">
					<Input type="email" />
				</Form.Item>
				<Form.Item required name="dateOfBirth" label="Date of Birth">
					<Input type="date" />
				</Form.Item>
				<Form.Item required name="phoneNumber" label="Phone Number">
					<Input />
				</Form.Item>
				<Form.Item required name="type" label="Type">
					<Select>
						<Option value="part_time">Part Time</Option>
						<Option value="full_time">Full Time</Option>
						<Option value="contract">Contract</Option>
						<Option value="other">Other</Option>
					</Select>
				</Form.Item>
				<Form.Item required name="gender" label="Gender">
					<Select>
						<Option value="male">Male</Option>
						<Option value="female">Female</Option>
						<Option value="other">Other</Option>
					</Select>
				</Form.Item>
				<Form.Item required name="ctc" label="CTC">
					<Input />
				</Form.Item>
				<Form.Item required name="jobdescription" label="Job Description">
					<Input />
				</Form.Item>
				<Form.Item required name="kras" label="kras">
					<Input />
				</Form.Item>
				{role !== "BUSINESS_OWNER" && role !== "HR_HEAD" && (
					<>
						<Form.Item required name="designation" label="Designation">
							<Select>
								{designations.map((designation) => (
									<Option key={designation.id} value={designation.id}>
										{designation.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item required name="department" label="Department">
							<Select>
								{departments.map((department) => (
									<Option key={department.id} value={department.id}>
										{department.name}
									</Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item required name="reported_to" label="Reported To">
							<Select>
								{employees.map((employee) => (
									<Option key={employee.id} value={employee.id}>
										{employee.userName}
									</Option>
								))}
							</Select>
						</Form.Item>
					</>
				)}
				<Form.Item required name="dateOfJoining" label="Date of Joining">
					<Input type="date" />
				</Form.Item>
				<Form.Item required name="bank" label="Need Bank Acc">
					<Select>
						<Option value="yes">Yes</Option>
						<Option value="no">No</Option>
					</Select>
				</Form.Item>
				<Form.Item>
					<Button type="primary" htmlType="submit">
						Create Employee
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddEmployee;
