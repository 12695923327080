import React, { useState } from "react";
import { Upload, Button, message, Spin, Table } from "antd";
import { InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import Papa from "papaparse";
import Success from "../utils/Success";
const { Dragger } = Upload;
const UploadLeaveBalance = () => {
    const [file, setFile] = useState(null);
    
    const { apiurl, token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [success, setSuccess] = useState(false);
    const handleFileChange = (info) => {
        if (info.file.status === "done") {
            setFile(info.file.originFileObj);
        } else if (info.file.status === "error") {
            message.error("Upload failed.");
        }
    };
    const handleSubmit = async () => {
        if (!file) {
            message.warning("Please select file");
            return;
        }
        setLoading(true);

        const formData = new FormData();
        formData.append("childid", sessionStorage.getItem("selectedChildId"));
        formData.append("file", file);

        const headers = {
            Authorization: `Bearer ${token}`,
        };

        const response = await fetch(`${apiurl}/uploadleavebal/`, {
            method: "POST",
            body: formData,
            headers: headers,
        });

        if (response.ok) {
            const data = await response.json();
            setLoading(false);
            message.success(data.message);
            setSuccess(true);
        } else {
            const data = await response.json();
            setLoading(false);
            message.error(data.error || "An error occurred during the upload.");
        }
    };

    if (success) {
        return <Success title="LeaveBalance Uploaded Successfully" />;
    }

    if (loading) {
        return (
            <div className="loading-container">
                <Spin
                    tip="Uploading..."
                    size="large"
                    indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
                />
            </div>
        );
    }

    const columns =
        tableData.length > 0
            ? Object.keys(tableData[0]).map((key) => ({
                title: key,
                dataIndex: key,
                key: key,
            }))
            : [];

    const customRequest = ({ file, onSuccess }) => {
        onSuccess("ok");
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
                setTableData(results.data);
            },
        });
    };
    return (
        <div className='name-sheet-main'>
            <h3>Upload Leave Balance</h3>
            <Dragger
                className="name-sheet-upload"
                accept=".csv"
                maxCount={1}
                customRequest={customRequest}
                onChange={handleFileChange}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                    Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">Upload only .csv files</p>
            </Dragger>
            {tableData.length > 0 && (
                <Table
                    dataSource={tableData}
                    columns={columns}
                    rowKey={(record) => record.email}
                />
            )}
            <Button className="name-sheet-btn" type="primary" onClick={handleSubmit}>
                Upload
            </Button>


        </div>
    );
};
export default UploadLeaveBalance;