import React from "react";
import Main from "../Homepages/Layouts/Main";
import GrievanceList from "./GrievanceList";
import "./Grievance.css";

const GrievanceMain = () => {
	return (
		<Main>
			<GrievanceList />
		</Main>
	);
};

export default GrievanceMain;
