import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";
import Loader from "../Loader/Loader";
import { message, Table } from "antd";

const AttendanceRequest = () => {
	const { apiurl, token } = useAuth();
	const [data, setData] = useState(null);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		getdata();
	}, []);

	const getdata = async () => {
		try {
			const response = await fetch(`${apiurl}/displayrequests/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data1 = await response.json();
				console.log(data1);
				setData(data1.data);
			}
		} catch (error) {
			message.error("Failed to fetch attendance requests");
		}
	};
	const columns = [
		{ title: "id", dataIndex: "id", key: "id" },
		{ title: "Date", dataIndex: "Date", key: "Date" },
		{ title: "reason", dataIndex: "reason", key: "reason" },
		{ title: "status", dataIndex: "status", key: "status" },
	];

	return (
		<Main>
			<div className="approvals-main">
				<div className="section-head">Attendance Requests Created</div>
				{data && <Table columns={columns} dataSource={data} />}
			</div>
		</Main>
	);
};

export default AttendanceRequest;
