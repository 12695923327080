import React, { useEffect, useState } from "react";
import Main from "../Homepages/Layouts/Main";
import { useAuth } from "../utils/useAuth";
import { message } from "antd";
import "./Style.css"; 

const Notifications = () => {
	const [notifications, setNotifications] = useState(null);
	const [showUnread, setShowUnread] = useState(false);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		fetchNotifications();
	}, []);

	const fetchNotifications = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchnotifications`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setNotifications(data.data);
			} else {
				message.error("Unable to process. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const readNotification = async (id,status) => {
		try {
			const response = await fetch(`${apiurl}/fetchnotifications/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: id,
					status:status
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchNotifications();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error(error);
		}
	};

	const formatDate = (dateString) => {
		const options = { month: "short", day: "2-digit", year: "numeric" };
		const date = new Date(dateString);
		return date.toLocaleDateString("en-US", options);
	};

    const unreadCount = notifications
			? notifications.filter((notification) => !notification.is_read).length
			: 0;

	return (
		<Main>
			<div className="notifications-container">
				<h2 className="notifications-header">Notifications ({unreadCount}):</h2>
				<button
					className="show-button"
					onClick={() => setShowUnread(!showUnread)}>
					{showUnread ? "Show All" : "Show Unread"}
				</button>
				<br />

				{notifications?.length > 0 ? (
					<div className="notifications-list">
						{notifications.map(
							(notification) =>
								(!showUnread || !notification.is_read) && (
									<div
										className={`notification ${
											notification.is_read ? "read" : "unread"
										}`}
										key={notification.id}>
										<>
											<p className="notification-date">
												{formatDate(notification.date)}
											</p>
											<p className="notification-message">
												{notification.message}
											</p>
										</>
										{!notification.is_read && (
											<button
												className="mark-as-read-button"
												onClick={() => readNotification(notification.id,'Read')}>
												Mark As Read
											</button>
										)}
										{notification.is_read && (
											<button
												className="mark-as-read-button"
												onClick={() => readNotification(notification.id,'Unread')}>
												Mark As Unread
											</button>
										)}
									</div>
								)
						)}
					</div>
				) : (
					<p className="no-notifications">None</p>
				)}
			</div>
		</Main>
	);
};

export default Notifications;
