import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, message, Table } from "antd";

import "./Leave.css";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";

const OptionalHoliday = () => {
	const { token, apiurl } = useAuth();
	const [employees, setEmployees] = useState([]);
	const [OptionalHolidaysData, setOptionalHolidaysData] = useState([]);
	const [holidays, setHolidays] = useState([]);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchData();
		fetchHolidays();
		fetchemployees();
	}, []);

	const fetchemployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setEmployees(data.data);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const fetchHolidays = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchoptional/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				const currentDate = new Date();
				const filteredData = data.filter(
					(item) => new Date(item.date) >= currentDate
				);
				setHolidays(filteredData);
			} else {
				message.error("Unable to fetch Optional Holidays. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiurl}/empoptionalholidays/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			const data = await response.json();
			setOptionalHolidaysData(data);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const onFinish = async (values) => {
		console.log(values);
		setLoading(true);
		values = { ...values, childid: sessionStorage.getItem("selectedChildId") };
		try {
			const response = await fetch(`${apiurl}/empoptionalholidays/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			const data = await response.json();
			if (response.ok) {
				setLoading(false);
				message.success(data.message);
				form.resetFields();
				fetchData();
				fetchHolidays();
			} else {
				setLoading(false);
				message.error(data.message);
			}
		} catch (error) {
			setLoading(false);
		}
	};

	const columns = [
		{
			title: "Holiday",
			dataIndex: "holiday",
			key: "holiday",
		},
		{
			title: "Holiday Date",
			dataIndex: "date",
			key: "date",
		},
		{
			title: "Work Delegated",
			dataIndex: "workDelegated",
			key: "workDelegated",
		},
		{
			title: "Comments",
			dataIndex: "comments",
			key: "comments",
		},
	];

	if (loading) {
		return <div>Loading...</div>;
	}

	return (
		<Main>
			<div className="opt-holiday-main">
				<Form
					form={form}
					layout="vertical"
					onFinish={onFinish}
					className="form">
					<Form.Item
						name="id"
						label="Select a Holiday"
						rules={[
							{
								required: true,
								message: "Please select the Date",
							},
						]}>
						<Select placeholder="Select the Optional Holiday Date">
							{holidays?.map((holiday) => (
								<Select.Option key={holiday.id} value={holiday.id}>
									{holiday.name} - {holiday.date}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						name="workDelegated"
						label="Work Delegated"
						rules={[
							{
								required: true,
								message: "Please select the work delegated",
							},
						]}>
						<Select placeholder="Select the work delegated">
							{employees?.map((employee) => (
								<Select.Option key={employee.id} value={employee.id}>
									{employee.userName} - {employee.email}
								</Select.Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						name="comments"
						label="Comments On Work Delegation"
						rules={[
							{
								required: true,
								message: "Please enter comments",
							},
						]}>
						<Input.TextArea placeholder="Enter comments" />
					</Form.Item>

					<Form.Item>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			</div>
			<div className="tabcont" id="leavedata">
				<Table
					dataSource={OptionalHolidaysData}
					columns={columns}
					rowKey="id"
				/>
			</div>
		</Main>
	);
};

export default OptionalHoliday;
