import React, { useEffect, useRef, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import {
	HomeOutlined,
	BellOutlined,
	SolutionOutlined,
	UserOutlined,
	LogoutOutlined,
	ScheduleOutlined,
	UserSwitchOutlined,
	PayCircleOutlined,
	SecurityScanOutlined,
} from "@ant-design/icons";
import { Layout, Menu, Button, Dropdown, Badge, Avatar, Modal, Tour, FloatButton } from "antd";
import "./Main.css";
import userimg from "./../../../Images/userimg.jpg";
import { useAuth } from "../../utils/useAuth";
import logo from "./../../../Images/LOGO-10-black.png";

const { Header, Sider } = Layout;

const Main = ({ children }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const [openKeys, setOpenKeys] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [profileImg, setProfileImg] = useState("");
	const { apiurl, token } = useAuth();
	const [count, setCount] = useState(null);
	const Attendanceref = useRef(null);
	const missedAttRef = useRef(null);
	const CompofRef = useRef(null);
	const lateloginRef = useRef(null);
	const opRef = useRef(null);
	const leavesRef = useRef(null);
	const payslipsRef = useRef(null);
	const requestsRef = useRef(null);
	const grievanceRef = useRef(null);
	const ticketsRef = useRef(null);
	const attreqRef = useRef(null);
	const [open, setOpen] = useState(false);

	const steps = [
		{
			title: "Attendance",
			description:
				"Manage your attendance records and mark your daily attendance.",
			target: () => Attendanceref.current,
		},
		{
			title: "Missed Attendance?",
			description: "Report any missed attendance and request regularization.",
			target: () => missedAttRef.current,
		},
		{
			title: "Compensation Off?",
			description: "Apply for compensation off for extra hours worked.",
			target: () => CompofRef.current,
		},

		{
			title: "Late Login?",
			description:
				"Record your late login and provide necessary justifications.",
			target: () => lateloginRef.current,
		},
		{
			title: "Optional Holiday?",
			description:
				"Opt for optional holidays and manage your holiday calendar.",
			target: () => opRef.current,
		},
		{
			title: "Leaves",
			description: "Apply for leaves and track your leave balance and history.",
			target: () => leavesRef.current,
		},
		{
			title: "Payslips",
			description:
				"View and download your payslips and manage your financial records.",
			target: () => payslipsRef.current,
		},

		{
			title: "Grievance",
			description: "Report any grievances and track the resolution process.",
			target: () => grievanceRef.current,
		},
		{
			title: "Ticket",
			description:
				"Raise tickets for any issues and track the status of your requests.",
			target: () => ticketsRef.current,
		},
		{
			title: "Requests",
			description:
				"Manage various requests such as approvals, grievances, and more.",
			target: () => requestsRef.current,
		},
		{
			title: "Attendance Request",
			description:
				"Request attendance adjustments and manage related approvals.",
			target: () => attreqRef.current,
		},
	];


	useEffect(() => {
		fetchImages();
		fetchnotificationscount();
		const pathname = location.pathname;
		if (pathname.includes("/attendance")) {
			setOpenKeys(["2"]);
		}
		if (pathname.includes("/requests")) {
			setOpenKeys(["5"]);
		}
	}, [location]);

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const fetchnotificationscount = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchnotificationscount/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setCount(data.notifications);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const defaultSelectedKeys = () => {
		const pathname = location.pathname;

		const menuItems = [
			"/home",
			"/attendance",
			"/leave-management",
			"/payslips",
			"/requests",
		];

		const index = menuItems.findIndex((item) => pathname.includes(item));
		if (index === 1) {
			const newitems = [
				"/attendance/request",
				"/attendance/comp-off",
				"/attendance/latelogin",
				"/attendance/optholidays"
			];
			const subindex = newitems.findIndex((item) => pathname.includes(item));
			return subindex !== -1 ? [`${index + 1}-${subindex}`] : ["2-0"];
		}
		else if (index === 4) {
			const newitems = [
				"/requests/grievance",
				"/requests/ticket",
				"/requests/approvals",
				"/requests/attrequest"
			];
			const subindex = newitems.findIndex((item) => pathname.includes(item));
			console.log(subindex !== -1 ? [`${index + 1}-${subindex}`] : ["5-0"]);
			return subindex !== -1 ? [`${index + 1}-${subindex}`] : ["5-0"];
		} else {
			return index !== -1 ? [`${index + 1}`] : [""];
		}
	};

	const handleMenuItemClick = ({ key }) => {
		setOpenKeys(openKeys.includes(key) ? [] : [key]);
	};

	const Logout = () => {
		handleLogout();
		navigate("/logout");
	};

	const handleStepChange = (currentStepIndex) => {
		if (currentStepIndex < 5) {
			setOpenKeys(["2"]);
		}
		if (currentStepIndex>=7 && currentStepIndex<11){
			setOpenKeys(["5"]);
		}
	};

	const menuItems = [
		{
			key: "1",
			icon: <HomeOutlined />,
			label: <Link to="/home">Home</Link>,
		},
		{
			key: "2",
			icon: <ScheduleOutlined />,
			label: <span ref={Attendanceref}>Attendance</span>,
			children: [
				{
					key: "2-0",
					label: <Link to="/attendance">Dashboard</Link>,
				},
				{
					key: "2-1",
					label: (
						<Link to="/attendance/request" ref={missedAttRef}>
							Missed Attendance ?
						</Link>
					),
				},
				{
					key: "2-2",
					label: (
						<Link to="/attendance/comp-off" ref={CompofRef}>
							Comp-off ?
						</Link>
					),
				},
				{
					key: "2-3",
					label: (
						<Link to="/attendance/latelogin" ref={lateloginRef}>
							Late Login ?
						</Link>
					),
				},
				{
					key: "2-4",
					label: (
						<Link to="/attendance/optholidays" ref={opRef}>
							Optional Holiday?
						</Link>
					),
				},
			],
		},
		{
			key: "3",
			icon: <SolutionOutlined />,
			label: (
				<Link to="/leave-management" ref={leavesRef}>
					Leaves
				</Link>
			),
		},
		{
			key: "4",
			icon: <PayCircleOutlined />,
			label: (
				<Link to="/payslips" ref={payslipsRef}>
					Payslips
				</Link>
			),
		},
		{
			key: "5",
			icon: <SolutionOutlined />,
			label: "Requests",
			children: [
				{
					key: "5-0",
					label: (
						<Link to="/requests/grievance" ref={grievanceRef}>
							Grievance
						</Link>
					),
				},
				{
					key: "5-1",
					label: (
						<Link to="/requests/ticket" ref={ticketsRef}>
							Ticket
						</Link>
					),
				},
				{
					key: "5-2",
					label: (
						<Link to="/requests/approvals" ref={requestsRef}>
							Logs
						</Link>
					),
				},
				{
					key: "5-3",
					label: (
						<Link to="/requests/attrequest" ref={attreqRef}>
							Att Request
						</Link>
					),
				},
			],
		},
	];

	const dropdownItems = [
		{
			key: "1",
			label: (
				<Link to="/profile">
					<UserOutlined /> Profile
				</Link>
			),
		},
		{
			key: "2",
			label: (
				<Link to="/switchchild">
					<UserOutlined /> Switch Child
				</Link>
			),
		},
		{
			key: "3",
			label: (
				<Link to="/change">
					<UserOutlined /> Change Password
				</Link>
			),
		},
		{
			key: "4",
			label: (
				<div onClick={() => setIsModalOpen(true)}>
					<LogoutOutlined /> Logout
				</div>
			),
		},
	];

	return (
		<Layout>
			<Sider
				className="side"
				breakpoint="lg"
				collapsedWidth="0"
				width={"225px"}
				style={{
					height: "calc(100vh - 100px)",
					position: "fixed",
					left: "0",
					top: "100px",
					bottom: 0,
					zIndex: 1,
					background: "#fff",
				}}>
				<Menu
					mode="inline"
					theme="light"
					defaultSelectedKeys={defaultSelectedKeys()}
					openKeys={openKeys}
					onOpenChange={(keys) => setOpenKeys(keys)}
					onClick={handleMenuItemClick}
					className="menu"
					items={menuItems}
				/>
			</Sider>
			<Layout>
				<Header className="head">
					<div className="header-logo">
						<a href="/home">
							<img alt="logo" src={logo}></img>
						</a>
					</div>
					<div className="header-buttons">
						<Link to="/notifications">
							<Badge
								showZero
								count={count}
								overflowCount={9}
								offset={[-10, 10]}>
								<Avatar
									shape="square"
									size="large"
									icon={<BellOutlined />}
									style={{ background: "transparent" }}
								/>
							</Badge>
						</Link>
						<Dropdown
							menu={{ items: dropdownItems }}
							placement="bottom"
							width={"250"}
							trigger={["click"]}>
							<img
								src={profileImg ? `${apiurl}${profileImg}` : userimg}
								alt="profile"
							/>
						</Dropdown>
					</div>
				</Header>

				<Modal
					open={isModalOpen}
					onOk={() => {
						Logout();
						setIsModalOpen(false);
					}}
					onCancel={() => setIsModalOpen(false)}>
					<h3>Are you sure to logout?</h3>
				</Modal>
				<div className="content">{children}</div>
				<Tour
					className="tourdiv"
					open={open}
					onClose={() => setOpen(false)}
					steps={steps}
					onChange={handleStepChange}
				/>
				{/* <FloatButton
					onClick={() => setOpen(true)}
					icon={<SecurityScanOutlined className="floaticon" />}
					type="primary"
					shape="circle"
					className="tourbtn"
				/> */}
			</Layout>
		</Layout>
	);
};

export default Main;
