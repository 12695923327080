import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Descriptions,
	Button,
	message,
	Input,
	Form,
	Modal,
	List,
	Card,
} from "antd";
import { useAuth } from "../utils/useAuth";
import Main from "../Homepages/Layouts/Main";
import { LeftCircleOutlined } from "@ant-design/icons";

function TicketDetails() {
	const navigate = useNavigate();
	const [grievance, setGrievance] = useState(null);
	const [commentModalVisible, setCommentModalVisible] = useState(false);
	const { id } = useParams();
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	useEffect(() => {
		fetchGrievance();
	}, [id]);
	const fetchGrievance = async () => {
		try {
			const response = await fetch(`${apiurl}/tickets/${id}/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
			});
			if (!response.ok) {
				throw new Error("Failed to fetch Ticket details");
			}
			const data = await response.json();
			setGrievance(data);
		} catch (error) {
			message.error("Error fetching Ticket details: " + error.message);
		}
	};
	const updateStatus = async (newStatus) => {
		try {
			const response = await fetch(`${apiurl}/tickets/${id}/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({ ...grievance, status: newStatus }),
			});
			if (!response.ok) {
				throw new Error("Failed to update status");
			}
			const updated = await response.json();
			setGrievance(updated);
			message.success("Status updated successfully");
		} catch (error) {
			message.error("Error updating status: " + error.message);
		}
	};

	const handleAddComment = async (values) => {
		try {
			const response = await fetch(`${apiurl}tickets/${id}/ticketcomments/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify(values),
			});
			if (!response.ok) {
				throw new Error("Failed to add comment");
			}
			message.success("Comment added successfully");
			setCommentModalVisible(false);
			fetchGrievance();
		} catch (error) {
			message.error("Error adding comment: " + error.message);
		}
	};

	const showCommentModal = () => {
		setCommentModalVisible(true);
	};

	const closeCommentModal = () => {
		setCommentModalVisible(false);
	};

	return (
		<Main>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<center>
				<h3 className="section-head">Ticket Details</h3>
			</center>
			<div className="grievance-details-main">
				<Card
					title="Ticket"
					className="details"
					extra={
						<>
							{grievance && grievance.status !== "resolved" && (
								<Button type="default" onClick={() => updateStatus("resolved")}>
									Mark as Resolved
								</Button>
							)}
						</>
					}>
					<div className="item">
						<p>
							<span>Issue</span>
							{grievance?.issue}
						</p>
					</div>
					<div className="item">
						<p>
							<span>Description</span>
							{grievance?.description}
						</p>
					</div>
					<div className="item">
						<p>
							<span>Status</span>
							{grievance?.status}
						</p>
					</div>
					<div className="item">
						<p>
							<span>Created At</span>
							{grievance && new Date(grievance.created_at).toLocaleString()}
						</p>
					</div>
				</Card>
				<div className="comments">
					{grievance && grievance.comments && (
						<List
							dataSource={grievance.comments}
							header={
								<div className="comments-list-header">
									<div>Comments : {grievance.comments.length}</div>
									<Button type="default" onClick={showCommentModal}>
										Add New Comment
									</Button>
								</div>
							}
							itemLayout="horizontal"
							renderItem={(comment) => (
								<List.Item>
									<List.Item.Meta
										title={comment.sender}
										description={comment.comment}
									/>
								</List.Item>
							)}
						/>
					)}
				</div>
			</div>
			<Modal
				title="Add Comment"
				open={commentModalVisible}
				onCancel={closeCommentModal}
				footer={null}>
				<Form form={form} onFinish={handleAddComment} layout="vertical">
					<Form.Item
						name="comment"
						label="Comment"
						rules={[{ required: true, message: "Please input your comment!" }]}>
						<Input.TextArea rows={4} />
					</Form.Item>
					<Form.Item>
						<Button type="primary" htmlType="submit">
							Submit
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</Main>
	);
}

export default TicketDetails;
