import React, { useEffect, useState } from "react";
import { Table, Space, message, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";
import HeaderComponent from "../Header/Header";
import "./Grievance.css";
import { LeftCircleOutlined } from "@ant-design/icons";

function GrievanceHrList() {
	const navigate = useNavigate()
	const [grievances, setGrievances] = useState([]);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		fetchGrievances();
	}, []);

	const fetchGrievances = async () => {
		try {
			const response = await fetch(`${apiurl}/grievances/admin/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (!response.ok) {
				throw new Error("Failed to fetch grievances");
			}
			const data = await response.json();
			setGrievances(data);
		} catch (error) {
			message.error("Error fetching grievances: " + error.message);
		}
	};

	const columns = [
		{
			title: "Title",
			dataIndex: "title",
			key: "title",
		},
		{
			title: "Sender",
			dataIndex: "sender",
			key: "sender",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Action",
			key: "action",
			render: (_, record) => (
				<Space size="middle">
					<Link to={`/hr/grievances/${record.id}`}>View</Link>
				</Space>
			),
		},
	];

	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="hr-grievances">
				<h3>Grievances</h3>
				<Table columns={columns} dataSource={grievances} rowKey="id" />
			</div>
		</HeaderComponent>
	);
}

export default GrievanceHrList;
