import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './components/utils/UserContext';
import { BrowserRouter as Router} from "react-router-dom";


// if ("serviceWorker" in navigator) {
// 	window.addEventListener("load", () => {
// 		navigator.serviceWorker
// 			.register("/service-worker.js")
// 			.then((registration) => {
// 				console.log("Service Worker registered:", registration);
// 			})
// 			.catch((error) => {
// 				console.error("Service Worker registration failed:", error);
// 			});
// 	});
// }

// if (Notification.permission !== "granted") {
// 	Notification.requestPermission().then((permission) => {
// 		if (permission !== "granted") {
// 			alert("Notification permissions are required");
// 		}
// 	});
// }





const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	// <React.StrictMode>
		<UserProvider>
			<Router>
				<App />
			</Router>
		</UserProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
