import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, message, Spin, Modal, Input, Table, Select } from "antd";
import "./Attendance.css";

const { Option } = Select;

const Break = () => {
	const { apiurl, token } = useAuth();
	const [punchData, setPunchData] = useState(null);
	const [objStatus, setObjStatus] = useState(false);
	const [loading, setLoading] = useState(false);
	const [punchedout, setPunchedout] = useState(false);
	const [punchedin, setPunchedin] = useState(false);
	const [reason, setReason] = useState("");
	const [isopen, setIsopen] = useState(false);
	const [isOther, setIsOther] = useState(false);

	useEffect(() => {
		fetchAttt();
		getatt();
	}, []);

	const fetchPublicIP = async () => {
		try {
			const response = await fetch("https://api.ipify.org?format=json");
			const data = await response.json();
			const ip = data.ip;
			return ip;
		} catch (error) {
			message.error("Error fetching public IP:", error);
			return null;
		}
	};

	const fetchAttt = async () => {
		try {
			const response = await fetch(`${apiurl}/addbreak`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setPunchData(data.break);
				setObjStatus(true);
			} else {
				setObjStatus(false);
			}
		} catch (error) {
			console.error("Error during Fetch Attendance:", error);
			setObjStatus(false);
		}
	};

	const getatt = async () => {
		try {
			const response = await fetch(`${apiurl}/checktimeout`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setPunchedout(data.punchedout);
				setPunchedin(data.punchedin);
			}
		} catch (error) {
			console.error("Error during Fetch Attendance:", error);
			setPunchedout(false);
			setPunchedin(false);
			setObjStatus(false);
		}
	};

	const submitPunchIn = async () => {
		if (reason === "" || !reason) {
			message.error("Please Mention Reason");
			return;
		}
		setLoading(true);
		try {
			const ip = await fetchPublicIP();
			const response = await fetch(`${apiurl}/addbreak/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
					type: "BREAKIN",
					ip: ip,
					reason: reason,
				}),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				setReason("");
				fetchAttt();
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error during Punching Break Out");
		} finally {
			setLoading(false);
			setIsopen(false);
			setIsOther(false);
			setReason("");
		}
	};

	const submitPunchOut = async () => {
		setLoading(true);
		try {
			const ip = await fetchPublicIP();
			const response = await fetch(`${apiurl}/addbreak/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: punchData[punchData.length - 1].id,
					type: "BREAKOUT",
					ip: ip,
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				fetchAttt();
			} else {
				message.error("Error during Punching Break Out");
			}
		} catch (error) {
			message.error("Error during Punching Break Out");
		} finally {
			setLoading(false);
		}
	};

	const formatTime = (timeStr) => {
		if (!timeStr) {
			return "";
		}
		const [hours, minutes] = timeStr.split(":").map(Number);
		if (hours === 0) {
			return `${minutes}mins`;
		} else {
			return `${hours}hr ${minutes}mins`;
		}
	};

	const columns = [
		{
			title: "Reason",
			dataIndex: "reason",
			key: "reason",
		},
		{
			title: "In",
			dataIndex: "time_in",
			key: "time_in",
		},
		{
			title: "Out",
			dataIndex: "time_out",
			key: "time_out",
		},
		{
			title: "Duration",
			dataIndex: "net_time_in",
			key: "net_time_in",
			render: (text) => formatTime(text),
		},
	];

	const reqbutton = () => {
		if (punchedin) {
			if (objStatus && punchData) {
				if (punchData.length > 0 && !punchData[punchData.length - 1].time_out) {
					return (
						<Button
							className="breakbtn"
							onClick={submitPunchOut}
							loading={loading}
							disabled={loading}>
							Break out
						</Button>
					);
				} else if (!punchedout) {
					return (
						<Button
							className="breakbtn"
							onClick={() => setIsopen(true)}
							loading={loading}
							disabled={loading}>
							Break In
						</Button>
					);
				}
			}
		}
		return null;
	};

	const handleReasonChange = (value) => {
		setReason(value);
		setIsOther(value === "Other");
	};

	return (
		<>
			<div className="punch-main">
				{objStatus && (
					<Table
						dataSource={punchData}
						columns={columns}
						rowKey="id"
						pagination={false}
					/>
				)}
				<div>{reqbutton()}</div>
			</div>
			<Modal
				title="Break Reason"
				className="brean-reason-form"
				open={isopen}
				onCancel={() => {
					setReason("");
					setIsopen(false);
				}}
				footer={[
					<Button
						key="cancel"
						onClick={() => {
							setReason("");
							setIsopen(false);
						}}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={submitPunchIn}>
						Take Break
					</Button>,
				]}>
				<Select value={reason} onChange={handleReasonChange}>
					<Option value="Lunch">Lunch</Option>
					<Option value="Tea">Tea</Option>
					<Option value="Other">Other</Option>
				</Select>
				{isOther && (
					<Input
						style={{ marginTop: 10 }}
						value={reason}
						onChange={(e) => setReason(e.target.value)}
						placeholder="Please specify reason"
					/>
				)}
			</Modal>
		</>
	);
};

export default Break;
