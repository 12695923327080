import React, { useEffect, useState } from "react";
import { Button, Card, Form, Input, Modal, message } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";
import "./Policy.css";
import PfSlabsComponent from "./PfSlabs";

const PayrollPolicy = () => {
	const { apiurl, token } = useAuth();
	const [form] = Form.useForm();
	const [editMode, setEditMode] = useState(false);
	const [createMode, setCreateMode] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialValues, setInitialValues] = useState({});
	const [isPolicy, setIspolicy] = useState(false);
	const [pfType,setPFType] = useState(null);

	useEffect(() => {
		fetchPolicy();
	}, []);

	const fetchPolicy = async () => {
		try {
			const response = await fetch(`${apiurl}/payrollpolicy1/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setInitialValues(data.data);
				setPFType(data.type);
				setIspolicy(true);
			} else {
				setIspolicy(false);
			}
		} catch (error) {
			message.error(error);
			setIspolicy(false);
		}
	};

	const handleCreate = async (values) => {
		try {
			setLoading(true);
			values = {
				...values,
				childid: sessionStorage.getItem("selectedChildId"),
			};
			const response = await fetch(`${apiurl}/payrollpolicy/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Policy framed successfully");
				setCreateMode(false);
				fetchPolicy();
			} else {
				message.error("Failed to frame policy");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error framing policy");
			setLoading(false);
		}
	};

	const handleUpdate = async (values) => {
		values = { ...values, childid: sessionStorage.getItem("selectedChildId") };
		try {
			setLoading(true);
			const response = await fetch(`${apiurl}/payrollpolicy/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			if (response.ok) {
				message.success("Details updated successfully");
				fetchPolicy();
				setEditMode(false);
			} else {
				message.error("Failed to update details");
			}
			setLoading(false);
		} catch (error) {
			message.error(error.message || "Error updating details");
			setLoading(false);
		}
	};

	return (
		<div className="policy-main">
			{isPolicy ? (
				<>
					<Card
						className="policy"
						bordered={false}
						title="Payroll Policy"
						extra={
							<Button
								icon={<EditOutlined />}
								onClick={() => setEditMode(true)}
								disabled={editMode}
							/>
						}>
						<p>
							<span>Dearnaess Allowance(%)</span> {initialValues.da_percentage}{" "}
						</p>
						<p>
							<span>House Rent Allowance(%)</span>{" "}
							{initialValues.hra_percentage}{" "}
						</p>
						<p>
							<span>PF(%)</span> {initialValues.pf_percentage}{" "}
						</p>
						<p>
							<span>ESI (%)</span> {initialValues.esi_percentage}{" "}
						</p>
						<p>
							<span>Employer PF(%)</span> {initialValues.employer_pf_percentage}{" "}
						</p>
						<p>
							<span>Employer ESI(%)</span>{" "}
							{initialValues.employer_esi_percentage}{" "}
						</p>
					</Card>
				</>
			) : (
				<>
					<h3>Payroll Policy Not Yet Created.</h3>
					<Button onClick={() => setCreateMode(true)}>Create Policy</Button>
				</>
			)}
			{pfType === "slab" && <PfSlabsComponent />}
			<Modal
				open={createMode}
				title="Create Payroll Policy Anually"
				destroyOnClose
				onCancel={() => setCreateMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setCreateMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={handleCreate}>
					<Form.Item required name="pf_percentage" label="PF percentage">
						<Input />
					</Form.Item>
					<Form.Item required name="da_percentage" label="DA percentage">
						<Input />
					</Form.Item>
					<Form.Item required name="hra_percentage" label="HRA percentage">
						<Input />
					</Form.Item>
					<Form.Item required name="esi_percentage" label="ESI percentage">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="employer_pf_percentage"
						label="Employer PF percentage">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="employer_esi_percentage"
						label="Employer ESI percentage">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				open={editMode}
				destroyOnClose
				title="Edit Details"
				onCancel={() => setEditMode(false)}
				footer={[
					<Button key="cancel" onClick={() => setEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form
					form={form}
					layout="vertical"
					onFinish={handleUpdate}
					initialValues={initialValues}>
					<Form.Item required name="pf_percentage" label="pf percentage">
						<Input />
					</Form.Item>
					<Form.Item required name="da_percentage" label="da percentage">
						<Input />
					</Form.Item>
					<Form.Item required name="hra_percentage" label="hra percentage">
						<Input />
					</Form.Item>
					<Form.Item required name="esi_percentage" label="ESI percentage">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="employer_pf_percentage"
						label="Employer PF percentage">
						<Input />
					</Form.Item>
					<Form.Item
						required
						name="employer_esi_percentage"
						label="Employer ESI percentage">
						<Input />
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default PayrollPolicy;
