import React, { useEffect, useState } from 'react'
import Main from '../Layouts/Main'
import { useAuth } from '../../utils/useAuth';
import { Button, Form, Modal,  message } from 'antd';
import BasicDetailsModal from '../../EmployeeInfo/BasicDetails';
import { InboxOutlined, EditOutlined } from "@ant-design/icons";
import Dragger from 'antd/es/upload/Dragger';
import './Profile.css';
import Documents from '../../EmployeeInfo/Documents';
import EducationDetails from '../../EmployeeInfo/EducationDetails';
import Relationships from '../../EmployeeInfo/Relationships';
import ExperienceDetails from '../../EmployeeInfo/WorkExperience';
import Occasions from '../../EmployeeInfo/Occasions';
import { useParams } from 'react-router-dom';

const EmployeeInfo = () => {
    const [empData, setEmpData] = useState(null);
    const { apiurl, token } = useAuth();
	const [profileImg, setProfileImg] = useState("");
	const [loading,setLoading] = useState(false);
	const [file, setFile] = useState(null);
    const id=useParams()
	const [imageEditMode, setImageEditMode] = useState(false);

	const [form] = Form.useForm();


	useEffect(() => {
		fetchEmployeeDetails();
		fetchImages();
	}, []);

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/gethandleimages/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
                body:JSON.stringify(id)
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};
	
	
    

    const fetchEmployeeDetails = async () => {
			try {
				const response = await fetch(`${apiurl}/getcreateemployee/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
                    body:JSON.stringify(id)
				});

				const data = await response.json();

				if (response.ok) {
					setEmpData(data.data);
				} else {
					message.error(data.error);
				}
			} catch (error) {
				message.error("An error occurred while processing your request.");
			}
		};

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};
    return (
			<Main>
				<div className="section-1">
					<div className="profile-card">
						<div className="left-sec">
							<div>
								<img src={`${apiurl}${profileImg}`} alt="profile" />
								
							</div>
							<div className="user-details">
								<h2>{empData?.userName}</h2>
								<h3>
									{empData?.parent} {empData?.child && <> - {empData?.child}</>}
								</h3>
								<h3>{empData?.type}</h3>
							</div>
						</div>
					</div>
					<div className="right-sec">
						<div>
							<span>Email: </span>
							{empData?.email}
						</div>
						<div>
							<span>Date Of Birth: </span>
							{empData?.dateOfBirth}
						</div>
						<div>
							<span>Gender: </span>
							{empData?.gender}
						</div>
						<div>
							<span>Contact: </span>
							{empData?.phoneNumber}
						</div>
					</div>
				</div>
				<div className="section">
					<BasicDetailsModal />
				</div>
				<div className="section">
					<Documents />
				</div>

				<div className="section">
					<EducationDetails />
				</div>

				<div className="section">
					<Relationships />
				</div>

				<div className="section">
					<Occasions />
				</div>

				<div className="section">
					<ExperienceDetails />
				</div>

			</Main>
		);
}

export default EmployeeInfo
