import React, { useState } from "react";
import { Form, Input, Button, DatePicker, message, Select } from "antd";
import { useAuth } from "../utils/useAuth";
import { useNavigate } from "react-router-dom";
import Main from "../Homepages/Super_User/Layouts/Main";
import Success from "../utils/Success";

const AddChild = () => {
    const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
    const {apiurl,token} = useAuth();

	const onFinish = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/createchild/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
				},
				body: JSON.stringify(values),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
                setIsSuccess(true);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			console.error("Error:", error);
			message.error("Something went wrong");
		}
		setLoading(false);
	};

	if (loading) {
		return <>loading</>;
	}

	if (isSuccess) {
		return <Success title={"Child Account Created Successfully"} />;
	}

	return (
		<div className="add-child-container">
			<Form
				layout="vertical"
				className="add-child-form"
				name="createChild"
				initialValues={{ remember: true }}
				onFinish={onFinish}>
				<Form.Item
					name="name"
					label="Name"
					rules={[{ required: true, message: "Please input the name!" }]}>
					<Input placeholder="Name" />
				</Form.Item>

				<Form.Item
					name="regNo"
					label="Registration Number"
					rules={[
						{
							required: true,
							message: "Please input the registration number!",
						},
					]}>
					<Input placeholder="Registration Number" />
				</Form.Item>

				<Form.Item
					name="companyRegistrationDate"
					label="Company Registration Date"
					rules={[
						{
							required: true,
							message: "Please select the company registration date!",
						},
					]}>
					<Input type="date" />
				</Form.Item>

				<Form.Item
					name="contactPerson"
					label="Contact Person"
					rules={[
						{ required: true, message: "Please input the contact person!" },
					]}>
					<Input placeholder="Contact Person" />
				</Form.Item>

				<Form.Item
					name="designation"
					label="Designation"
					rules={[
						{ required: true, message: "Please input the designation!" },
					]}>
					<Input placeholder="Designation" />
				</Form.Item>

				<Form.Item
					name="contactNo"
					label="Contact Number"
					rules={[
						{ required: true, message: "Please input the contact number!" },
					]}>
					<Input placeholder="Contact Number" />
				</Form.Item>

				<Form.Item
					name="email"
					label="Email"
					rules={[
						{ required: true, message: "Please input the email!" },
						{ type: "email", message: "Please enter a valid email!" },
					]}>
					<Input placeholder="Email" />
				</Form.Item>

				<Form.Item
					name="companyGstRegNo"
					label="Company GST Registration Number">
					<Input placeholder="Company GST Registration Number" />
				</Form.Item>

				<Form.Item name="companyPanNo" label="Company PAN Number">
					<Input placeholder="Company PAN Number" />
				</Form.Item>

				<Form.Item name="companyTanNo" label="Company TAN Number">
					<Input placeholder="Company TAN Number" />
				</Form.Item>

				<Form.Item name="attendanceType" label="Attendance Type">
					<Select className="sel">
						<option key="bulkupload" value="bulkupload">
							Bulk Upload
						</option>
						<option key="punch" value="punch">
							Punch In and Out
						</option>
					</Select>
				</Form.Item>

				<Form.Item name="PFType" label="PF Type">
					<Select className="sel">
						<option key="fixed" value="fixed">
							Fixed
						</option>
						<option key="slab" value="slab">
							Slabs
						</option>
					</Select>
				</Form.Item>

				<Form.Item>
					<Button type="primary" htmlType="submit" loading={loading}>
						Create Child
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default AddChild;
