import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Table, message } from "antd";
import Main from "../Homepages/Layouts/Main";
import Employees from "../Employee/Employees";

const CurrentChildDetails = () => {
	const { apiurl, token } = useAuth();
	const [childData, setChildData] = useState(null);
	const [existingRoles, setExistingRoles] = useState([]);
	useEffect(() => {
		fetchChild();
		fetchRoles();
	}, []);
	const fetchRoles = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchroles/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setExistingRoles(data.roles);
			} else {
				message.error("Unable to process. Please try again");
			}
		} catch (error) {
			message.error(error.message);
		}
	};
	const fetchChild = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchchild/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setChildData(data.data);
			} else {
				message.error("Unable to find child data please try again");
			}
		} catch (error) {
			message.error(error);
		}
	};
	const columns = [
		{
			title: "Role",
			dataIndex: "name",
			key: "name",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "User",
			dataIndex: "user",
			key: "user",
			render: (text) => (text ? text : "-"),
		},
	];

	return (
		<Main>
			<div className="child-details">
				<div className="child-det">
					<div className="section-head">Child Details</div>
					<div className="child-info">
						<div>
							Name: <span>{childData?.name}</span>
						</div>
						<div>
							Reg No: <span>{childData?.regNo}</span>
						</div>

						<div>
							Company Gst Reg No: <span>{childData?.companyGstRegNo}</span>
						</div>
						<div>
							Company Pan No: <span>{childData?.companyPanNo}</span>
						</div>
						<div>
							Company Tan No: <span>{childData?.companyTanNo}</span>
						</div>
					</div>
				</div>
				<div className="roles-section">
					<div className="section-head">Roles in Child</div>
					<Table
						dataSource={existingRoles}
						columns={columns}
						rowKey="id"
						bordered={false}
						pagination={false}
						style={{ overflow: "auto" }}
					/>
				</div>
			</div>
		</Main>
	);
};

export default CurrentChildDetails;
