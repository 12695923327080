import React, { useEffect, useState } from 'react'
import { useAuth } from '../utils/useAuth';
import { message } from 'antd';
import { Link } from 'react-router-dom';
import Main from '../Homepages/Super_User/Layouts/Main';

const Childs = () => {
    const { apiurl, token } = useAuth();	
    const [childs, setChilds] = useState(null);
    useEffect(() => {
			fetchChilds();
		}, []);
    const fetchChilds = async () => {
			try {
				const response = await fetch(`${apiurl}/fetchchilds`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				});
				if (response.ok) {
					const data = await response.json();
					console.log(data);
					setChilds(data.data);
				} else {
					message.error("Unable to find childs please try again");
				}
			} catch (error) {
				message.error(error);
			}
		};
  return (
        <Main>
            <div className='child-cont'>
                {childs &&
                    childs.map((child) => (
                        <Link to={`/child-details/${child.id}`}>
                            <div className="child">{child.name}</div>
                        </Link>
                    ))}
            </div>
        </Main>
	);
}

export default Childs