import React from "react";
import "./Home.css";
import { useNavigate } from "react-router-dom";

const TALENT_MANAGER_HOME = () => {
	const navigate = useNavigate();
	return (
		<>
			<br></br>
			<div className="business-owner-home">
				<h2>Talent Manager Functionalities</h2>
				<div className="action-item" onClick={() => navigate("/add/employees")}>
					<div className="action-item-header">Manage Employees</div>
					<div className="action-item-body">
						Click here to view and add employees in your organization.
					</div>
				</div>
			</div>
		</>
	);
};

export default TALENT_MANAGER_HOME;
